import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup, PatternValidator } from '@angular/forms';
import * as zxcvbn from 'zxcvbn';
import { matchValues } from '~app/core/validators';

// No whitespace at the beginning or end of the password.
const PasswordRegex: RegExp = /^[\S].*[\S]$/;

@Component({
    selector: 'marlin-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss']
})

export class PasswordComponent implements OnInit {
    // TODO: Rework this control to use value accessor and use the input-password.component.

    passwordForm = this.fb.group(
        {
            password: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(64), Validators.pattern(PasswordRegex)]],
            confirmPassword: [''],
            strength: ['']
        },
        { validator: matchValues('password', 'confirmPassword') }
    );

    passwordInputType = 'password';
    passwordInputTypes = {
        hidePassword: { value: 'eye-slash', type: 'password' },
        showPassword: { value: 'eye', type: 'text' }
    };

    progressColours = {
        0: 'danger',
        25: 'danger',
        50: 'warning',
        75: 'success',
        100: 'success'
    };

    progressColour = this.progressColours[0];

    showPasswordRequirments = false;

    // zxcvbn has 4 levels of strength so we need a multiplier for the progress input.
    strengthMultiplier = 25;

    @Input() password: string;
    @Output() onPasswordChange = new EventEmitter<FormGroup>();
    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.passwordForm.get('password').valueChanges.subscribe(() => {
            this.getStrength(this.passwordForm.get('password').value);
        });
        this.passwordForm.get('confirmPassword').valueChanges.subscribe(() => {
            this.getStrength(this.passwordForm.get('password').value);
        });
        // Emit on init so the parent can recieve a value to initialise.
        this.onPasswordChange.emit(this.passwordForm);
    }

    getStrength(password) {
        const estimation = zxcvbn(password || '');
        const score = estimation.score * this.strengthMultiplier;
        this.passwordForm.get('strength').setValue(score);
        this.progressColour = this.progressColours[score];
        this.onPasswordChange.emit(this.passwordForm);
    }

    changeInputType(e, remaskPassword = false) {
        if (remaskPassword || this.passwordInputType == this.passwordInputTypes.showPassword.type) {
            this.passwordInputType = this.passwordInputTypes.hidePassword.type;
            // Setting the svg icon manually because using ng class doesn't work.
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.hidePassword.value);
        } else {
            this.passwordInputType = this.passwordInputTypes.showPassword.type;
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.showPassword.value);
        }
    }
}
