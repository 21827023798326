﻿import { MyHillsongUrl, HapiUrl, MarlinApiUrl } from '~constants/url';
import { RaygunAPIKey, MapiPublicKey } from '~app/constants/app-config';

export const environment = {
    hapiUrl: HapiUrl.live,
    isProduction: true,
    isStaging: false,
    isLocal: false,
    isSandbox: false,
    marlinApiUrl: MarlinApiUrl.live,
    mapiPublicKey: MapiPublicKey.production,
    myhillsongUrl: MyHillsongUrl.live,
    raygunAPIKey: RaygunAPIKey.production
};
