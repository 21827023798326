import { Store } from '@ngrx/store';
import { PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { MetadataTypes } from '~app/constants/church';
var TranslatePipe = /** @class */ (function () {
    function TranslatePipe(store) {
        this.store = store;
    }
    TranslatePipe.prototype.transform = function (key) {
        var _this = this;
        if (key) {
            var translation = void 0;
            this.store
                .select(function (s) { return s.metadata[MetadataTypes.Translations]; })
                .take(1)
                .subscribe(function (data) {
                if (!_.isEmpty(data)) {
                    _this.translationData = data;
                }
            });
            // Since this.translationData is inside a subscribe loop we don't know if there is data.
            // We need to check if there is data before try to search for a translation.
            if (!_.isEmpty(this.translationData)) {
                translation = this.translationData.translationStrings.find(function (obj) {
                    return obj.key === key;
                });
            }
            if (translation) {
                return translation.value;
            }
        }
        return key;
    };
    return TranslatePipe;
}());
export { TranslatePipe };
