import { Routes } from '@angular/router';
import { SupportComponent } from '~public/support/support.component';
import { RegisterComponent } from '~public/register/register.component';
import { LoginComponent } from '~public/login/login.component';
import { GlobalLoginComponent } from '~public/global-login/global-login.component';
import { ResetPasswordComponent } from '~public/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '~public/forgot-password/forgot-password.component';
import { AcceptPolicyComponent } from '~public/accept-policy/accept-policy.component';
import { UserStateGuard } from '~app/guards/user-state.guard';
import { YoungComponent } from '~app/components/public/young/young.component';
var routes = [
    {
        path: 'portal',
        // Replaced by update_loadChildren.js when deployed to live/staging/sandbox.
        loadChildren: '~authenticated/authenticated.module#AuthenticatedModule'
    },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'support', component: SupportComponent },
    { path: 'global-login', component: GlobalLoginComponent },
    { path: 'policy', component: AcceptPolicyComponent, canActivate: [UserStateGuard] },
    { path: 'young', component: YoungComponent },
    { path: '**', redirectTo: '/login', pathMatch: 'full' },
    { path: '', redirectTo: '/login', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
