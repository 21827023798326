export var LOAD_METADATA = '[Metadata] Load Metadata';
export var LOAD_METADATA_SUCCESS = '[Metadata] Load Metadata Success';
export var LOAD_METADATA_FAILURE = '[Metadata] Load Metadata Failure';
export var LOAD_CONSENTS = '[Consents] Load Consents';
export var LOAD_CONSENTS_SUCCESS = '[Consents] Load Consents Success';
export var LOAD_CONSENTS_FAILURE = '[Consents] Load Consents Failure';
export var LOAD_TRANSLATIONS = '[Translations] Load Translations';
export var LOAD_TRANSLATIONS_SUCCESS = '[Translations] Load Translations Success';
export var LOAD_TRANSLATIONS_FAILURE = '[Translations] Load Translations Failure';
export var TOGGLE_USER_IS_LOADED = '[IsUserLoaded] Toggle User Is Loaded';
export var TOGGLE_TRANSLATIONS_IS_LOADED = '[IsTranslationLoaded] Toggle Translations Is Loaded';
export var TOGGLE_IS_LOADING = '[IsLoading] Toggle Is Loading';
export var TOGGLE_IS_LOGGED_IN = '[IsLoggedIn] Toggle Is Logged In';
export var TOGGLE_IS_REDIRECTING = '[IsRedirecting] Toggle Is Redirecting';
export var INITIALISE_APP_STATE = '[InitialiseAppState] Set Default Values For App State';
export var SET_APP_ERROR_MESSAGE = '[AppErrorMessage] Set Application Error Message';
var LoadMetadata = /** @class */ (function () {
    function LoadMetadata(payload, churchId) {
        this.payload = payload;
        this.churchId = churchId;
        this.type = LOAD_METADATA;
    }
    return LoadMetadata;
}());
export { LoadMetadata };
var LoadMetadataSuccess = /** @class */ (function () {
    function LoadMetadataSuccess(payload, metaType) {
        this.payload = payload;
        this.metaType = metaType;
        this.type = LOAD_METADATA_SUCCESS;
    }
    return LoadMetadataSuccess;
}());
export { LoadMetadataSuccess };
var LoadMetadataFailure = /** @class */ (function () {
    function LoadMetadataFailure(payload) {
        this.payload = payload;
        this.type = LOAD_METADATA_FAILURE;
    }
    return LoadMetadataFailure;
}());
export { LoadMetadataFailure };
var LoadTranslations = /** @class */ (function () {
    // Payload is string culture.
    function LoadTranslations(payload) {
        this.payload = payload;
        this.type = LOAD_TRANSLATIONS;
    }
    return LoadTranslations;
}());
export { LoadTranslations };
var LoadTranslationsSuccess = /** @class */ (function () {
    function LoadTranslationsSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_TRANSLATIONS_SUCCESS;
    }
    return LoadTranslationsSuccess;
}());
export { LoadTranslationsSuccess };
var LoadTranslationsFailure = /** @class */ (function () {
    function LoadTranslationsFailure(payload) {
        this.payload = payload;
        this.type = LOAD_TRANSLATIONS_FAILURE;
    }
    return LoadTranslationsFailure;
}());
export { LoadTranslationsFailure };
var LoadConsents = /** @class */ (function () {
    // Payload is string culture.
    function LoadConsents(payload) {
        this.payload = payload;
        this.type = LOAD_CONSENTS;
    }
    return LoadConsents;
}());
export { LoadConsents };
var LoadConsentsSuccess = /** @class */ (function () {
    function LoadConsentsSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_CONSENTS_SUCCESS;
    }
    return LoadConsentsSuccess;
}());
export { LoadConsentsSuccess };
var LoadConsentsFailure = /** @class */ (function () {
    function LoadConsentsFailure(payload) {
        this.payload = payload;
        this.type = LOAD_CONSENTS_FAILURE;
    }
    return LoadConsentsFailure;
}());
export { LoadConsentsFailure };
var ToggleUserIsLoaded = /** @class */ (function () {
    function ToggleUserIsLoaded(payload) {
        this.payload = payload;
        this.type = TOGGLE_USER_IS_LOADED;
    }
    return ToggleUserIsLoaded;
}());
export { ToggleUserIsLoaded };
var ToggleTranslationsIsLoaded = /** @class */ (function () {
    function ToggleTranslationsIsLoaded(payload) {
        this.payload = payload;
        this.type = TOGGLE_TRANSLATIONS_IS_LOADED;
    }
    return ToggleTranslationsIsLoaded;
}());
export { ToggleTranslationsIsLoaded };
var ToggleIsLoading = /** @class */ (function () {
    function ToggleIsLoading(payload) {
        this.payload = payload;
        this.type = TOGGLE_IS_LOADING;
    }
    return ToggleIsLoading;
}());
export { ToggleIsLoading };
var ToggleIsLoggedIn = /** @class */ (function () {
    function ToggleIsLoggedIn(payload) {
        this.payload = payload;
        this.type = TOGGLE_IS_LOGGED_IN;
    }
    return ToggleIsLoggedIn;
}());
export { ToggleIsLoggedIn };
var ToggleIsRedirecting = /** @class */ (function () {
    function ToggleIsRedirecting(payload) {
        this.payload = payload;
        this.type = TOGGLE_IS_REDIRECTING;
    }
    return ToggleIsRedirecting;
}());
export { ToggleIsRedirecting };
var InitiliseAppState = /** @class */ (function () {
    function InitiliseAppState() {
        this.type = INITIALISE_APP_STATE;
    }
    return InitiliseAppState;
}());
export { InitiliseAppState };
var SetAppErrorMessage = /** @class */ (function () {
    function SetAppErrorMessage(payload) {
        this.payload = payload;
        this.type = SET_APP_ERROR_MESSAGE;
    }
    return SetAppErrorMessage;
}());
export { SetAppErrorMessage };
