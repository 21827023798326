<div class="input-group">
    <label for="control" [ngClass]="{'marlin-required': this.control.errors?.required}" *ngIf="label != ''" [innerHTML]='label | translate'></label>
    <div class="input-group-prepend">
        <div class="input-group-text d-flex justify-content-center">
            <i class="{{iconClass}}"></i>
        </div>
    </div>
    <input type="text" #input class="form-control" [ngClass]="{'ng-invalid': control.invalid, 'ng-touched': control.touched}"
        placeholder="{{placeholder | translate}}" (keypress)="onKeyPress($event)" (keyup)="onChange($event.target.value)" (blur)="onTouched()"
        id="control" name="control" [value]="control.value" maxlength="16" />
    <div class="alert alert-danger" *ngIf="control.invalid && control.touched" [innerHTML]='errorMessage | translate'>
    </div>
</div>