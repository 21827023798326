import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';

import { environment } from '~environments/environment';
import { UserLoginResponse, UserLogin, UserRegistrationRequest } from '~models/user';
import { App_Id } from '~constants/app-config';
import { ErrorHandler } from '~app/services/service-handlers';
import { User, UserResponse, UserUpdateRequest } from '~models/user';

import 'rxjs/add/operator/catch';
import { NgbDateISOParserFormatter } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-parser-formatter';
import { Store } from '@ngrx/store';
import { AppState } from '~app/store/app-state';
import { CookieService } from 'ngx-cookie';
import { formatDateForHapi } from '~app/logical-objects/helpers/date-time';
import * as utf8 from 'utf8';
import { MetadataTypes } from '~app/constants/church';
import { isNullOrUndefined } from 'util';

@Injectable()
export class UserService {
    private _Login_Endpoint = '/authentication';
    private _Logout_Endpoint = '/logout';
    private _Terms_And_Conditions = '/termsandconditionsaccepted';
    private _Get_User_Endpoint = '/users?type=full';
    private _Save_User_Endpoint = '/users';
    private _Get_Nonce_Endpoint = '/nonce';
    private _Get_Token_Endpoint = '/token';

    constructor(private httpClient: HttpClient, private store: Store<AppState>, private cookieService: CookieService) {}

    authenticate(userLogin: UserLogin): Observable<UserLoginResponse> {
        const body = {
            loginEmail: userLogin.loginEmail,
            password: userLogin.password
        };

        return this.httpClient
            .post<UserLoginResponse>(environment.marlinApiUrl + this._Login_Endpoint, body)
            .catch(ErrorHandler);
    }

    getNonce(token: string): Observable<{ nonce: string }> {
        const headers = new HttpHeaders({
            authorization: token
        });
        return this.httpClient
            .get<{ nonce: string }>(environment.marlinApiUrl + this._Get_Nonce_Endpoint, { headers: headers })
            .catch(ErrorHandler);
    }

    getToken(nonce: string): Observable<{ token: string }> {
        const headers = new HttpHeaders({
            authorization: nonce
        });
        return this.httpClient
            .get<{ token: string }>(environment.marlinApiUrl + this._Get_Token_Endpoint, { headers: headers })
            .catch(ErrorHandler);
    }

    updateUser(user): Observable<any> {
        let dateOfBirth = '';
        if (user.dateOfBirth) {
            dateOfBirth = formatDateForHapi(user.dateOfBirth.value);
        }
        const body: UserUpdateRequest = {
            firstName: user.firstName,
            familyName: user.familyName,
            genderId: user.genderId,
            // Both mobile and home phone can have + at the start. JOSE Encryption replaces + with a space when passed from Marlin as encrypted data. 
            // So as a workaround we replace + with P in Marlin and handle it in HAPI. MAPI will allow P in phone number
            mobilePhone: user.mobilePhone.replace("+","P"),
            homePhone: user.homePhone.replace("+","P"),
            ageGroupId: user.ageGroupId,
            dateOfBirth: dateOfBirth,
            address1: user.address,
            suburb: user.suburb,
            postCode: user.postCode,
            countryId: user.countryId,
            serviceId: user.churchLocationService.service,
            involvementId: user.involvementId
        };
        return this.httpClient
            .patch<UserLoginResponse>(environment.marlinApiUrl + this._Save_User_Endpoint, body)
            .catch(ErrorHandler);
    }

    updateTermsAndConditions(isAccepted: boolean) {
        const body = {
            termsAndConditionsAccepted: isAccepted
        };

        return this.httpClient
            .patch<UserLoginResponse>(environment.marlinApiUrl + this._Terms_And_Conditions, body)
            .catch(ErrorHandler);
    }

    registerUser(userRegistration) {
        let storedConsents = [];
        this.store
            .select(state => state.metadata[MetadataTypes.Consents])
            .take(1)
            .subscribe(consentData => {
                if (consentData) {
                    storedConsents = storedConsents.concat(consentData);
                }
            });
        let formConsents = userRegistration.consents.value;
            if (isNullOrUndefined(formConsents)) {
                formConsents = userRegistration.consents;
        }
        else {
            for (const key in formConsents) {
                if (formConsents[key]) {
                    formConsents['consentType6'] = true;
                }
            }
        }
        const returnConsents = [];
        for (const key in formConsents) {
            if (formConsents.hasOwnProperty(key)) {
                const typeID = key.replace('consentType', '');
                const wordingID = storedConsents.filter(consent => consent.consentTypeID == typeID)[0].id;

                returnConsents.push({
                    consentWordingID: wordingID,
                    agreed: formConsents[key],
                    consentTypeID: typeID
                });
            }
        }
        let dateOfBirth = '';
        if (userRegistration.dateOfBirth) {
            dateOfBirth = formatDateForHapi(userRegistration.dateOfBirth.value);
        }
        const body: UserRegistrationRequest = {
            loginEmail: userRegistration.loginEmail,
            firstName: userRegistration.firstName,
            familyName: userRegistration.familyName,
            genderId: userRegistration.genderId,
            // Both mobile and home phone can have + at the start. JOSE Encryption replaces + with a space when passed from Marlin as encrypted data. 
            // So as a workaround we replace + with P in Marlin and handle it in HAPI. MAPI will allow P in phone number
            mobilePhone: userRegistration.mobilePhone.replace("+","P"),
            homePhone: userRegistration.homePhone.replace("+","P"),
            ageGroupId: userRegistration.ageGroupId,
            churchId: userRegistration.churchLocationService.value.church,
            locationId: userRegistration.churchLocationService.value.location,
            serviceId: userRegistration.churchLocationService.value.service,
            dateOfBirth: dateOfBirth,
            involvementId: userRegistration.involvementId,
            recaptcha: userRegistration.recaptcha,
            consents: returnConsents,
            invitationToken: userRegistration.invitationToken
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

        return this.httpClient.post(environment.marlinApiUrl + this._Save_User_Endpoint, body,
            { headers: headers }).catch(ErrorHandler);
    }

    getUser(token: string): Observable<UserResponse> {
        const headers = new HttpHeaders({
            authorization: token
        });
        return this.httpClient
            .get<UserResponse>(environment.marlinApiUrl + this._Get_User_Endpoint, { headers: headers })
            .catch(ErrorHandler);
    }

    logoutUser() {
        return this.httpClient.post(environment.marlinApiUrl + this._Logout_Endpoint, {}).catch(ErrorHandler);
    }
}

export const SuccessMessages = {
    success: 'OK',
    successDetails: 'OK_UPDATE_DETAILS',
    successVerify: 'OK_VERIFY'
};
