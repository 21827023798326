import { FormBuilder, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { SupportTypes } from '~constants/app-config';
import { SupportService } from '~services/common/support.service';
import { UIControlStatuses } from '~constants/ui';
import { markAllTouched } from '~logic/helpers/form';
import * as appDataActions from '~store/actions/app-data.actions';
import { TranslatePipe } from '~app/core/pipes/translate.pipe';
import { validateEmail } from '~app/core/validators';
var SupportComponent = /** @class */ (function () {
    function SupportComponent(fb, activatedRoute, supportService, store) {
        var _this = this;
        this.fb = fb;
        this.activatedRoute = activatedRoute;
        this.supportService = supportService;
        this.store = store;
        this.status = UIControlStatuses.initial;
        this.supportForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, validateEmail]],
            phone: [''],
            supportType: ['', Validators.required],
            issueDescription: ['', Validators.required],
            recaptcha: ['', Validators.required]
        });
        this.supportTypes = SupportTypes.map(function (type) {
            return Object.assign({}, type, { text: new TranslatePipe(_this.store).transform(type.text) });
        });
        this.uiStatuses = UIControlStatuses;
    }
    SupportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (params) {
            var supportType = params['type'] || params['Type'];
            var supportValue;
            if (!supportType) {
                supportValue = _this.supportTypes[0].value;
            }
            else {
                supportValue = _this.supportTypes.find(function (obj) {
                    return obj.type.replace(' ', '').toLowerCase() == supportType.toLowerCase();
                });
            }
            _this.supportForm.get('supportType').setValue(supportValue.value);
        });
    };
    SupportComponent.prototype.submit = function (captchaResponse) {
        var _this = this;
        if (this.supportForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.supportService.support(this.supportForm.value).subscribe(function (data) {
                _this.status = UIControlStatuses.submitSuccess;
            }, function (err) {
                // Todo: For an unknown reason, on local a success behaves as predicted,
                // but on live a success goes to this error handler.
                if (err.status == 200) {
                    _this.status = UIControlStatuses.submitSuccess;
                }
                else {
                    _this.status = UIControlStatuses.submitError;
                }
                _this.store.dispatch(new appDataActions.ToggleIsLoading(false));
            }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
        }
        else {
            markAllTouched(this.supportForm);
        }
    };
    return SupportComponent;
}());
export { SupportComponent };
