import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { UserService } from '~services/auth/user.service';
import { MetadataService } from '~services/common/metadata.service';
import { PasswordService } from '~services/auth/password.service';
import { VerificationTokenService } from '~services/auth/verification_token.service';
import { AuthInterceptor } from '~app/services/auth.interceptor';
import { MockInterceptor } from '~tests/mock.interceptor';
import { environment } from '~environments/environment';

@NgModule({
    providers: [
        UserService,
        MetadataService,
        PasswordService,
        VerificationTokenService,
        //{ provide: HTTP_INTERCEPTORS, useClass: MockInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ]
})
export class ServicesModule {}
