import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { MetadataService } from '~services/common/metadata.service';
import * as appDataActions from '~actions/app-data.actions';
import { MetaData, Option, MetaDataOptions } from '~models/meta-data';
import { Translations } from '~models/common';
import { AppState } from '~store/app-state';
import { TranslationsMd5, Default_Language } from '~app/constants/app-config';

@Injectable()
export class AppDataEffects {
    metaData: MetaData;
    translations: Translations;

    @Effect()
    metaData$ = this.actions$.pipe(
        ofType(appDataActions.LOAD_METADATA),
        map(toPayload => {
            return toPayload;
        }),
        // Using switch map will cancel previous calls.  Merge map will execute all dispatches.
        mergeMap((data: any) => {
            return this.metadataService
                .getMetadata(data.payload, data.churchId)
                .map((metaData: MetaDataOptions) => {
                    let successData: MetaDataOptions = metaData;
                    if (data.payload == 'Country') {
                        // If the meta is country we need to transform the text and value
                        // because we need the value to be 'countryId' not 'countryCode'.
                        const transformedData: Option[] = metaData[0].options.map(metaResp => {
                            return Object.assign({}, data, { value: metaResp.meta1, text: metaResp.text });
                        });
                        successData = Object.assign({}, [
                            {
                                metaType: metaData[0].metaType,
                                options: transformedData
                            }
                        ]);
                    }
                    // Sort by alphabetical order before setting the store.
                    const storeData = Object.assign({}, [
                        {
                            metaType: metaData[0].metaType,
                            options: _.sortBy(successData[0].options, 'text')
                        }
                    ]);
                    return new appDataActions.LoadMetadataSuccess(storeData, data.payload);
                })
                .catch(error => of(new appDataActions.LoadMetadataFailure(error)));
        })
    );

    @Effect()
    translations$ = this.actions$.pipe(
        ofType(appDataActions.LOAD_TRANSLATIONS),
        map(toPayload => {
            return toPayload;
        }),
        mergeMap((payload: any) => {
            let md5 = '';
            let culture = payload ? payload : '';
            if (!_.isString(culture)) {
                culture = payload.payload;
            }
            const translationMd5: string = this.cookieService.get(TranslationsMd5);
            if (translationMd5) {
                // If there is an existing translations store then we assign the md5 value to check if our
                // translations is up to date with the server.
                md5 = translationMd5;
            }

            return this.metadataService
                .getTranslations(md5, culture)
                .map(translations => {
                    this.cookieService.put(TranslationsMd5, JSON.stringify(translations.md5));
                    return new appDataActions.LoadTranslationsSuccess(translations);
                })
                .catch(err => {
                    this.store.dispatch(new appDataActions.LoadTranslations(Default_Language));
                    this.store.dispatch(new appDataActions.LoadConsents(Default_Language));
                    return of(new appDataActions.LoadTranslationsFailure(err));
                });
        })
    );

    @Effect()
    consents$ = this.actions$.pipe(
        ofType(appDataActions.LOAD_CONSENTS),
        map(toPayload => {
            return toPayload;
        }),
        mergeMap((payload: any) => {
            let culture = payload ? payload : '';
            if (!_.isString(culture)) {
                culture = payload.payload;
            }
            return this.metadataService.getConsents(culture).map(consents => {
                return new appDataActions.LoadConsentsSuccess(consents);
            });
        })
    );

    constructor(
        private actions$: Actions,
        private metadataService: MetadataService,
        private store: Store<AppState>,
        private cookieService: CookieService
    ) {}
}
