import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'marlin-young',
    templateUrl: './young.component.html',
    styleUrls: ['./young.component.scss']
})
export class YoungComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {}

}
