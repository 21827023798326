import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
var YoungComponent = /** @class */ (function () {
    function YoungComponent(router) {
        this.router = router;
    }
    YoungComponent.prototype.ngOnInit = function () { };
    return YoungComponent;
}());
export { YoungComponent };
