import {
    FormControl,
    FormBuilder,
    AbstractControl,
    FormGroup,
    Validators,
    ControlContainer,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
    Component,
    Input,
    EventEmitter,
    Output,
    OnChanges,
    Optional,
    Host,
    SkipSelf,
    OnInit,
    ViewChild,
    forwardRef
} from '@angular/core';
import { verifyValidPhoneKey, verifyValidHomePhoneKey } from '~app/logical-objects/helpers/form';
import { InputVerficationKeyTypes } from '~constants/form';

@Component({
    selector: 'marlin-input-group',
    templateUrl: './input-group.component.html',
    styleUrls: ['./input-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputGroupComponent),
            multi: true
        }
    ]
})
export class InputGroupComponent implements ControlValueAccessor, OnChanges, OnInit {
    @ViewChild('input') input;
    control: AbstractControl = new FormControl();
    @Input() errorMessage = '';
    @Input() iconClass = '';
    @Input() label = '';
    @Input() placeholder = '';
    @Input() type = '';
    @Input() setError = false;
    @Input() formControlName: string;

    onChange;
    onTouched;

    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer
    ) {}

    ngOnChanges(target) {}

    ngOnInit() {
        this.control = this.controlContainer.control.get(this.formControlName);
    }

    writeValue(value: any): void {
        const input = this.input.nativeElement;
        input.value = value;
    }
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    onKeyPress(e) {
        switch (this.type) {
            case InputVerficationKeyTypes.tel:
                return verifyValidPhoneKey(e);
            case InputVerficationKeyTypes.homePhone:
                return verifyValidHomePhoneKey(e);
            default:
                break;
        }
    }
}
