import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';
var MetadataService = /** @class */ (function () {
    function MetadataService(httpClient) {
        this.httpClient = httpClient;
        this._Metadata_Endpoint = '/Metadata';
        this._Translations_Endpoint = '/TranslationStrings';
        this._Consents_Endpoint = '/Consents/Wordings';
        // Extreme IP as a free rate limit of 200 calls per minute.
        this._GeoIPUrl = 'https://extreme-ip-lookup.com/json/';
    }
    MetadataService.prototype.getMetadata = function (metaType, churchId) {
        if (!churchId) {
            churchId = 1;
        }
        return this.httpClient
            .get(environment.hapiUrl + this._Metadata_Endpoint + "?metaTypes=" + metaType + "&churchID=" + churchId)
            .catch(ErrorHandler);
    };
    MetadataService.prototype.getTranslations = function (md5, culture) {
        if (!culture) {
            culture = 'en-AU';
        }
        return this.httpClient
            .get(environment.hapiUrl + this._Translations_Endpoint + "?md5=" + md5 + (culture ? "&culture=" + culture : ''))
            .catch(ErrorHandler);
    };
    MetadataService.prototype.getConsents = function (languageCode) {
        if (!languageCode) {
            languageCode = 'en-AU';
        }
        var url = environment.hapiUrl + this._Consents_Endpoint;
        url += '?api_key=46b95198-60e5-4f0d-a417-4f59f5d0ffa5';
        url += languageCode ? "&languageCode=" + languageCode : '';
        return this.httpClient.get(url).catch(ErrorHandler);
    };
    MetadataService.prototype.getIPCountry = function () {
        return this.httpClient.get("" + this._GeoIPUrl).catch(ErrorHandler);
    };
    return MetadataService;
}());
export { MetadataService };
