const KeyTeamInvolvementId = 13;
const MinistryInvolvementId = 1;
const LeadershipInvolvementId = 14;

export const MyHillsongLoginPassThroughInvolvementIds = [
    KeyTeamInvolvementId,
    MinistryInvolvementId,
    LeadershipInvolvementId
];

export const UserStatuses = {
    failed: 'failed',
    globalPerson: 'globalPerson'
};
