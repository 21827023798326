import { FormGroup } from '@angular/forms';

export function validateRequiredGroup(ctls: string[]) {
    return (group: FormGroup): { [key: string]: any } => {
        let isValid = false;
        ctls.forEach(element => {
            const ctl = group.get(element).value;
            if (ctl != '') {
                isValid = true;
                return null;
            }
        });
        if (isValid) {
            return null;
        } else {
            return { validateRequiredGroup: true };
        }
    };
}

export function matchValues(password: string, confirmPassword: string) {
    return (group: FormGroup): { [key: string]: any } => {
        const valPassword = group.get(password).value;
        const valConfirmPassword = group.get(confirmPassword).value;

        if (valPassword != valConfirmPassword) {
            return { matchValues: true };
        } else {
            return null;
        }
    };
}

export function validateFormHasValues(ctl) {
    if (ctl.value) {
        // Loop through and check that church, location and service all has a value.
        for (const property in ctl.value.value) {
            if (!ctl.value.controls[property].value) {
                return { validateFormHasValues: true };
            }
        }
        return null;
    }
    return { validateFormHasValues: true };
}

export function validateEmail(ctl) {
    const regex: RegExp = /\w+([-+.']\w+)*[-+']?@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (regex.test(ctl.value)) {
        return null;
    } else {
        return { validateEmail: true };
    }
}

const invalidPrefixes = [
    '0000000',
    '1111111',
    '2222222',
    '3333333',
    '4444444',
    '5555555',
    '6666666',
    '7777777',
    '8888888',
    '9999999',
    '1234567',
    '0123456',
    '9876543',
    '7654321'
];

export function validatePhone(ctl) {
    const regex: RegExp = /^\+?[\d]{5,}$/;
    const isValidPrefix = invalidPrefixes.every(number => {
        return !ctl.value.startsWith(number);
    });

    if ((regex.test(ctl.value) || ctl.value == '') && isValidPrefix) {
        return null;
    } else {
        return { validatePhone: true };
    }
}

export function validateHomePhone(ctl) {
    const regex: RegExp = /^\+?[\d\(\)]{5,}$/;
    const isValidPrefix = invalidPrefixes.every(number => {
        return !ctl.value.startsWith(number);
    });

    if ((regex.test(ctl.value) || ctl.value == '') && isValidPrefix) {
        return null;
    } else {
        return { validateHomePhone: true };
    }
}
