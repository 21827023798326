import { MetadataService } from '~services/common/metadata.service';
import { Store } from '@ngrx/store';
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    EventEmitter,
    Output,
    Optional,
    Host,
    SkipSelf,
    forwardRef
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
    FormBuilder,
    Validators,
    FormGroup,
    ControlValueAccessor,
    ControlContainer,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import { Option, MetaData } from '~models/meta-data';
import { AppState } from '~store/app-state';
import { ChurchLocationService, InitialiseChurchLocationService } from '~models/church';
import * as metadataAction from '~store/actions/app-data.actions';
import { MetadataTypes, DefaultChurchByCountryCode } from '~app/constants/church';
import { markAllUntouched } from '~app/logical-objects/helpers/form';

@Component({
    selector: 'marlin-church-location-service',
    templateUrl: './church-location-service.component.html',
    styleUrls: ['./church-location-service.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChurchLocationServiceComponent),
            multi: true
        }
    ]
})
export class ChurchLocationServiceComponent implements OnInit, OnDestroy, ControlValueAccessor {
    churchLocationServiceForm = this.fb.group({
        church: [null, Validators.required],
        location: [{ value: null, disabled: true }, Validators.required],
        service: [{ value: null, disabled: true }, Validators.required]
    });
    churches: Option[] = [];
    locations: Option[] = [];
    services: Option[] = [];
    isSubscriptionActive = true;

    onChange = (form: FormGroup) => {};
    onTouched = () => {};

    // If Church or Location is set by default then
    // the drop down for them will automatically be hidden.
    // @summary Pass a name or id for church location service.

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder,
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        private metadataService: MetadataService
    ) {}

    ngOnInit() {
        // Subscribe to changes/data from church location service metadata store.
        this.store.select(state => state.metadata[MetadataTypes.Church]).subscribe(churchesData => {
            if (churchesData) {
                this.churches = churchesData.options;
                this.onChange(this.churchLocationServiceForm);
                // If no default value is passed we set the church value based on user IP.
                if (this.churchLocationServiceForm.controls.church.value == null) {
                    this.metadataService.getIPCountry().subscribe(data => {
                        const churchId = DefaultChurchByCountryCode[data.countryCode.valueOf()];
                        if (churchId != null) {
                            this.dropdownChangeValue(churchId, 'Church');
                        }
                    });
                }
            }
        });

        // Get list of church location service. The take(1) call will automatically complete this
        // subscription and is a one time call.
        this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.Church));
    }

    writeValue(value: any): void {
        if (value) {
            this.churchLocationServiceForm.setValue({
                church: value.church,
                location: value.location,
                service: value.service
            });
        } else {
            this.churchLocationServiceForm.setValue({
                church: null,
                location: null,
                service: null
            });
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    dropdownChangeValue(data, type: string) {
        // Assign new church location service array value to the relevant drop down depending on the
        // selected item (data) that was passed.
        const church = this.churchLocationServiceForm.get('church');
        const location = this.churchLocationServiceForm.get('location');
        const service = this.churchLocationServiceForm.get('service');
        if (type) {
            switch (type) {
                case MetadataTypes.Church:
                    if (!data) {
                        location.setValue(null);
                        location.disable();
                        service.setValue(null);
                        service.disable();
                        return;
                    }
                    // Set locations to the selected church location options, then set the data
                    // model to the selected church value.
                    this.locations = this.churches.find(c => c.value === data).child.options;
                    church.setValue(data);
                    location.setValue(null);
                    location.enable();
                    service.setValue(null);
                    service.disable();
                    location.updateValueAndValidity();
                    service.updateValueAndValidity();
                    this.services = [];
                    break;
                case MetadataTypes.Location:
                    if (!data) {
                        service.setValue(null);
                        service.disable();
                        return;
                    }
                    // Set services to the selected location service options, then set the data
                    // model to the selected location value.
                    this.services = this.locations.find(c => c.value === data).child.options;
                    location.setValue(data);
                    location.enable();
                    service.setValue(null);
                    service.enable();
                    service.updateValueAndValidity();
                    break;
                default:
                    break;
            }
            markAllUntouched(this.churchLocationServiceForm);
        } else if (data) {
            service.setValue(data);
        }
    }

    ngOnDestroy() {
        // Unsubscribe to observables in this component.
        this.isSubscriptionActive = false;
    }
}
