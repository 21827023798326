import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, AbstractControl, ControlValueAccessor, ControlContainer } from '@angular/forms';
import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
var DropdownComponent = /** @class */ (function () {
    function DropdownComponent(store, config, controlContainer) {
        this.store = store;
        this.controlContainer = controlContainer;
        this.control = new FormControl();
        this.data = [];
        this.title = '';
        this.class = 'form-control w-100';
        this.type = '';
        this.toolTip = '';
        this.errorMessage = '';
        this.onChange = function (val) { };
        this.onTouched = function () { };
        if (!this.toolTipConfig) {
            config.placement = 'right';
            config.triggers = 'click';
        }
        else {
            config = this.toolTipConfig;
        }
    }
    DropdownComponent.prototype.ngOnInit = function () {
        this.control = this.controlContainer.control.get(this.formControlName);
    };
    DropdownComponent.prototype.writeValue = function (value) {
        if (value) {
            this.selectedValue = value;
        }
    };
    DropdownComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DropdownComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    return DropdownComponent;
}());
export { DropdownComponent };
