import * as tslib_1 from "tslib";
import { CookieService } from 'ngx-cookie';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/observable/of';
import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { MetadataService } from '~services/common/metadata.service';
import * as appDataActions from '~actions/app-data.actions';
import { TranslationsMd5, Default_Language } from '~app/constants/app-config';
var AppDataEffects = /** @class */ (function () {
    function AppDataEffects(actions$, metadataService, store, cookieService) {
        var _this = this;
        this.actions$ = actions$;
        this.metadataService = metadataService;
        this.store = store;
        this.cookieService = cookieService;
        this.metaData$ = this.actions$.pipe(ofType(appDataActions.LOAD_METADATA), map(function (toPayload) {
            return toPayload;
        }), 
        // Using switch map will cancel previous calls.  Merge map will execute all dispatches.
        mergeMap(function (data) {
            return _this.metadataService
                .getMetadata(data.payload, data.churchId)
                .map(function (metaData) {
                var successData = metaData;
                if (data.payload == 'Country') {
                    // If the meta is country we need to transform the text and value
                    // because we need the value to be 'countryId' not 'countryCode'.
                    var transformedData = metaData[0].options.map(function (metaResp) {
                        return Object.assign({}, data, { value: metaResp.meta1, text: metaResp.text });
                    });
                    successData = Object.assign({}, [
                        {
                            metaType: metaData[0].metaType,
                            options: transformedData
                        }
                    ]);
                }
                // Sort by alphabetical order before setting the store.
                var storeData = Object.assign({}, [
                    {
                        metaType: metaData[0].metaType,
                        options: _.sortBy(successData[0].options, 'text')
                    }
                ]);
                return new appDataActions.LoadMetadataSuccess(storeData, data.payload);
            })
                .catch(function (error) { return of(new appDataActions.LoadMetadataFailure(error)); });
        }));
        this.translations$ = this.actions$.pipe(ofType(appDataActions.LOAD_TRANSLATIONS), map(function (toPayload) {
            return toPayload;
        }), mergeMap(function (payload) {
            var md5 = '';
            var culture = payload ? payload : '';
            if (!_.isString(culture)) {
                culture = payload.payload;
            }
            var translationMd5 = _this.cookieService.get(TranslationsMd5);
            if (translationMd5) {
                // If there is an existing translations store then we assign the md5 value to check if our
                // translations is up to date with the server.
                md5 = translationMd5;
            }
            return _this.metadataService
                .getTranslations(md5, culture)
                .map(function (translations) {
                _this.cookieService.put(TranslationsMd5, JSON.stringify(translations.md5));
                return new appDataActions.LoadTranslationsSuccess(translations);
            })
                .catch(function (err) {
                _this.store.dispatch(new appDataActions.LoadTranslations(Default_Language));
                _this.store.dispatch(new appDataActions.LoadConsents(Default_Language));
                return of(new appDataActions.LoadTranslationsFailure(err));
            });
        }));
        this.consents$ = this.actions$.pipe(ofType(appDataActions.LOAD_CONSENTS), map(function (toPayload) {
            return toPayload;
        }), mergeMap(function (payload) {
            var culture = payload ? payload : '';
            if (!_.isString(culture)) {
                culture = payload.payload;
            }
            return _this.metadataService.getConsents(culture).map(function (consents) {
                return new appDataActions.LoadConsentsSuccess(consents);
            });
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppDataEffects.prototype, "metaData$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppDataEffects.prototype, "translations$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AppDataEffects.prototype, "consents$", void 0);
    return AppDataEffects;
}());
export { AppDataEffects };
