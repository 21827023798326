<div *ngIf="!isDeleted">
    <div class="form-title">
        <h3>Delete your Account.</h3>
        <p>Please let us know the reason you would like to delete your account.</p>
        <textarea placeholder="eg: No longer attend Hillsong Church"></textarea>
    </div>
    <div class="form-group">
        <button class="btn btn-primary w-100" (click)="confirmDelete()">Delete My Account</button>
    </div>
</div>
<div *ngIf="isDeleted">
    <div class="form-title">
        <h3>Your Account has been deleted</h3>
    </div>
    <div class="form-group">
        <button class="btn btn-primary w-100" [routerLink]="['/login']" [innerHTML]="'gblReturnToLogin' | translate"></button>
    </div>
</div>