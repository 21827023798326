<div class="form-title">
    <h3 [innerHTML]="'gblPoliciesUpdated' | translate"></h3>
    <div class="terms-conditions">
        <label>
            <span [innerHTML]="'gblPleaseReadPolicies' | translate"></span>
            <a [href]="policiesUrl" [innerHTML]="'gblHere' | translate"></a>.
        </label>
        <label [innerHTML]="'gblPrivacyDataTermsAndConditionsAgreement' | translate"></label>
        <label [innerHTML]="'gblCookiesAgreement' | translate"></label>
    </div>
    <div class="form-group">
        <div class="checkbox terms-conditions">
            <input type="checkbox" [value]="1" id="policyAcceptance" />
            <label for="policyAcceptance" [innerHTML]="'gblAccept' | translate"> </label>
        </div>
    </div>
</div>
<div class="form-group">
    <button class="btn btn-primary w-100" (click)="submit()" [innerHTML]="'lclSubmit' | translate"></button>
</div>