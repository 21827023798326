import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

import { SupportTypes } from '~constants/app-config';
import { SupportService } from '~services/common/support.service';
import { UIControlStatuses } from '~constants/ui';
import { markAllTouched } from '~logic/helpers/form';
import { AppState } from '~app/store/app-state';
import * as appDataActions from '~store/actions/app-data.actions';
import { TranslatePipe } from '~app/core/pipes/translate.pipe';
import { validateEmail } from '~app/core/validators';

@Component({
    selector: 'marlin-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    providers: [SupportService]
})

export class SupportComponent implements OnInit {
    status = UIControlStatuses.initial;
    supportForm = this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, validateEmail]],
        phone: [''],
        supportType: ['', Validators.required],
        issueDescription: ['', Validators.required],
        recaptcha: ['', Validators.required]
    });

    supportTypes = SupportTypes.map(type => {
        return Object.assign({}, type, { text: new TranslatePipe(this.store).transform(type.text) });
    });
    uiStatuses = UIControlStatuses;

    constructor(
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private supportService: SupportService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            const supportType: string = params['type'] || params['Type'];
            let supportValue;
            if (!supportType) {
                supportValue = this.supportTypes[0].value;
            } else {
                supportValue = this.supportTypes.find(obj => {
                    return obj.type.replace(' ', '').toLowerCase() == supportType.toLowerCase();
                });
            }
            this.supportForm.get('supportType').setValue(supportValue.value);
        });
    }

    submit(captchaResponse: string) {
        if (this.supportForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.supportService.support(this.supportForm.value).subscribe(
                data => {
                    this.status = UIControlStatuses.submitSuccess;
                },
                err => {
                    // Todo: For an unknown reason, on local a success behaves as predicted,
                    // but on live a success goes to this error handler.
                    if (err.status == 200) {
                        this.status = UIControlStatuses.submitSuccess;
                    } else {
                        this.status = UIControlStatuses.submitError;
                    }
                    this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                },
                () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
            );
        } else {
            markAllTouched(this.supportForm);
        }
    }
}
