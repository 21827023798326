import { Store } from '@ngrx/store';
import { OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ControlValueAccessor, ControlContainer } from '@angular/forms';
import { MetadataTypes } from '~app/constants/church';
var ConsentsComponent = /** @class */ (function () {
    function ConsentsComponent(store, fb, controlContainer) {
        this.store = store;
        this.fb = fb;
        this.controlContainer = controlContainer;
        this.consentsForm = this.fb.group({
            consentType1: [false],
            consentType2: [false],
            consentType3: [false],
            consentType4: [false],
            consentType5: [false],
            consentType6: [false]
        });
        this.consentTypes = {
            CONSENT_LEGAL_TEXT: 6,
            EMAIL: 1,
            PHONE: 2,
            SMS: 3,
            MAIL: 4,
            SOCIAL: 5,
            SOCIAL_HELP: 9
        };
        this.consents = null;
        this.onChange = function (form) { };
        this.onTouched = function () { };
    }
    ConsentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.select(function (state) { return state.metadata[MetadataTypes.Consents]; }).subscribe(function (consentData) {
            if (consentData) {
                _this.consents = consentData;
            }
        });
    };
    ConsentsComponent.prototype.writeValue = function (value) {
        if (value) {
            this.consentsForm.setValue({
                consentType1: value.consentType1,
                consentType2: value.consentType2,
                consentType3: value.consentType3,
                consentType4: value.consentType4,
                consentType5: value.consentType5,
                consentType6: value.consentType6
            });
        }
        else {
            this.consentsForm.setValue({
                consentType1: false,
                consentType2: false,
                consentType3: false,
                consentType4: false,
                consentType5: false,
                consentType6: false
            });
        }
    };
    ConsentsComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    ConsentsComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    ConsentsComponent.prototype.getWordingForConsentType = function (consentTypeID) {
        return this.consents.filter(function (consent) { return consent.consentTypeID == consentTypeID; })[0].text;
    };
    return ConsentsComponent;
}());
export { ConsentsComponent };
