import { VERSION } from '~environments/version';
import { environment } from '~environments/environment';
import * as rg4js from 'raygun4js';
var VERSION_NUMBER = '1.0.0.0';
rg4js('apiKey', environment.raygunAPIKey);
rg4js('setVersion', VERSION_NUMBER);
rg4js('enableCrashReporting', true);
rg4js('enablePulse', true);
rg4js('logContentsOfXhrCalls', true);
rg4js('setDevelopmentHostnames', ['localhost']);
var RaygunErrorHandler = /** @class */ (function () {
    function RaygunErrorHandler() {
    }
    RaygunErrorHandler.prototype.handleError = function (e) {
        // Output to console if the environment is local otherwise send it to raygun.
        if (environment.isLocal) {
            console.error(e);
        }
        else {
            rg4js('send', {
                error: JSON.stringify(e, ['message', 'arguments', 'type', 'name']),
                version: JSON.stringify(VERSION)
            });
        }
    };
    return RaygunErrorHandler;
}());
export { RaygunErrorHandler };
