export const MetadataTypes = {
    Church: 'Church',
    Location: 'Location',
    Service: 'Service',
    Gender: 'Gender',
    AgeGroup: 'AgeGroup',
    Language: 'Language',
    Country: 'Country',
    Translations: 'Translation',
    Consents: 'Consents'
};

export const DefaultChurchByCountryCode = {
    AU: '1',
    GB: '2',
    UA: '3',
    ZA: '4',
    SV: '5',
    FR: '6',
    RU: '7',
    US: null,
    // Switzerland and Germany is grouped under Germany.
    DE: '9',
    CH: '9',
    DK: '10',
    NL: '11'
};

// Global Churches
export const NewYork = 8;
export const California = 13;

// Australian Locations
export const ChurchOfTheAir = 153;

// Configuration
export const ExcludedChurchesNotRequiringAgeGroupGender = [California];
export const LimitInvolvementOptionToHillsongIsHome = [ChurchOfTheAir];

