﻿<!-- isTranslationsLoaded and isRedirecting state are application level states which indicates to the user that the application is waiting to be ready.
This also applies in the case where we are redirecting them to another part of this system.
-->
<div class="wrapper" *ngIf="appStatus.isTranslationsLoaded && !appStatus.isRedirecting">
    <div class="header d-flex justify-content-between">
        <div class="myhillsong-logo" [routerLink]="['/portal/home']">
            <div id="divLogo" class="d-flex align-items-center"></div>
            <h3 class="d-flex align-items-center">MyHillsong</h3>
        </div>
        <div class="d-flex">
            <kendo-dropdownlist class="language-dropdown" [valuePrimitive]='true' [data]='languages' [ignoreCase]='true' [valueField]='"value"'
                [textField]='"text"' [value]='language' (valueChange)='changeLanguage($event)'>
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span class="language-dropdown__item" [innerHTML]="dataItem?.text"></span>
                </ng-template>
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <div class="language-dropdown__selected">
                        <span [innerHTML]="dataItem?.text"></span>
                    </div>
                </ng-template>
            </kendo-dropdownlist>
            <div ngbDropdown placement="bottom-right" name="profile" class="profile" *ngIf="appStatus.isLoggedIn">
                <button class="btn btn-outline-secondary" id="profile" ngbDropdownToggle>
                    <i class="fa fa-user-circle"></i>
                </button>
                <div class="scrollable-menu" ngbDropdownMenu aria-labelledby="profile">
                    <button class="dropdown-item" (click)="logout()" [innerHTML]="'pgtLogout' | translate"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="container d-md-flex d-lg-flex">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-sm-9 col-md-7 content">
                <span [innerHTML]="heading | translate"></span>
                <div *ngIf="errorMessage" class="alert alert-danger" [innerHTML]="errorMessage | translate"></div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class=" footer">
        <div class="row footer-bar d-flex align-items-center">
            <div class="col-11 footer-bar__left">
                <label>
                    <span [innerHTML]="'gblMoreAboutHillsong' | translate"></span>
                    <a href="https://hillsong.com" class="a--padding-left">hillsong.com.</a>
                </label>
                <label>Copyright © {{currentYear}} Hillsong International.</label>
                <label>
                    <a href="{{policiesUrl}}" [innerHTML]="'pmtPolicies' | translate"></a>
                </label>
            </div>
            <div class="col-1 footer-bar__right">
                <label>
                    <a *ngIf='showHelpIcon()' [routerLink]="['/support']" [queryParams]="{type: 'GeneralEnquiries'}">
                        <i class="fa fa-question-circle fa-2x"></i>
                    </a>
                </label>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<!-- Modals indicate that we are processing their request.  Things such as on submit should be changing the isLoading status.
Modals are always loaded if the application is not ready such as when translations are loading or the app is redirecting.
-->
<div *ngIf="appStatus.isLoading || !appStatus.isTranslationsLoaded || !appStatus.isUserLoaded || appStatus.isRedirecting">
    <div class="modal">
    </div>
    <div class="loader-container">
        <div class="row align-items-center justify-content-center">
            <div class="align-items-center">
                <div id='divLoadingImage'></div>
                <div class="spinner-text"></div>
                <div class="spinner text-center">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </div>
        </div>
    </div>
</div>