import { Component } from '@angular/core';

@Component({
    selector: 'marlin-home-button',
    template: ` <a [routerLink]="['/portal/home']">
                    <i class="far fa-home fa-2x"></i>
                    <span [innerHTML]="'gblHomePage' | translate"></span>
                </a>`
})
export class HomeButtonComponent {}
