import { Component, OnInit, Optional, Host, SkipSelf, Input, forwardRef } from '@angular/core';
import {
    ControlContainer,
    FormBuilder,
    FormGroup,
    Validators,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import * as moment from 'moment';
import { DatePickerData } from '~app/models/data';
import { CookieLanguageName } from '~app/constants/app-config';
import { CookieService } from 'ngx-cookie';

@Component({
    selector: 'marlin-date-dropdown',
    templateUrl: './date-dropdown.component.html',
    styleUrls: ['./date-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateDropdownComponent),
            multi: true
        }
    ]
})
export class DateDropdownComponent implements OnInit, ControlValueAccessor {
    dayOptions;
    monthOptions;
    yearOptions;

    dateForm = this.fb.group({
        day: [{ value: null, disabled: true }, Validators.required],
        month: [{ value: null, disabled: true }, Validators.required],
        year: [null, Validators.required]
    });

    @Input() maxDate: DatePickerData = null;
    @Input() minDate: DatePickerData = null;

    constructor(
        private fb: FormBuilder,
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer,
        private cookieService: CookieService
    ) {}

    ngOnInit() {
        let minYear = 1900;
        let maxYear = new Date().getFullYear();

        if (this.maxDate !== null) {
            maxYear = this.maxDate.year;
        }
        if (this.minDate !== null) {
            minYear = this.minDate.year;
        }

        this.generateYearOptions(minYear, maxYear);
    }

    onChange = (form: FormGroup) => {};
    onTouched = () => {};

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    writeValue(value: any): void {
        if (value != '' && value != null) {
            this.dateForm.setValue({
                day: value.day,
                month: value.month,
                year: value.year
            });
        } else {
            this.dateForm.setValue({
                day: null,
                month: null,
                year: null
            });
        }
    }

    generateDayOptions() {
        let dayOptions = [];
        const year = this.dateForm.controls.year.value;
        const month = this.dateForm.controls.month.value;

        let minDay = 1;
        let maxDay = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

        if (this.minDate !== null) {
            if (this.minDate.year == year.value && this.minDate.month == month.value) {
                minDay = this.minDate.day;
            }
        }

        if (this.maxDate !== null) {
            if (this.maxDate.year == year.value && this.maxDate.month == month.value) {
                maxDay = this.maxDate.day;
            }
        }

        for (let index = minDay; index <= maxDay; index++) {
            dayOptions.push({ text: index, value: index });
        }
        this.dayOptions = dayOptions;
    }

    generateMonthOptions() {
        let monthOptions = [];
        const year = this.dateForm.controls.year.value;
        let minMonth = 1;
        let maxMonth = 12;

        if (this.minDate !== null) {
            if (this.minDate.year == year) {
                minMonth = this.minDate.month;
            }
        }

        if (this.maxDate !== null) {
            if (this.maxDate.year == year) {
                maxMonth = this.maxDate.month;
            }
        }

        const language = this.cookieService.get(CookieLanguageName).substring(0, 2);
        const monthsArray = moment.localeData(language).monthsShort();

        for (let index = minMonth; index <= maxMonth; index++) {
            monthOptions.push({ text: monthsArray[index - 1], value: index });
        }
        this.monthOptions = monthOptions;
    }

    generateYearOptions(minYear: number, maxYear: number) {
        let yearOptions = [];
        for (let index = maxYear; index >= minYear; index--) {
            yearOptions.push({ text: index, value: index });
        }
        this.yearOptions = yearOptions;
    }

    yearMonthChange(type: string) {
        const year = this.dateForm.controls.year;
        const month = this.dateForm.controls.month;
        const day = this.dateForm.controls.day;

        switch (type) {
            case 'year':
                month.updateValueAndValidity();
                day.updateValueAndValidity();

                if (year.value !== null) {
                    month.enable();
                    // Populate min and max month depending on the input.
                    this.generateMonthOptions();
                    if (month.value === 2) {
                        this.generateDayOptions();
                    }
                }
                break;
            case 'month':
                this.dayOptions = [];
                day.updateValueAndValidity();
                if (this.dateForm.controls.year.value !== null) {
                    day.enable();
                    // Populate min and max date depending on the input.
                    this.generateDayOptions();
                }
                break;
        }
    }
}
