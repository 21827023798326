import { MetadataService } from '~services/common/metadata.service';
import { Store } from '@ngrx/store';
import { OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ControlValueAccessor, ControlContainer } from '@angular/forms';
import { Option } from '~models/meta-data';
import * as metadataAction from '~store/actions/app-data.actions';
import { MetadataTypes, DefaultChurchByCountryCode } from '~app/constants/church';
import { markAllUntouched } from '~app/logical-objects/helpers/form';
var ChurchLocationServiceComponent = /** @class */ (function () {
    // If Church or Location is set by default then
    // the drop down for them will automatically be hidden.
    // @summary Pass a name or id for church location service.
    function ChurchLocationServiceComponent(store, fb, controlContainer, metadataService) {
        this.store = store;
        this.fb = fb;
        this.controlContainer = controlContainer;
        this.metadataService = metadataService;
        this.churchLocationServiceForm = this.fb.group({
            church: [null, Validators.required],
            location: [{ value: null, disabled: true }, Validators.required],
            service: [{ value: null, disabled: true }, Validators.required]
        });
        this.churches = [];
        this.locations = [];
        this.services = [];
        this.isSubscriptionActive = true;
        this.onChange = function (form) { };
        this.onTouched = function () { };
    }
    ChurchLocationServiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Subscribe to changes/data from church location service metadata store.
        this.store.select(function (state) { return state.metadata[MetadataTypes.Church]; }).subscribe(function (churchesData) {
            if (churchesData) {
                _this.churches = churchesData.options;
                _this.onChange(_this.churchLocationServiceForm);
                // If no default value is passed we set the church value based on user IP.
                if (_this.churchLocationServiceForm.controls.church.value == null) {
                    _this.metadataService.getIPCountry().subscribe(function (data) {
                        var churchId = DefaultChurchByCountryCode[data.countryCode.valueOf()];
                        if (churchId != null) {
                            _this.dropdownChangeValue(churchId, 'Church');
                        }
                    });
                }
            }
        });
        // Get list of church location service. The take(1) call will automatically complete this
        // subscription and is a one time call.
        this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.Church));
    };
    ChurchLocationServiceComponent.prototype.writeValue = function (value) {
        if (value) {
            this.churchLocationServiceForm.setValue({
                church: value.church,
                location: value.location,
                service: value.service
            });
        }
        else {
            this.churchLocationServiceForm.setValue({
                church: null,
                location: null,
                service: null
            });
        }
    };
    ChurchLocationServiceComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    ChurchLocationServiceComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    ChurchLocationServiceComponent.prototype.dropdownChangeValue = function (data, type) {
        // Assign new church location service array value to the relevant drop down depending on the
        // selected item (data) that was passed.
        var church = this.churchLocationServiceForm.get('church');
        var location = this.churchLocationServiceForm.get('location');
        var service = this.churchLocationServiceForm.get('service');
        if (type) {
            switch (type) {
                case MetadataTypes.Church:
                    if (!data) {
                        location.setValue(null);
                        location.disable();
                        service.setValue(null);
                        service.disable();
                        return;
                    }
                    // Set locations to the selected church location options, then set the data
                    // model to the selected church value.
                    this.locations = this.churches.find(function (c) { return c.value === data; }).child.options;
                    church.setValue(data);
                    location.setValue(null);
                    location.enable();
                    service.setValue(null);
                    service.disable();
                    location.updateValueAndValidity();
                    service.updateValueAndValidity();
                    this.services = [];
                    break;
                case MetadataTypes.Location:
                    if (!data) {
                        service.setValue(null);
                        service.disable();
                        return;
                    }
                    // Set services to the selected location service options, then set the data
                    // model to the selected location value.
                    this.services = this.locations.find(function (c) { return c.value === data; }).child.options;
                    location.setValue(data);
                    location.enable();
                    service.setValue(null);
                    service.enable();
                    service.updateValueAndValidity();
                    break;
                default:
                    break;
            }
            markAllUntouched(this.churchLocationServiceForm);
        }
        else if (data) {
            service.setValue(data);
        }
    };
    ChurchLocationServiceComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe to observables in this component.
        this.isSubscriptionActive = false;
    };
    return ChurchLocationServiceComponent;
}());
export { ChurchLocationServiceComponent };
