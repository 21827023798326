import { Store } from '@ngrx/store';
import { markAllTouched } from '~app/logical-objects/helpers/form';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { AppState } from '~app/store/app-state';
import { UIControlStatuses } from '~constants/ui';
import { PasswordService } from '~services/auth/password.service';
import { VerificationTokenService } from '~services/auth/verification_token.service';
import * as appDataActions from '~store/actions/app-data.actions';
import { getTranslatedErrorMessageFromResponse } from '~app/logical-objects/helpers/system';
import { validateEmail } from '~app/core/validators';

@Component({
    selector: 'marlin-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm = this.fb.group({
        email: ['', [Validators.required, validateEmail]]
    });
    uiStatuses = UIControlStatuses;
    uiStatus = this.uiStatuses.initial;
    isUnconfirmedCognitoUser: boolean;
    constructor(
        private fb: FormBuilder,
        private store: Store<AppState>,
        private passwordService: PasswordService,
        private verficationTokenService: VerificationTokenService
    ) {}

    ngOnInit() {
        this.isUnconfirmedCognitoUser = false;
    }

    submit() {
        if (this.forgotPasswordForm.valid) {
            // Reset messages and initiate loading.
            this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.passwordService.resetPassword(this.forgotPasswordForm.get('email').value).subscribe(
                data => {
                    this.uiStatus = UIControlStatuses.submitSuccess;
                },
                err => this.toggleForgotPasswordError(err), 
                () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
            );
        } else {
            markAllTouched(this.forgotPasswordForm);
        }
    }

    resendVerificationEmail() {
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.verficationTokenService
            .passwordResetResendVerificationEmail(this.forgotPasswordForm.get('email').value)
            .subscribe(
                data => {
                    this.uiStatus = UIControlStatuses.submitSuccess;
                    this.isUnconfirmedCognitoUser = false;
                },
                err => this.toggleForgotPasswordError(err),
                () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
            );
    }

    toggleForgotPasswordError(err) {
        console.log(`toggleForgotPasswordError ${err}`);
        let errorMessage = '';
        if (
            err.error.status.indexOf('0007') != -1
        ) {
            errorMessage = "";
            this.isUnconfirmedCognitoUser = true;
        }
        else {
            errorMessage = getTranslatedErrorMessageFromResponse(err.error.status);
        }
        this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
    }
}
