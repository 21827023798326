import { TimePeriodDesignationKey } from '~constants/date-time';
import * as moment from 'moment';

export function getTimePeriodDesignation(): string {
    const myDate = new Date();
    if (myDate.getHours() < 12) {
        return TimePeriodDesignationKey.morning;
    } else if (myDate.getHours() >= 12 && myDate.getHours() <= 17) {
        // Hour is from noon to 5pm (actually to 5:59 pm).
        return TimePeriodDesignationKey.afternoon;
    } else if (myDate.getHours() > 17 && myDate.getHours() <= 24) {
        // The hour is after 5pm, so it is between 6pm and midnight.
        return TimePeriodDesignationKey.evening;
    }
}

export function formatDateForHapi(date): string {
    return `${date.year}-${date.month < 10 ? `0${date.month}` : date.month}-${
        date.day < 10 ? `0${date.day}` : date.day
    }T00:00:00Z`;
}

export function is16OrOver(dateOfBirth: moment.Moment): Boolean {
    const sixteenYearsAgo = moment().subtract(16, 'years');
    if (dateOfBirth < sixteenYearsAgo) {
        // 16 years or older
        return true;
    } else {
        // under 16
        return false;
    }
}
