import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { UserService } from '~app/services/auth/user.service';
import { AppState } from '~app/store/app-state';
import { Store } from '@ngrx/store';
import { AuthTokenName } from '~constants/app-config';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userActions from '~store/actions/user.actions';

@Component({
    selector: 'marlin-global-login',
    template: ''
})
export class GlobalLoginComponent implements OnInit {
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            const nonce = params['nonce'];
            if (!nonce) {
                this.router.navigate(['/login']);
            } else {
                this.store.dispatch(new appDataActions.ToggleIsLoading(true));
                this.userService.getToken(nonce).subscribe(
                    data => {
                        window.sessionStorage.setItem(AuthTokenName, data.token);
                        this.store.dispatch(new userActions.GetUser(data.token));
                    },
                    err => {
                        this.router.navigate(['/login']);
                        handleMapiError(this.store, err.error.status);
                    }
                );
            }
        });

        this.store.select(state => state.user).subscribe(user => {
            if (user.personId) {
                this.router.navigate(['/portal/home']);
                this.store.dispatch(new appDataActions.ToggleIsLoading(false));
            }
        });
    }
}
