import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from '~authenticated/home/home.component';
import { CoreModule } from '~core/core.module';
import { ControlsModule } from '~shared/controls/controls.module';
import { PortalRoutingModule } from '~app/components/authenticated/portal-routing.module';
import { UiGroupsModule } from '~app/components/shared/ui-groups/ui-groups.module';
import { DeleteComponent } from '~app/components/authenticated/delete/delete.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CoreModule,
        NgbModule,
        ControlsModule,
        PortalRoutingModule,
        UiGroupsModule
    ],
    declarations: [HomeComponent, DeleteComponent],
    providers: []
})
export class AuthenticatedModule {}
