import { TOGGLE_IS_LOADING } from './../store/actions/app-data.actions';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { environment } from '~environments/environment';
import { App_Id, AuthTokenName } from '~constants/app-config';
import { JsonToJWEwPEM, JWEToJsonwPEM } from '~core/encryption';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const encryptionBody = {
            pem: environment.mapiPublicKey,
            payload: req.body
        };
        // Get the auth token from session storage if its there.
        let authToken: string = window.sessionStorage.getItem(AuthTokenName);

        // Set the authToken if it is already present and do not overwrite it.
        if (req.headers.get('authorization')) {
            authToken = req.headers.get('authorization');
        }

        // Skip encoding to JWE when communicating with Hapi.
        if (!req.url.startsWith(environment.marlinApiUrl)) {
            return next.handle(req);
        }

        return fromPromise(JsonToJWEwPEM(encryptionBody)).switchMap(token => {
            // Set authorization, content type and app id to all outgoing headers.
            const authReq = req.clone({
                headers: req.headers
                    .set('Content-Type', 'application/json; charset=utf-8')
                    .set('x-hs-appid', App_Id)
                    .set('authorization', authToken ? authToken : ''),
                body: { data: token }
            });
            return next.handle(authReq);
        });
    }
}
