﻿import { CookieService } from 'ngx-cookie';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import { CoreModule } from '~core/core.module';
import { ForgotPasswordComponent } from '~public/forgot-password/forgot-password.component';
import { LoginComponent } from '~public/login/login.component';
import { RegisterComponent } from '~public/register/register.component';
import { ResetPasswordComponent } from '~public/reset-password/reset-password.component';
import { SupportComponent } from '~public/support/support.component';
import { UiGroupsModule } from '~app/components/shared/ui-groups/ui-groups.module';
import { ControlsModule } from '~shared/controls/controls.module';
import { GlobalLoginComponent } from '~public/global-login/global-login.component';
import { AcceptPolicyComponent } from '~public/accept-policy/accept-policy.component';
import { YoungComponent } from '~public/young/young.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        CoreModule,
        UiGroupsModule,
        NgbModule,
        RecaptchaModule.forRoot(),
        RecaptchaFormsModule,
        ControlsModule
    ],
    declarations: [
        LoginComponent,
        RegisterComponent,
        ResetPasswordComponent,
        SupportComponent,
        ForgotPasswordComponent,
        GlobalLoginComponent,
        AcceptPolicyComponent,
        YoungComponent
    ],
    providers: [CookieService]
})
export class PublicModule {}
