<div class="form-group">
    <label for="control" [ngClass]="{'marlin-required': this.control.errors?.required}" [innerHTML]="title | translate"></label>
    <button type="button" container="body" *ngIf="toolTip != ''" class="tooltip-element" ngbTooltip="{{toolTip}}" triggers="click:blur">
        <i class="fa fa-question-circle"></i>
    </button>
    <kendo-dropdownlist class="form-control w-100" #input [defaultItem]="{text: 'gblPleaseSelect' | translate, value: null}"
        [ngClass]="{'ng-invalid': control.invalid, 'ng-touched': control.touched}" [data]='data' [ignoreCase]='true' [value]="selectedValue"
        [valuePrimitive]='true' (valueChange)="onChange($event)" (blur)="onTouched()" [valueField]='"value"' [textField]='"text"'>
        <ng-template kendoDropDownListItemTemplate let-dataItem>
            <div [innerHTML]="dataItem.text"></div>
        </ng-template>
    </kendo-dropdownlist>
    <div class="alert alert-danger" *ngIf="control.invalid && control.touched" [innerHTML]='errorMessage | translate'>
    </div>
</div>