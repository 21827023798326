import { Store } from '@ngrx/store';
import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    EventEmitter,
    Output,
    Optional,
    Host,
    SkipSelf,
    forwardRef
} from '@angular/core';
import { Observable } from 'rxjs/Observable';
import {
    FormBuilder,
    Validators,
    FormGroup,
    ControlValueAccessor,
    ControlContainer,
    NG_VALUE_ACCESSOR
} from '@angular/forms';

import { Option, MetaData } from '~models/meta-data';
import { AppState } from '~store/app-state';
import { markAllUntouched } from '~app/logical-objects/helpers/form';
import { MetadataTypes } from '~app/constants/church';

@Component({
    selector: 'marlin-consents',
    templateUrl: './consents.component.html',
    styleUrls: ['./consents.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConsentsComponent),
            multi: true
        }
    ]
})
export class ConsentsComponent implements OnInit, ControlValueAccessor {
    consentsForm = this.fb.group({
        consentType1: [false],
        consentType2: [false],
        consentType3: [false],
        consentType4: [false],
        consentType5: [false],
        consentType6: [false]
    });
    consentTypes = {
        CONSENT_LEGAL_TEXT: 6,
        EMAIL: 1,
        PHONE: 2,
        SMS: 3,
        MAIL: 4,
        SOCIAL: 5,
        SOCIAL_HELP: 9
    };
    consents = null;
    onChange = (form: FormGroup) => {};
    onTouched = () => {};
    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder,
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer
    ) {}

    ngOnInit() {
        this.store.select(state => state.metadata[MetadataTypes.Consents]).subscribe(consentData => {
            if (consentData) {
                this.consents = consentData;
            }
        });
    }

    writeValue(value: any): void {
        if (value) {
            this.consentsForm.setValue({
                consentType1: value.consentType1,
                consentType2: value.consentType2,
                consentType3: value.consentType3,
                consentType4: value.consentType4,
                consentType5: value.consentType5,
                consentType6: value.consentType6
            });
        } else {
            this.consentsForm.setValue({
                consentType1: false,
                consentType2: false,
                consentType3: false,
                consentType4: false,
                consentType5: false,
                consentType6: false
            });
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    getWordingForConsentType(consentTypeID: number): string {
        return this.consents.filter(consent => consent.consentTypeID == consentTypeID)[0].text;
    }
}
