<div *ngIf="progressSteps.currentStep === steps.validateVerificationToken" >
    <div class="form-title">
    </div>
</div>

<div *ngIf="progressSteps.currentStep === steps.passwordMatch" [formGroup]="passwordMatchForm">
    <div class="form-title">
        <h2 [innerHTML]='message | translate'></h2>
        <p *ngIf='passwordResetType == passwordResetTypes.updatePassword' [innerHTML]='"lclChangePassword" | translate'>
        </p>
    </div>
    <marlin-input-password *ngIf='passwordResetType == passwordResetTypes.updatePassword' [label]="'lclOldPassword'" [errorMessage]="'gblPasswordRequiredErrorMsg'"
        formControlName="oldPassword"></marlin-input-password>
    <marlin-password (onPasswordChange)="passwordMatchForm.controls.password.setValue($event.controls.password.value); passwordMatchForm.controls.confirmPassword.setValue($event.controls.confirmPassword.value); passwordMatchForm.setErrors($event.valid ? null : {error: true})"></marlin-password>
    <div class="form-group form-title">
        <button class="btn btn-primary w-100" (click)="submitPasswordChange()">{{"gblGo" | translate}}</button>
    </div>
    <div class="form-group text-center form-navigation">
        <marlin-home-button></marlin-home-button>
    </div>
</div>

<div class="form-group" *ngIf="progressSteps.currentStep === steps.passwordUpdateSucccess">
    <div class="form-title">
        <h2 [innerHTML]='"gblPasswordUpdated" | translate'></h2>
        <button *ngIf="passwordResetType != passwordResetTypes.updatePassword" class="btn btn-primary w-100" [routerLink]="['/portal/home']">{{"lclClickHereToLogIn" | translate}}</button>
        <button *ngIf="passwordResetType == passwordResetTypes.updatePassword" class="btn btn-primary w-100" [routerLink]="['/portal/home']">{{"gblHomePage" | translate}}</button>
    </div>
</div>

<div *ngIf="progressSteps.currentStep === steps.validateVerificationFailedNoResendOption">
    <div class="form-title">
        <h2 [innerHTML]='"gblUnableToCompleteYourRequest" | translate'></h2>
    </div>

    <div class="form-group">
       <label [innerHTML]='"gblVerificationLinkExpired" | translate'></label>
    </div>

    <div class="row marginspecified">
        <div class="col text-left">
            <a [routerLink]="['/support']" [queryParams]="{type:'PasswordReset'}" [innerHTML]="'gblNeedHelp' | translate"></a>
        </div>
        <div class="col text-center">
            <a [routerLink]="['/portal/home']" [innerHTML]="'gblLogin' | translate"></a>
        </div>
    </div>
</div>

<div *ngIf="progressSteps.currentStep === steps.validateVerificationFailed">
    <div class="form-title">
        <h2 [innerHTML]='"gblUnableToCompleteYourRequest" | translate'></h2>
    </div>

    <div class="form-group">
        <label [innerHTML]='"gblVerificationTokenExpired" | translate'></label>
    </div>

    <div class="form-group">
        <button class="btn btn-outline-primary w-100" (click)="resendVerificationEmail();" [innerHTML]="'gblGenerateToken' | translate"></button>
    </div>

    <div class="row">
        <div class="col text-center">
            <a [routerLink]="['/support']" [queryParams]="{type:'PasswordReset'}" [innerHTML]="'gblNeedHelp' | translate"></a>
        </div>
        <div class="col text-center">
            <a [routerLink]="['/portal/home']" [innerHTML]="'gblLogin' | translate"></a>
        </div>
    </div>
</div>

<div *ngIf="progressSteps.currentStep === steps.resendVerificationEmail" class="form-group">
    <div class="form-title">
        <h4 [innerHTML]='"gblFurtherInstructionsSent" | translate'></h4>
    </div>
    <div class="form-group text-center form-navigation">
        <marlin-home-button></marlin-home-button>
    </div>
</div>

<div *ngIf="progressSteps.currentStep === steps.serverError">
    <ng-container *ngIf="passwordResetType == passwordResetTypes.updatePassword; then passwordUpdate; else passwordReset"></ng-container>

    <ng-template #passwordUpdate>
        <div class="form-group">
            <div class="form-title">
                <h2 [innerHTML]='"pmtResetPassword" | translate'></h2>
            </div>
            <div>
                <button class="btn btn-primary col" (click)="resetPage();" [innerHTML]="'gblTryAgain' | translate"></button>
            </div>
            <div class="row">
                <div class="col text-center">
                    <a [routerLink]="['/support']" [queryParams]="{type:'PasswordReset'}">{{'gblNeedHelp' | translate}}</a>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #passwordReset>
        <div class="form-title">
            <h2 [innerHTML]='"gblUnableToCompleteYourRequest" | translate'></h2>
        </div>

        <div *ngIf="passwordResetType === passwordResetTypes.completeRegistration">
            <div class="form-group">
                <a class="btn btn-primary col" target="_blank" [routerLink]="['/support']" [queryParams]="{type:'AccountRegistration'}" [innerHTML]="'gblContactOurTeam' | translate"></a>
            </div>
        </div>

        <div *ngIf="passwordResetType === passwordResetTypes.resetPassword">
            <div class="form-group">
                <button class="btn btn-primary col" [routerLink]="['/forgot-password']" [innerHTML]="'gblTryAgain' | translate"></button>
            </div>
            <div class="row">
                <div class="col text-center">
                    <a [routerLink]="['/support']" [queryParams]="{type:'PasswordReset'}" [innerHTML]="'gblNeedHelp' | translate"></a>
                </div>
                <div class="col text-center">
                    <a [routerLink]="['/portal/home']" [innerHTML]="'gblLogin' | translate"></a>
                </div>
            </div>
        </div>
    </ng-template>
</div>