<div [formGroup]="churchLocationServiceForm">
    <div class="form-group">
        <label [ngClass]="{'marlin-required': churchLocationServiceForm.controls.church.errors?.required}" [innerHTML]="'gblCountryRegion' | translate"></label>
        <kendo-dropdownlist class="form-control w-100" [defaultItem]="{text: 'gblPleaseSelect' | translate, value: null}" [valuePrimitive]='true'
            [data]='churches' [ignoreCase]='true' [valueField]='"value"' [textField]='"text"' formControlName="church" (valueChange)='onChange(churchLocationServiceForm); dropdownChangeValue($event, "Church")'>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div [innerHTML]="dataItem.text"></div>
            </ng-template>
        </kendo-dropdownlist>
        <div class="alert alert-danger" *ngIf="churchLocationServiceForm.controls.church.invalid && churchLocationServiceForm.controls.church.touched"
            [innerHTML]='"gblCountryRegionErrorMsg" | translate'>
        </div>
    </div>
    <!-- Value is set for location because when selecting church THEN selecting location, then changing the church and then back to the previously selected church
    there seems to be a bug in telerik where it will still retain the previously selected location.  To replicate this bug do exactly the following (after removing the value setter):
    * Select Australia
    * Select Hills
    * Select Argentina
    * Select Australia again...
    -->
    <div class="form-group">
        <label [ngClass]="{'marlin-required': churchLocationServiceForm.controls.location.errors?.required}" [innerHTML]="'gblLocation' | translate"></label>
        <kendo-dropdownlist class="form-control w-100" [defaultItem]="{text: 'gblPleaseSelect' | translate, value: null}" [valuePrimitive]='true'
            [value]="churchLocationServiceForm.controls.location.value" [data]='locations' [ignoreCase]='true' [valueField]='"value"'
            [textField]='"text"' formControlName="location" (valueChange)='onChange(churchLocationServiceForm); dropdownChangeValue($event, "Location")'>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div [innerHTML]="dataItem.text"></div>
            </ng-template>
        </kendo-dropdownlist>
        <div class="alert alert-danger" *ngIf="churchLocationServiceForm.controls.location.invalid && churchLocationServiceForm.controls.location.touched"
            [innerHTML]='"gblLocationErrorMsg" | translate'>
        </div>
    </div>
    <div class="form-group">
        <label [ngClass]="{'marlin-required': churchLocationServiceForm.controls.service.errors?.required}" [innerHTML]="'gblService' | translate"></label>
        <kendo-dropdownlist class="form-control w-100" [defaultItem]="{text: 'gblPleaseSelect' | translate, value: null}" [valuePrimitive]='true'
            [value]="churchLocationServiceForm.controls.service.value" [data]='services' [ignoreCase]='true' [valueField]='"value"'
            [textField]='"text"' formControlName="service" (valueChange)='onChange(churchLocationServiceForm)'>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div [innerHTML]="dataItem.text"></div>
            </ng-template>
        </kendo-dropdownlist>
        <div class="alert alert-danger" *ngIf="churchLocationServiceForm.controls.service.invalid && churchLocationServiceForm.controls.service.touched"
            [innerHTML]='"gblServiceErrorMsg" | translate'>
        </div>
    </div>
</div>