import { AppState } from '~app/store/app-state';
import { Store } from '@ngrx/store';
import * as appDataActions from '~store/actions/app-data.actions';

export class ProgressSteps {
    currentStep = 1;
    progressValue = 0;
    numberOfSteps: number;
    progressStepSize: number;

    get isFirstStep(): boolean {
        return this.currentStep === 1;
    }
    get isFinalStep(): boolean {
        return this.currentStep === this.numberOfSteps;
    }

    constructor(numberOfSteps: number, private store: Store<AppState>) {
        this.numberOfSteps = numberOfSteps;
        this.progressStepSize = 100 / numberOfSteps;
        this.updateProgressValue();
    }

    goToStep(step: number) {
        this.currentStep = step;
        this.updateProgressValue();
    }

    nextStep() {
        if (this.currentStep < this.numberOfSteps) {
            this.currentStep++;
            this.updateProgressValue();
        }
    }

    previousStep() {
        if (this.currentStep > 1) {
            this.currentStep--;
            this.updateProgressValue();
        }
    }

    private updateProgressValue() {
        this.progressValue = this.progressStepSize * this.currentStep;
        this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
    }
}
