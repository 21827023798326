import { Component, OnInit, ViewChild, Input, Optional, Host, SkipSelf, forwardRef } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    ControlContainer,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import * as zxcvbn from 'zxcvbn';

@Component({
    selector: 'marlin-input-password',
    templateUrl: './input-password.component.html',
    styleUrls: ['./input-password.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputPasswordComponent),
            multi: true
        }
    ]
})
export class InputPasswordComponent implements ControlValueAccessor, OnInit {
    @ViewChild('input') input;

    @Input() label = '';
    control: AbstractControl = new FormControl();
    @Input() errorMessage = '';
    @Input() formControlName: string;
    @Input() placeholder = '';
    @Input() type = '';
    @Input() showToolTip = false;

    onChange;
    onTouched;

    passwordInputType = 'password';
    passwordInputTypes = {
        hidePassword: { value: 'eye-slash', type: 'password' },
        showPassword: { value: 'eye', type: 'text' }
    };

    progressColours = {
        0: 'danger',
        25: 'danger',
        50: 'warning',
        75: 'success',
        100: 'success'
    };

    strength = 0;

    progressColour = this.progressColours[0];

    showPasswordRequirments = false;

    // zxcvbn has 4 levels of strength so we need a multiplier for the progress input.
    strengthMultiplier = 25;
    constructor(
        config: NgbTooltipConfig,
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer
    ) {}

    ngOnInit() {
        this.control = this.controlContainer.control.get(this.formControlName);
    }

    writeValue(value: any): void {
        const input = this.input.nativeElement;
        input.value = value;
    }
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    // Setting the svg icon manually because using ng class doesn't work.
    changeInputType(e, remaskPassword = false) {
        if (remaskPassword) {
            if (this.passwordInputType == this.passwordInputTypes.showPassword.type) {
                this.passwordInputType = this.passwordInputTypes.hidePassword.type;
                const parent = e.currentTarget.parentNode;
                if (parent.getAttribute('class') == "input-group-append") {
                    // Lost focus from show/hide button.
                    parent.childNodes[0].childNodes[0].setAttribute(
                        'data-icon',
                        this.passwordInputTypes.hidePassword.value
                    );
                } else {
                    // Lost focus from password field itself.
                    parent.childNodes[1].childNodes[0].childNodes[0].setAttribute(
                        'data-icon',
                        this.passwordInputTypes.hidePassword.value
                    );
                }
            }
        } else if (this.passwordInputType == this.passwordInputTypes.showPassword.type) {
            this.passwordInputType = this.passwordInputTypes.hidePassword.type;
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.hidePassword.value);
        } else {
            this.passwordInputType = this.passwordInputTypes.showPassword.type;
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.showPassword.value);
        }
    }

    getStrength(password) {
        const estimation = zxcvbn(password || '');
        const score = estimation.score * this.strengthMultiplier;
        this.strength = score;
        this.progressColour = this.progressColours[score];
    }
}
