import { FormControl, AbstractControl, ControlContainer, ControlValueAccessor } from '@angular/forms';
import { OnChanges, OnInit } from '@angular/core';
import { verifyValidPhoneKey, verifyValidHomePhoneKey } from '~app/logical-objects/helpers/form';
import { InputVerficationKeyTypes } from '~constants/form';
var InputGroupComponent = /** @class */ (function () {
    function InputGroupComponent(controlContainer) {
        this.controlContainer = controlContainer;
        this.control = new FormControl();
        this.errorMessage = '';
        this.iconClass = '';
        this.label = '';
        this.placeholder = '';
        this.type = '';
        this.setError = false;
    }
    InputGroupComponent.prototype.ngOnChanges = function (target) { };
    InputGroupComponent.prototype.ngOnInit = function () {
        this.control = this.controlContainer.control.get(this.formControlName);
    };
    InputGroupComponent.prototype.writeValue = function (value) {
        var input = this.input.nativeElement;
        input.value = value;
    };
    InputGroupComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    InputGroupComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    InputGroupComponent.prototype.onKeyPress = function (e) {
        switch (this.type) {
            case InputVerficationKeyTypes.tel:
                return verifyValidPhoneKey(e);
            case InputVerficationKeyTypes.homePhone:
                return verifyValidHomePhoneKey(e);
            default:
                break;
        }
    };
    return InputGroupComponent;
}());
export { InputGroupComponent };
