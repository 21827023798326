import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { userReducer } from '~store/reducers/user.reducers';
import { metadataReducer, appStatusReducer } from '~store/reducers/app-data.reducers';

import { AuthUserEffects } from '~store/effects/user.effects';
import { AppDataEffects } from '~store/effects/app-data.effects';

import { UserService } from '~services/auth/user.service';
import { MetadataService } from '~services/common/metadata.service';

import { AppState } from './app-state';

@NgModule({
    imports: [
        StoreModule.forRoot({
            user: userReducer,
            metadata: metadataReducer,
            appStatus: appStatusReducer
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        EffectsModule.forRoot([AuthUserEffects, AppDataEffects])
    ],
    exports: [StoreModule],
    providers: [UserService, MetadataService]
})
export class MarlinStoreModule {}
