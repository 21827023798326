﻿export const MarlinApiUrl = {
    local: 'https://sandbox-api.myhillsong.com',
    staging: 'https://staging-api.myhillsong.com',
    sandbox: 'https://sandbox-api.myhillsong.com',
    postBuildVerification: 'https://api.myhillsong.com',
    live: 'https://api.myhillsong.com'
};

export const MyHillsongUrl = {
    local: 'http://localhost:2000',
    staging: 'https://staging.myhillsong.com',
    sandbox: 'https://sandbox.myhillsong.com',
    live: 'https://secure.myhillsong.com',
    postBuildVerification: 'https://ec2-34-209-185-105.us-west-2.compute.amazonaws.com',
    connectionZone: '/People/ConnectionZone.aspx'
};

export const HapiUrl = {
    local: 'https://sandbox-api.hillsong.com/api',
    staging: 'https://staging-api.hillsong.com/api',
    sandbox: 'https://sandbox-api.hillsong.com/api',
    postBuildVerification: 'https://api.hillsong.com/api',
    live: 'https://api.hillsong.com/api'
};
