import { Store } from '@ngrx/store';
import { OnInit, ApplicationRef } from '@angular/core';
import * as raygunUtils from '~core/raygun-utils';
import * as rg4js from 'raygun4js';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import * as _ from 'lodash';
import { environment } from '~environments/environment';
import { MetadataTypes } from '~app/constants/church';
import { AuthTokenName, Default_Language } from '~constants/app-config';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userAction from '~store/actions/user.actions';
import { UserService } from '~app/services/auth/user.service';
import { UserMetaData } from '~app/constants/bit-data';
import { getTranslatedErrorMessageFromResponse } from '~app/logical-objects/helpers/system';
var AppComponent = /** @class */ (function () {
    function AppComponent(store, router, userService, cookieService, activatedRoute, appRef) {
        this.store = store;
        this.router = router;
        this.userService = userService;
        this.cookieService = cookieService;
        this.activatedRoute = activatedRoute;
        this.appRef = appRef;
        this.authToken = window.sessionStorage.getItem(AuthTokenName);
        this.cookieLanguageName = 'marlin-language';
        this.policiesUrl = environment.myhillsongUrl + "/Policies.aspx";
        this.title = 'Marlin';
        this.isGlobalUser = false;
        this.currentYear = (new Date().getFullYear());
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.dispatch(new appDataActions.InitiliseAppState());
        raygunUtils.raygunNavigationTracking(this.router);
        this.authToken = window.sessionStorage.getItem(AuthTokenName);
        var language = this.cookieService.get(this.cookieLanguageName);
        if (language) {
            this.changeLanguage(language);
        }
        // Checks and populates the user if logged in.
        if (!this.userData && this.authToken) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.store.dispatch(new userAction.GetUser(this.authToken.toString()));
        }
        else if (!this.userData && !this.authToken) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        }
        // Load translation language options.
        this.store.dispatch(new appDataActions.LoadMetadata(MetadataTypes.Language));
        this.store.select(function (state) { return state.appStatus; }).subscribe(function (data) {
            _this.errorMessage = data.errorMessage ? data.errorMessage : '';
            _this.appStatus = data;
        });
        this.store.select(function (state) { return state.metadata; }).subscribe(function (data) {
            var languages = data[MetadataTypes.Language];
            var translations = data[MetadataTypes.Translations];
            var supportedLanguages;
            if (!_this.language && languages) {
                var browserLanguages = void 0;
                if (navigator.languages) {
                    browserLanguages = navigator.languages;
                    // Some browsers do not support navigator.languages.
                    // In this case, we just check the preferred language.
                }
                else if (navigator.language) {
                    browserLanguages = [navigator.language];
                }
                // First browser language that exists in MyHillsong language options.
                if (browserLanguages) {
                    supportedLanguages = browserLanguages.filter(function (bl) {
                        return languages.options.some(function (l) {
                            return l.value.toLowerCase() === bl.toLowerCase();
                        });
                    });
                }
                if (supportedLanguages && supportedLanguages.length > 0) {
                    _this.changeLanguage(supportedLanguages[0].toLowerCase());
                }
                else {
                    _this.changeLanguage(Default_Language);
                }
            }
            // Translations are loaded for the first time.
            if (languages && translations && _this.language === translations.culture) {
                _this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                _this.store.dispatch(new appDataActions.ToggleTranslationsIsLoaded(true));
                _this.languages = languages.options;
            }
            else if (languages && translations) {
                _this.store.dispatch(new appDataActions.ToggleTranslationsIsLoaded(true));
                _this.languages = languages.options;
            }
            else {
                _this.store.dispatch(new appDataActions.ToggleTranslationsIsLoaded(false));
            }
        });
        this.store.select(function (state) { return state.user; }).subscribe(function (data) {
            _this.userData = data;
            _this.authToken = window.sessionStorage.getItem(AuthTokenName);
            _this.isGlobalUser = !!(_this.userData.metadata & UserMetaData.isGlobalUser);
            if (_this.authToken && _this.isGlobalUser) {
                // "Global" users who are not a church member, cannot log in but we don't clear their auth cookie.
                _this.store.dispatch(new appDataActions.ToggleIsLoggedIn(false));
                _this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                _this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                _this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                _this.router.navigate(['/login']);
            }
            else if (_this.authToken && _.isEmpty(_this.userData)) {
                // If there is an authToken, toggle user loaded as false as we need to load the user.
                _this.store.dispatch(new appDataActions.ToggleUserIsLoaded(false));
            }
            else if (data['status'] == 'failed') {
                // The app data status is set as failed if it cannot get the user.
                // We can the take off the loading screen.
                var errorMessage = getTranslatedErrorMessageFromResponse(data['code']);
                _this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
                _this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                _this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                _this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                window.sessionStorage.removeItem(AuthTokenName);
            }
            else if (_this.authToken && !_.isEmpty(_this.userData)) {
                // Check that there is an authToken and that userdata (i.e. firstname) loads successfully.
                // Only toggle loading off if it is not the login page.
                // Otherwise leave loading on as we log the user in.
                if (_this.router.url.startsWith('/login')) {
                    _this.activatedRoute.queryParams.take(1).subscribe(function (params) {
                        var redirect = params['redirect'];
                        // The redirect parameter is passed when redirected from Login.aspx.  If present,
                        // redirect the person to the login page even if they have a valid authToken.
                        // This indicates a forms authentication timeout happened in MyHillsong.
                        if (redirect) {
                            _this.router.navigate([], {
                                relativeTo: _this.activatedRoute,
                                queryParams: {
                                    redirect: null
                                },
                                queryParamsHandling: 'merge'
                            });
                        }
                        else {
                            // Redirect the user from login to the home page if they are already logged in.
                            _this.router.navigate(['/portal/home'], { queryParamsHandling: 'preserve' });
                        }
                        // We don't know when the router will finish changing routes
                        // so we need to subscribe before turning off loading.
                        var routerSubscription = _this.router.events.subscribe(function (event) {
                            if (event instanceof NavigationEnd) {
                                // Change the status to user is loaded at navigation end to remove the loading screen.
                                _this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                                // Unsubscribe after loading otherwise it will be called everytime the route changes.
                                routerSubscription.unsubscribe();
                            }
                        });
                    });
                }
                else {
                    _this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                    _this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                }
                // If there is an auth token and we have user data then this user's status is logged in.
                _this.store.dispatch(new appDataActions.ToggleIsLoggedIn(true));
            }
            else {
                // If the user has never been to the site, then set user loaded as true.
                _this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
            }
            if (_this.userData.personId) {
                rg4js('setUser', {
                    identifier: _this.userData.personId,
                    isAnonymous: false,
                    email: _this.userData.loginEmail
                });
            }
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
            }
        });
    };
    AppComponent.prototype.changeLanguage = function (languageCode) {
        this.language = languageCode;
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.store.dispatch(new appDataActions.LoadTranslations(languageCode));
        this.store.dispatch(new appDataActions.LoadConsents(languageCode));
        this.cookieService.put(this.cookieLanguageName, languageCode);
    };
    AppComponent.prototype.logout = function () {
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.userService.logoutUser().subscribe(function (data) {
            window.sessionStorage.removeItem(AuthTokenName);
            window.location.href = environment.myhillsongUrl + "/Logout.aspx";
        });
    };
    AppComponent.prototype.showHelpIcon = function () {
        return !this.router.url.startsWith('/support') && !this.isGlobalUser;
    };
    return AppComponent;
}());
export { AppComponent };
