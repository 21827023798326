import { transition } from '@angular/animations';
import { Store } from '@ngrx/store';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

import { AppState } from '~store/app-state';
import { Translations } from '~models/common';
import { MetadataTypes } from '~app/constants/church';

@Pipe({ name: 'translate', pure: false })
export class TranslatePipe implements PipeTransform {
    translationData: Translations;
    constructor(private store: Store<AppState>) {}
    transform(key: string) {
        if (key) {
            let translation;
            this.store
                .select(s => s.metadata[MetadataTypes.Translations])
                .take(1)
                .subscribe(data => {
                    if (!_.isEmpty(data)) {
                        this.translationData = data;
                    }
                });
            // Since this.translationData is inside a subscribe loop we don't know if there is data.
            // We need to check if there is data before try to search for a translation.
            if (!_.isEmpty(this.translationData)) {
                translation = this.translationData.translationStrings.find(obj => {
                    return obj.key === key;
                });
            }
            if (translation) {
                return translation.value;
            }
        }
        return key;
    }
}
