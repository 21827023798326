﻿import { Store } from '@ngrx/store';
import { Component, OnInit, ApplicationRef } from '@angular/core';
import * as raygunUtils from '~core/raygun-utils';
import * as rg4js from 'raygun4js';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import * as _ from 'lodash';

import { environment } from '~environments/environment';
import { AppState } from '~store/app-state';

import { MetadataTypes } from '~app/constants/church';
import { AuthTokenName, Default_Language } from '~constants/app-config';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userAction from '~store/actions/user.actions';
import { User } from '~models/user';
import { UserService } from '~app/services/auth/user.service';
import { UserMetaData } from '~app/constants/bit-data';
import { getTranslatedErrorMessageFromResponse } from '~app/logical-objects/helpers/system';
import { Translations } from '~app/models/common';

@Component({
    selector: 'marlin-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    authToken = window.sessionStorage.getItem(AuthTokenName);
    cookieLanguageName = 'marlin-language';
    errorMessage: string;
    heading: string;
    appStatus;
    language: string;
    languages;
    policiesUrl = `${environment.myhillsongUrl}/Policies.aspx`;
    title = 'Marlin';
    userData: User;
    isGlobalUser = false;
    currentYear = (new Date().getFullYear());

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private userService: UserService,
        private cookieService: CookieService,
        private activatedRoute: ActivatedRoute,
        private appRef: ApplicationRef
    ) {}

    ngOnInit() {
        this.store.dispatch(new appDataActions.InitiliseAppState());
        raygunUtils.raygunNavigationTracking(this.router);

        this.authToken = window.sessionStorage.getItem(AuthTokenName);

        const language = this.cookieService.get(this.cookieLanguageName);
        if (language) {
            this.changeLanguage(language);
        }

        // Checks and populates the user if logged in.
        if (!this.userData && this.authToken) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.store.dispatch(new userAction.GetUser(this.authToken.toString()));
        } else if (!this.userData && !this.authToken) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        }

        // Load translation language options.
        this.store.dispatch(new appDataActions.LoadMetadata(MetadataTypes.Language));
        this.store.select(state => state.appStatus).subscribe(data => {
            this.errorMessage = data.errorMessage ? data.errorMessage : '';
            this.appStatus = data;
        });

        this.store.select(state => state.metadata).subscribe(data => {
            const languages = data[MetadataTypes.Language];
            const translations: Translations = data[MetadataTypes.Translations];

            let supportedLanguages;
            if (!this.language && languages) {
                let browserLanguages;
                if (navigator.languages) {
                    browserLanguages = navigator.languages;
                // Some browsers do not support navigator.languages.
                // In this case, we just check the preferred language.
                } else if (navigator.language) {
                    browserLanguages = [navigator.language];
                }
                // First browser language that exists in MyHillsong language options.
                if (browserLanguages) {
                    supportedLanguages = browserLanguages.filter(function (bl) {
                        return languages.options.some(function (l) {
                            return l.value.toLowerCase() === bl.toLowerCase();
                        });
                    });
                }

                if (supportedLanguages && supportedLanguages.length > 0) {
                    this.changeLanguage(supportedLanguages[0].toLowerCase());
                } else {
                    this.changeLanguage(Default_Language);
                }
            }

            // Translations are loaded for the first time.
            if (languages && translations && this.language === translations.culture) {
                this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                this.store.dispatch(new appDataActions.ToggleTranslationsIsLoaded(true));
                this.languages = languages.options;
            } else if (languages && translations) {
                this.store.dispatch(new appDataActions.ToggleTranslationsIsLoaded(true));
                this.languages = languages.options;
            } else {
                this.store.dispatch(new appDataActions.ToggleTranslationsIsLoaded(false));
            }
        });

        this.store.select(state => state.user).subscribe(data => {
            this.userData = data;
            this.authToken = window.sessionStorage.getItem(AuthTokenName);
            this.isGlobalUser = !!(this.userData.metadata & UserMetaData.isGlobalUser);
            if (this.authToken && this.isGlobalUser) {
                // "Global" users who are not a church member, cannot log in but we don't clear their auth cookie.
                this.store.dispatch(new appDataActions.ToggleIsLoggedIn(false));
                this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                this.router.navigate(['/login']);
            } else if (this.authToken && _.isEmpty(this.userData)) {
                // If there is an authToken, toggle user loaded as false as we need to load the user.
                this.store.dispatch(new appDataActions.ToggleUserIsLoaded(false));
            } else if (data['status'] == 'failed') {
                // The app data status is set as failed if it cannot get the user.
                // We can the take off the loading screen.
                const errorMessage = getTranslatedErrorMessageFromResponse(data['code']);
                this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
                this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                window.sessionStorage.removeItem(AuthTokenName);
            } else if (this.authToken && !_.isEmpty(this.userData)) {
                // Check that there is an authToken and that userdata (i.e. firstname) loads successfully.
                // Only toggle loading off if it is not the login page.
                // Otherwise leave loading on as we log the user in.
                if (this.router.url.startsWith('/login')) {
                    this.activatedRoute.queryParams.take(1).subscribe(params => {
                        const redirect = params['redirect'];
                        // The redirect parameter is passed when redirected from Login.aspx.  If present,
                        // redirect the person to the login page even if they have a valid authToken.
                        // This indicates a forms authentication timeout happened in MyHillsong.
                        if (redirect) {
                            this.router.navigate([], {
                                relativeTo: this.activatedRoute,
                                queryParams: {
                                    redirect: null
                                },
                                queryParamsHandling: 'merge'
                            });
                        } else {
                            // Redirect the user from login to the home page if they are already logged in.
                            this.router.navigate(['/portal/home'], { queryParamsHandling: 'preserve' });
                        }

                        // We don't know when the router will finish changing routes
                        // so we need to subscribe before turning off loading.
                        const routerSubscription = this.router.events.subscribe(event => {
                            if (event instanceof NavigationEnd) {
                                // Change the status to user is loaded at navigation end to remove the loading screen.
                                this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                                // Unsubscribe after loading otherwise it will be called everytime the route changes.
                                routerSubscription.unsubscribe();
                            }
                        });
                    });

                } else {
                    this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                    this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
                }
                // If there is an auth token and we have user data then this user's status is logged in.
                this.store.dispatch(new appDataActions.ToggleIsLoggedIn(true));
            } else {
                // If the user has never been to the site, then set user loaded as true.
                this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
            }

            if (this.userData.personId) {
                rg4js('setUser', {
                    identifier: this.userData.personId,
                    isAnonymous: false,
                    email: this.userData.loginEmail
                });
            }
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
            }
        });
    }

    changeLanguage(languageCode: string) {
        this.language = languageCode;
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.store.dispatch(new appDataActions.LoadTranslations(languageCode));
        this.store.dispatch(new appDataActions.LoadConsents(languageCode));
        this.cookieService.put(this.cookieLanguageName, languageCode);
    }

    logout() {
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.userService.logoutUser().subscribe(data => {
            window.sessionStorage.removeItem(AuthTokenName);
            window.location.href = `${environment.myhillsongUrl}/Logout.aspx`;
        });
    }

    showHelpIcon() {
        return !this.router.url.startsWith('/support') && !this.isGlobalUser;
    }
}
