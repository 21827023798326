import { Consents } from '~models/common';
import { ActionReducer, Action } from '@ngrx/store';

import * as AppActions from '~actions/app-data.actions';
import { MetadataTypes } from '~app/constants/church';

export function metadataReducer(state = [], action: AppActions.All) {
    let payloadData = [];
    switch (action.type) {
        case AppActions.LOAD_METADATA:
            return Object.assign({}, state);
        case AppActions.LOAD_METADATA_SUCCESS:
            payloadData[action.metaType] = action.payload[0];
            return Object.assign({}, state, payloadData);
        case AppActions.LOAD_METADATA_FAILURE:
            return Object.assign({}, state, action.payload);

        case AppActions.LOAD_TRANSLATIONS:
            return state;
        case AppActions.LOAD_TRANSLATIONS_SUCCESS:
            payloadData = [];
            payloadData[MetadataTypes.Translations] = action.payload;
            return Object.assign({}, state, payloadData);
        case AppActions.LOAD_TRANSLATIONS_FAILURE:
            return Object.assign({}, state, action.payload);

        case AppActions.LOAD_CONSENTS:
            return Object.assign({}, state);
        case AppActions.LOAD_CONSENTS_SUCCESS:
            payloadData = [];
            payloadData[MetadataTypes.Consents] = action.payload;
            return Object.assign({}, state, payloadData);
        case AppActions.LOAD_CONSENTS_FAILURE:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}

export function appStatusReducer(state = [], action: AppActions.All) {
    switch (action.type) {
        case AppActions.TOGGLE_IS_LOADING:
            return Object.assign({}, state, { isLoading: action.payload });
        case AppActions.TOGGLE_IS_LOGGED_IN:
            return Object.assign({}, state, { isLoggedIn: action.payload });
        case AppActions.SET_APP_ERROR_MESSAGE:
            return Object.assign({}, state, { errorMessage: action.payload });
        case AppActions.TOGGLE_USER_IS_LOADED:
            return Object.assign({}, state, { isUserLoaded: action.payload });
        case AppActions.TOGGLE_TRANSLATIONS_IS_LOADED:
            return Object.assign({}, state, { isTranslationsLoaded: action.payload });
        case AppActions.TOGGLE_IS_REDIRECTING:
            return Object.assign({}, state, { isRedirecting: action.payload });
        case AppActions.INITIALISE_APP_STATE:
            return Object.assign({}, state, {
                isLoading: true,
                isLoggedIn: false,
                isUserLoaded: false,
                isTranslationsLoaded: false,
                isRedirecting: false,
                errorMessage: ''
            });
        default:
            return state;
    }
}
