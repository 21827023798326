/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../../../../core/pipes/translate.pipe";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/forms";
import * as i7 from "./input.component";
var styles_InputComponent = [i0.styles];
var RenderType_InputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
function View_InputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "button", [["class", "tooltip-element"], ["type", "button"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i2.NgbTooltipConfig, i1.NgZone, i3.DOCUMENT, i1.ChangeDetectorRef], { ngbTooltip: [0, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-question-circle"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.toolTip, ""); _ck(_v, 1, 0, currVal_0); }, null); }
function View_InputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-default"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i4.TranslatePipe, [i5.Store])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.helpText)); _ck(_v, 0, 0, currVal_0); }); }
function View_InputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i4.TranslatePipe, [i5.Store])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.errorMessage)); _ck(_v, 0, 0, currVal_0); }); }
export function View_InputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "label", [["for", "control"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "marlin-required": 0 }), i1.ɵpid(0, i4.TranslatePipe, [i5.Store]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["input", 1]], null, 3, "input", [["autocomplete", "false"], ["class", "form-control"], ["name", "control"]], [[8, "type", 0], [8, "placeholder", 0]], [[null, "keypress"], [null, "keyup"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keypress" === en)) {
        var pd_0 = (_co.onKeyPress($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.onChange($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        _co.onBlur($event);
        var pd_2 = (_co.onTouched() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "ng-invalid": 0, "ng-touched": 1 }), i1.ɵpid(0, i4.TranslatePipe, [i5.Store]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputComponent_3)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, ((_co.control.errors == null) ? null : _co.control.errors.required)); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.toolTip != ""); _ck(_v, 7, 0, currVal_2); var currVal_5 = "form-control"; var currVal_6 = _ck(_v, 10, 0, _co.control.invalid, _co.control.touched); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = _co.helpText; _ck(_v, 13, 0, currVal_7); var currVal_8 = (_co.control.invalid && _co.control.touched); _ck(_v, 15, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform(_co.label)); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.type, ""); var currVal_4 = i1.ɵunv(_v, 8, 1, i1.ɵnov(_v, 11).transform(_co.placeholder)); _ck(_v, 8, 0, currVal_3, currVal_4); }); }
export function View_InputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "marlin-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.InputComponent]), i1.ɵdid(2, 114688, null, 0, i7.InputComponent, [i2.NgbTooltipConfig, [3, i6.ControlContainer]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputComponentNgFactory = i1.ɵccf("marlin-input", i7.InputComponent, View_InputComponent_Host_0, { label: "label", errorMessage: "errorMessage", placeholder: "placeholder", type: "type", toolTip: "toolTip", toolTipConfig: "toolTipConfig", formControlName: "formControlName", allowTrailingSpaceTrim: "allowTrailingSpaceTrim", helpText: "helpText" }, {}, []);
export { InputComponentNgFactory as InputComponentNgFactory };
