import { is16OrOver } from '~app/logical-objects/helpers/date-time';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';

import { AuthTokenName } from '~constants/app-config';
import { AppState } from '~app/store/app-state';
import * as _ from 'lodash';
import { UserMetaData } from '~app/constants/bit-data';
import * as userActions from '~store/actions/user.actions';
import * as appDataActions from '~store/actions/app-data.actions';
import { User } from '~app/models/user';
import * as moment from 'moment';

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private store: Store<AppState>) {}
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        let isAuthorised = false;
        let userData: User;
        this.store.select(state => state.user).subscribe(data => {
            isAuthorised = !!window.sessionStorage.getItem(AuthTokenName) && !_.isEmpty(data);
            userData = data;
        });

        if (userData.dateOfBirth) {
            if (!is16OrOver(moment(userData.dateOfBirth))) {
                this.store.dispatch(new userActions.ResetUserData());
                window.sessionStorage.removeItem(AuthTokenName);
                this.router.navigate(['/young']);
                this.store.dispatch(new appDataActions.ToggleIsLoading(false));
            }
        } else if (!isAuthorised) {
            this.router.navigate(['/login']);
        } else if (UserMetaData.isPasswordChangedOnNextLogin & userData.metadata) {
            this.router.navigate(['/reset-password'], { queryParams: { type: 'update' } });
            this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        }

        return isAuthorised;
    }
}
