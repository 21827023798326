<div class="form-group">
    <label for="control" [ngClass]="{'marlin-required': this.control.errors?.required}" [innerHTML]='label | translate'></label>
    <div class="input-group">
        <input class="form-control" #input [ngClass]="{'ng-invalid': control.invalid, 'ng-touched': control.touched}" placeholder="{{placeholder | translate}}"
            type="{{passwordInputType}}" (keyup)="onChange($event.target.value)" (blur)="changeInputType($event, true); onTouched(); showToolTip ? t.close() : null"
            (focus)="showToolTip ? t.open() : null" id="control" name="control" autocomplete="current-password">
        <div class="input-group-append" [ngbTooltip]="ttPasswordRequirements" triggers="manual" #t="ngbTooltip" placement='bottom'>
            <div class="input-group-text d-flex justify-content-center" tabindex="100000" (click)='changeInputType($event)' (blur)='changeInputType($event, true)'>
                <i class="fa fa-eye-slash"></i>
            </div>
        </div>
    </div>
    <div class="alert alert-danger" *ngIf="control.hasError('required') && control.touched" [innerHTML]='errorMessage | translate'>
    </div>
    <ng-template #ttPasswordRequirements>
        <div class="password-requirements">
            <label [innerHTML]='"gblPasswordTooShort12ErrorMsg" | translate'></label>
            <label [innerHTML]='"lclPasswordStrength" | translate'></label>
            <ngb-progressbar type="{{progressColour}}" [value]="strength"></ngb-progressbar>
        </div>
    </ng-template>
</div>