import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { environment } from '~environments/environment';
import { Component, OnInit } from '@angular/core';
import { UserService } from '~app/services/auth/user.service';
import { AppState } from '~app/store/app-state';
import * as appDataActions from '~store/actions/app-data.actions';
import { handleMapiError } from '~app/logical-objects/helpers/system';

@Component({
    selector: 'marlin-accept-policy',
    templateUrl: './accept-policy.component.html',
    styleUrls: ['./accept-policy.component.scss']
})
export class AcceptPolicyComponent implements OnInit {
    policiesUrl = `${environment.myhillsongUrl}/Policies.aspx`;
    isAcceptPolicy: boolean;
    constructor(private router: Router, private userService: UserService, private store: Store<AppState>) {}

    ngOnInit() {}

    submit() {
        if (this.isAcceptPolicy) {
            this.userService.updateTermsAndConditions(this.isAcceptPolicy).subscribe(
                data => {
                    this.router.navigate(['/login']);
                },
                err => {
                    handleMapiError(this.store, err.error.status);
                    this.router.navigate(['/login']);
                }
            );
        }
    }
}
