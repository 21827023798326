﻿// Gesture support.

import 'hammerjs';
import '~core/rxjs-extentsions';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';

import { RaygunErrorHandler } from './app.raygun.setup';
import { AppComponent } from '~app/app.component';
import { PublicModule } from '~public/public.module';
import { AuthenticatedModule } from '~authenticated/authenticated.module';
import { AppRoutingModule } from '~app/app-routing.module';
import { MarlinStoreModule } from '~store/marlin-store.module';
import { ServicesModule } from '~services/services.module';
import { CoreModule } from '~core/core.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        FormsModule,
        HttpClientModule,
        PublicModule,
        AuthenticatedModule,
        MarlinStoreModule,
        NgbModule.forRoot(),
        ServicesModule,
        CookieModule.forRoot(),
        DropDownsModule
    ],
    bootstrap: [AppComponent],
    providers: [
        Store,
        {
            provide: ErrorHandler,
            useClass: RaygunErrorHandler
        }
    ]
})
export class AppModule {}
