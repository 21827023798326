<div [formGroup]="dateForm">
    <label [innerHTML]='"gblDateOfBirth" | translate' class="dob-title"></label>
    <div class="container">
        <div class="row">
            <div class="form-group dob-container">
                <label class="dob-description" [ngClass]="{'marlin-required': dateForm.controls.year.errors?.required}" [innerHTML]="'gblYear' | translate"></label>
                <kendo-dropdownlist class="form-control" [valuePrimitive]='true' [value]="dateForm.controls.year.value" [data]='yearOptions'
                    [ignoreCase]='true' [valueField]='"value"' [textField]='"text"' formControlName="year" (valueChange)='onChange(dateForm);yearMonthChange("year")'>
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <div [innerHTML]="dataItem.text" class="dob-item"></div>
                    </ng-template>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <div class="dob-value" [innerHTML]="dataItem?.text"></div>
                    </ng-template>
                </kendo-dropdownlist>
            </div>
            <div class="form-group dob-container">
                <label class="dob-description" [ngClass]="{'marlin-required': dateForm.controls.month.errors?.required}" [innerHTML]="'gblMonth' | translate"></label>
                <kendo-dropdownlist class="form-control" [valuePrimitive]='true' [value]="dateForm.controls.month.value" [data]='monthOptions'
                    [ignoreCase]='true' [valueField]='"value"' [textField]='"text"' formControlName="month" (valueChange)='onChange(dateForm); yearMonthChange("month")'>
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <div [innerHTML]="dataItem.text" class="dob-item"></div>
                    </ng-template>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <div class="dob-value" [innerHTML]="dataItem?.text"></div>
                    </ng-template>
                </kendo-dropdownlist>
            </div>
            <div class="form-group dob-container">
                <label class="dob-description" [ngClass]="{'marlin-required': dateForm.controls.day.errors?.required}" [innerHTML]="'gblDate' | translate"></label>
                <kendo-dropdownlist class="form-control" [valuePrimitive]='true' [value]="dateForm.controls.day.value" [data]='dayOptions'
                    [ignoreCase]='true' [valueField]='"value"' [textField]='"text"' formControlName="day" (valueChange)='onChange(dateForm)'>
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <div [innerHTML]="dataItem.text" class="dob-item"></div>
                    </ng-template>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <div class="dob-value" [innerHTML]="dataItem?.text"></div>
                    </ng-template>
                </kendo-dropdownlist>
            </div>
            <div class="form-group">
                <div class="alert alert-danger" *ngIf="dateForm.controls.year.invalid && dateForm.controls.year.touched" [innerHTML]='"gblYearErrorMsg" | translate'>
                </div>
                <div class="alert alert-danger" *ngIf="dateForm.controls.month.invalid && dateForm.controls.month.touched" [innerHTML]='"gblMonthErrorMsg" | translate'>
                </div>
                <div class="alert alert-danger" *ngIf="dateForm.controls.day.invalid && dateForm.controls.day.touched" [innerHTML]='"gblDateErrorMsg" | translate'>
                </div>
            </div>
        </div>
    </div>
</div>