import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UserPasswordRequest } from '~app/models/user';
import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';

@Injectable()
export class PasswordService {
    private _Change_Password_Endpoint = '/passwordchange';
    private _Reset_Password_Endpoint = '/passwordreset';
    private _Change_Password_Hapi_Endpoint = '/UserDetails/UpdatePassword';

    constructor(private httpClient: HttpClient) {}

    resetPassword(email): Observable<{}> {
        const body = {
            loginEmail: email
        };

        return this.httpClient.post(environment.marlinApiUrl + this._Reset_Password_Endpoint, body).catch(ErrorHandler);
    }

    updatePassword(data: UserPasswordRequest): Observable<{}> {
        const headers = new HttpHeaders({
            authorization: data.token
        });
        const body: UserPasswordRequest = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            code: data.code,
            resetPassword: true
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    }

    updatePasswordLoggedInUser(data: UserPasswordRequest): Observable<{}> {
        const headers = new HttpHeaders({
            authorization: data.token
        });
        const body: UserPasswordRequest = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            oldPassword: data.oldPassword
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    }

    updatePasswordHapi(data: UserPasswordRequest): Observable<{}> {
        const body = {
            passwordToken: data.tk,
            newPassword: data.password
        };
        return this.httpClient
            .post(environment.hapiUrl + this._Change_Password_Hapi_Endpoint, body)
            .catch(ErrorHandler);
    }

    completeRegistration(data: UserPasswordRequest): Observable<{}> {
        const headers = new HttpHeaders({
            authorization: data.token
        });
        const body = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            code: data.code,
            urlEncoded: data.urlEncoded
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    }

    verifyPasswordDetails(password: string, token: string): Observable<{}> {
        const body = {
            password: password,
            token: token
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body)
            .catch(ErrorHandler);
    }
}
