import { ActivatedRoute, Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { UserService } from '~app/services/auth/user.service';
import { Store } from '@ngrx/store';
import { AuthTokenName } from '~constants/app-config';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userActions from '~store/actions/user.actions';
var GlobalLoginComponent = /** @class */ (function () {
    function GlobalLoginComponent(activatedRoute, router, userService, store) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.userService = userService;
        this.store = store;
    }
    GlobalLoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (params) {
            var nonce = params['nonce'];
            if (!nonce) {
                _this.router.navigate(['/login']);
            }
            else {
                _this.store.dispatch(new appDataActions.ToggleIsLoading(true));
                _this.userService.getToken(nonce).subscribe(function (data) {
                    window.sessionStorage.setItem(AuthTokenName, data.token);
                    _this.store.dispatch(new userActions.GetUser(data.token));
                }, function (err) {
                    _this.router.navigate(['/login']);
                    handleMapiError(_this.store, err.error.status);
                });
            }
        });
        this.store.select(function (state) { return state.user; }).subscribe(function (user) {
            if (user.personId) {
                _this.router.navigate(['/portal/home']);
                _this.store.dispatch(new appDataActions.ToggleIsLoading(false));
            }
        });
    };
    return GlobalLoginComponent;
}());
export { GlobalLoginComponent };
