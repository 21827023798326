import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from '~services/auth/user.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import * as userActions from '~actions/user.actions';
var AuthUserEffects = /** @class */ (function () {
    function AuthUserEffects(actions$, userService, store, router) {
        var _this = this;
        this.actions$ = actions$;
        this.userService = userService;
        this.store = store;
        this.router = router;
        this.getUser$ = this.actions$.pipe(ofType(userActions.GET_USER), map(function (toPayload) {
            return toPayload;
        }), switchMap(function (payload) {
            return _this.userService.getUser(payload)
                .map(function (user) {
                return new userActions.GetUserSuccess(user.data);
            })
                .catch(function (err) {
                return of(new userActions.GetUserFailed(err.error.status));
            })
                .finally(function () { });
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Object)
    ], AuthUserEffects.prototype, "getUser$", void 0);
    return AuthUserEffects;
}());
export { AuthUserEffects };
