import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';
import { Translations, Consents } from '~models/common';
import { MetaDataOptions, IPResponse } from '~app/models/meta-data';

@Injectable()
export class MetadataService {
    private _Metadata_Endpoint = '/Metadata';
    private _Translations_Endpoint = '/TranslationStrings';
    private _Consents_Endpoint = '/Consents/Wordings';
    // Extreme IP as a free rate limit of 200 calls per minute.
    private _GeoIPUrl = 'https://extreme-ip-lookup.com/json/';

    constructor(private httpClient: HttpClient) {}

    getMetadata(metaType: string, churchId?: number): Observable<MetaDataOptions> {
        if (!churchId) {
            churchId = 1;
        }
        return this.httpClient
            .get<MetaDataOptions>(
                `${environment.hapiUrl + this._Metadata_Endpoint}?metaTypes=${metaType}&churchID=${churchId}`
            )
            .catch(ErrorHandler);
    }

    getTranslations(md5: string, culture: string): Observable<Translations> {
        if (!culture) {
            culture = 'en-AU';
        }
        return this.httpClient
            .get<Translations>(
                `${environment.hapiUrl + this._Translations_Endpoint}?md5=${md5}${culture ? `&culture=${culture}` : ''}`
            )
            .catch(ErrorHandler);
    }

    getConsents(languageCode: string): Observable<Consents> {
        if (!languageCode) {
            languageCode = 'en-AU';
        }
        let url = environment.hapiUrl + this._Consents_Endpoint;
        url += '?api_key=46b95198-60e5-4f0d-a417-4f59f5d0ffa5';
        url += languageCode ? `&languageCode=${languageCode}` : '';

        return this.httpClient.get<Consents>(url).catch(ErrorHandler);
    }

    getIPCountry(): Observable<IPResponse> {
        return this.httpClient.get<IPResponse>(`${this._GeoIPUrl}`).catch(ErrorHandler);
    }
}
