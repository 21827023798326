<div class="form-group">
    <label for="control" [ngClass]="{'marlin-required': this.control.errors?.required}" [innerHTML]='label | translate'></label>
    <button type="button" *ngIf="toolTip != ''" ngbTooltip="{{toolTip}}" class="tooltip-element">
        <i class="fa fa-question-circle"></i>
    </button>
    <input class="form-control" autocomplete="false" #input type="{{type}}" [ngClass]="{'ng-invalid': control.invalid, 'ng-touched': control.touched}"
        [placeholder]="placeholder | translate" (keypress)="onKeyPress($event)" (keyup)="onChange($event.target.value)" (blur)="onBlur($event); onTouched();"
        name="control">
    <div class="alert alert-default" *ngIf="helpText" [innerHTML]='helpText | translate'>
    </div>
    <div class="alert alert-danger" *ngIf="control.invalid && control.touched" [innerHTML]='errorMessage | translate'>
    </div>
</div>