import { UserSupportRequest } from '~models/user';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';
import { MetaData } from '~app/models/meta-data';

@Injectable()
export class SupportService {
    private _Support_Endpoint = '/enquiries';

    constructor(private httpClient: HttpClient) {}

    support(userSupportRequest: UserSupportRequest) {
        const body = {
            enquiryType: userSupportRequest.supportType,
            name: userSupportRequest.name,
            emailAddress: userSupportRequest.email,
            message: userSupportRequest.issueDescription
        };

        return this.httpClient.post(environment.hapiUrl + this._Support_Endpoint, body).catch(ErrorHandler);
    }
}
