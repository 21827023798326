/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../core/pipes/translate.pipe";
import * as i3 from "@ngrx/store";
import * as i4 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "./input-password.component";
var styles_InputPasswordComponent = [i0.styles];
var RenderType_InputPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputPasswordComponent, data: {} });
export { RenderType_InputPasswordComponent as RenderType_InputPasswordComponent };
function View_InputPasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i2.TranslatePipe, [i3.Store])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.errorMessage)); _ck(_v, 0, 0, currVal_0); }); }
function View_InputPasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "password-requirements"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i2.TranslatePipe, [i3.Store]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i2.TranslatePipe, [i3.Store]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ngb-progressbar", [], null, null, null, i4.View_NgbProgressbar_0, i4.RenderType_NgbProgressbar)), i1.ɵdid(6, 49152, null, 0, i5.NgbProgressbar, [i5.NgbProgressbarConfig], { type: [0, "type"], value: [1, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.progressColour, ""); var currVal_3 = _co.strength; _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("gblPasswordTooShort12ErrorMsg")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("lclPasswordStrength")); _ck(_v, 3, 0, currVal_1); }); }
export function View_InputPasswordComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "label", [["for", "control"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "marlin-required": 0 }), i1.ɵpid(0, i2.TranslatePipe, [i3.Store]), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["input", 1]], null, 3, "input", [["autocomplete", "current-password"], ["class", "form-control"], ["id", "control"], ["name", "control"]], [[8, "placeholder", 0], [8, "type", 0]], [[null, "keyup"], [null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        _co.changeInputType($event, true);
        _co.onTouched();
        var pd_1 = ((_co.showToolTip ? i1.ɵnov(_v, 12).close() : null) !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = ((_co.showToolTip ? i1.ɵnov(_v, 12).open() : null) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "ng-invalid": 0, "ng-touched": 1 }), i1.ɵpid(0, i2.TranslatePipe, [i3.Store]), (_l()(), i1.ɵeld(11, 16777216, null, null, 3, "div", [["class", "input-group-append"], ["placement", "bottom"], ["triggers", "manual"]], null, null, null, null, null)), i1.ɵdid(12, 212992, [["t", 4]], 0, i5.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i5.NgbTooltipConfig, i1.NgZone, i6.DOCUMENT, i1.ChangeDetectorRef], { placement: [0, "placement"], triggers: [1, "triggers"], ngbTooltip: [2, "ngbTooltip"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "input-group-text d-flex justify-content-center"], ["tabindex", "100000"]], null, [[null, "click"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeInputType($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.changeInputType($event, true) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fa fa-eye-slash"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputPasswordComponent_1)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["ttPasswordRequirements", 2]], null, 0, null, View_InputPasswordComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 4, 0, ((_co.control.errors == null) ? null : _co.control.errors.required)); _ck(_v, 3, 0, currVal_1); var currVal_4 = "form-control"; var currVal_5 = _ck(_v, 9, 0, _co.control.invalid, _co.control.touched); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = "bottom"; var currVal_7 = "manual"; var currVal_8 = i1.ɵnov(_v, 17); _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = (_co.control.hasError("required") && _co.control.touched); _ck(_v, 16, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform(_co.label)); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 10).transform(_co.placeholder)), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.passwordInputType, ""); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_InputPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "marlin-input-password", [], null, null, null, View_InputPasswordComponent_0, RenderType_InputPasswordComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.InputPasswordComponent]), i1.ɵdid(2, 114688, null, 0, i8.InputPasswordComponent, [i5.NgbTooltipConfig, [3, i7.ControlContainer]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputPasswordComponentNgFactory = i1.ɵccf("marlin-input-password", i8.InputPasswordComponent, View_InputPasswordComponent_Host_0, { label: "label", errorMessage: "errorMessage", formControlName: "formControlName", placeholder: "placeholder", type: "type", showToolTip: "showToolTip" }, {}, []);
export { InputPasswordComponentNgFactory as InputPasswordComponentNgFactory };
