import { Action } from '@ngrx/store';

import { ServiceErrorResponse } from '~models/http-response';
import { MetaData, MetaDataOptions } from '~models/meta-data';
import { Translations, Consents } from '~models/common';

export const LOAD_METADATA = '[Metadata] Load Metadata';
export const LOAD_METADATA_SUCCESS = '[Metadata] Load Metadata Success';
export const LOAD_METADATA_FAILURE = '[Metadata] Load Metadata Failure';

export const LOAD_CONSENTS = '[Consents] Load Consents';
export const LOAD_CONSENTS_SUCCESS = '[Consents] Load Consents Success';
export const LOAD_CONSENTS_FAILURE = '[Consents] Load Consents Failure';

export const LOAD_TRANSLATIONS = '[Translations] Load Translations';
export const LOAD_TRANSLATIONS_SUCCESS = '[Translations] Load Translations Success';
export const LOAD_TRANSLATIONS_FAILURE = '[Translations] Load Translations Failure';

export const TOGGLE_USER_IS_LOADED = '[IsUserLoaded] Toggle User Is Loaded';
export const TOGGLE_TRANSLATIONS_IS_LOADED = '[IsTranslationLoaded] Toggle Translations Is Loaded';
export const TOGGLE_IS_LOADING = '[IsLoading] Toggle Is Loading';
export const TOGGLE_IS_LOGGED_IN = '[IsLoggedIn] Toggle Is Logged In';
export const TOGGLE_IS_REDIRECTING = '[IsRedirecting] Toggle Is Redirecting';

export const INITIALISE_APP_STATE = '[InitialiseAppState] Set Default Values For App State';
export const SET_APP_ERROR_MESSAGE = '[AppErrorMessage] Set Application Error Message';

export class LoadMetadata implements Action {
    readonly type = LOAD_METADATA;
    constructor(public payload: string, public churchId?: number) {}
}

export class LoadMetadataSuccess implements Action {
    readonly type = LOAD_METADATA_SUCCESS;
    constructor(public payload: MetaDataOptions, public metaType: string) {}
}

export class LoadMetadataFailure implements Action {
    readonly type = LOAD_METADATA_FAILURE;
    constructor(public payload: ServiceErrorResponse) {}
}

export class LoadTranslations implements Action {
    readonly type = LOAD_TRANSLATIONS;
    // Payload is string culture.
    constructor(public payload?: string) {}
}

export class LoadTranslationsSuccess implements Action {
    readonly type = LOAD_TRANSLATIONS_SUCCESS;
    constructor(public payload: Translations) {}
}

export class LoadTranslationsFailure implements Action {
    readonly type = LOAD_TRANSLATIONS_FAILURE;
    constructor(public payload: Translations) {}
}

export class LoadConsents implements Action {
    readonly type = LOAD_CONSENTS;
    // Payload is string culture.
    constructor(public payload?: string) {}
}

export class LoadConsentsSuccess implements Action {
    readonly type = LOAD_CONSENTS_SUCCESS;
    constructor(public payload: Consents) {}
}

export class LoadConsentsFailure implements Action {
    readonly type = LOAD_CONSENTS_FAILURE;
    constructor(public payload: ServiceErrorResponse) {}
}

export class ToggleUserIsLoaded implements Action {
    readonly type = TOGGLE_USER_IS_LOADED;
    constructor(public payload: boolean) {}
}

export class ToggleTranslationsIsLoaded implements Action {
    readonly type = TOGGLE_TRANSLATIONS_IS_LOADED;
    constructor(public payload: boolean) {}
}

export class ToggleIsLoading implements Action {
    readonly type = TOGGLE_IS_LOADING;
    constructor(public payload: boolean) {}
}

export class ToggleIsLoggedIn implements Action {
    readonly type = TOGGLE_IS_LOGGED_IN;
    constructor(public payload: boolean) {}
}

export class ToggleIsRedirecting implements Action {
    readonly type = TOGGLE_IS_REDIRECTING;
    constructor(public payload: boolean) {}
}

export class InitiliseAppState implements Action {
    readonly type = INITIALISE_APP_STATE;
    constructor() {}
}

export class SetAppErrorMessage implements Action {
    readonly type = SET_APP_ERROR_MESSAGE;
    constructor(public payload: string) {}
}

export type All =
    | LoadMetadata
    | LoadMetadataSuccess
    | LoadMetadataFailure
    | LoadTranslations
    | LoadTranslationsSuccess
    | LoadTranslationsFailure
    | LoadConsents
    | LoadConsentsSuccess
    | LoadConsentsFailure
    | ToggleUserIsLoaded
    | ToggleTranslationsIsLoaded
    | ToggleIsLoading
    | ToggleIsLoggedIn
    | ToggleIsRedirecting
    | InitiliseAppState
    | SetAppErrorMessage;
