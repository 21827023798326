﻿import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserService } from '~services/auth/user.service';
import { markAllTouched } from '~logic/helpers/form';
import { AuthTokenName } from '~constants/app-config';
import { AppState } from '~app/store/app-state';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userActions from '~store/actions/user.actions';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import { environment } from '~environments/environment';
import { UserMetaData } from '~app/constants/bit-data';
import { validateEmail } from '~app/core/validators';

@Component({
    selector: 'marlin-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    errorMessage = '';
    loginForm: FormGroup;
    policiesUrl = `${environment.myhillsongUrl}/Policies.aspx`;
    rememberCookie = 'marlinEmail';
    isGlobalUser: boolean;

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private router: Router,
        private store: Store<AppState>,
        private activatedRoute: ActivatedRoute,
        private cookieService: CookieService
    ) {}

    ngOnInit() {
        window.sessionStorage.removeItem(AuthTokenName);
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                redirect: null
            },
            queryParamsHandling: 'merge'
        });

        this.buildForm();
        const emailCookie = this.cookieService.get(this.rememberCookie);
        if (emailCookie) {
            this.loginForm.setValue({ loginEmail: emailCookie, password: null, isRemember: true });
        }

        this.store.select(state => state.user.metadata).subscribe(data => {
            if (!!(data & UserMetaData.isGlobalUser)) {
                this.isGlobalUser = !!(data & UserMetaData.isGlobalUser);
                this.store.dispatch(new appDataActions.ToggleIsLoggedIn(false));
                this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
            }
        });
    }

    buildForm() {
        this.loginForm = this.fb.group({
            loginEmail: ['', [Validators.required, validateEmail]],
            password: ['', Validators.required],
            isRemember: ['']
        });
    }

    onSubmit() {
        if (this.loginForm.get('isRemember').value) {
            this.cookieService.put(this.rememberCookie, this.loginForm.get('loginEmail').value);
        } else {
            this.cookieService.remove(this.rememberCookie);
        }

        if (this.loginForm.invalid) {
            markAllTouched(this.loginForm);
            return;
        }

        this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
        if (this.loginForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.userService.authenticate(this.loginForm.value).subscribe(
                user => {
                    window.sessionStorage.setItem(AuthTokenName, user.token);
                    // Get user details now that they have successfully logged in.
                    this.store.dispatch(new userActions.GetUser(user.token));
                },
                err => handleMapiError(this.store, err.error.status)
            );
        } else {
            markAllTouched(this.loginForm);
        }
    }

    resetUserData() {
        this.store.dispatch(new userActions.ResetUserData());
        window.sessionStorage.removeItem(AuthTokenName);
        this.buildForm();
        // Set user is loaded, to avoid loading forever loop.
        this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
    }
}
