import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';
var VerificationTokenService = /** @class */ (function () {
    function VerificationTokenService(httpClient) {
        this.httpClient = httpClient;
        this._Validate_Verfication_Token_Endpoint = '/ValidateVerificationToken';
        this._Resend_Verification_Email_Endpoint = '/ResendVerificatonEmailRequest';
        this._Password_Reset_Resend_Verification_Email_Endpoint = '/PasswordResetResendVerificatonEmail';
    }
    VerificationTokenService.prototype.validate = function (data) {
        var headers = new HttpHeaders({
            authorization: data.token
        });
        var body = {
            data: ''
        };
        // Throws on HTTP-4XX. Service subscribers should catch and handle error case.
        return this.httpClient
            .post(environment.marlinApiUrl + this._Validate_Verfication_Token_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    };
    VerificationTokenService.prototype.resendVerificationEmail = function (data) {
        var headers = new HttpHeaders({
            authorization: data.token
        });
        var body = {
            data: ''
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Resend_Verification_Email_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    };
    VerificationTokenService.prototype.passwordResetResendVerificationEmail = function (email) {
        var body = {
            loginEmail: email
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Password_Reset_Resend_Verification_Email_Endpoint, body)
            .catch(ErrorHandler);
    };
    return VerificationTokenService;
}());
export { VerificationTokenService };
