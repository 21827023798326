import { Action } from '@ngrx/store';

import { ServiceErrorResponse } from '~models/http-response';
import { User, UserLogin, UserLoginResponse } from '~models/user';

export const GET_USER = '[Users] Get';
export const GET_USER_SUCCESS = '[Users] Get Sucess';
export const GET_USER_FAILED = '[Users] Get Failed';
export const RESET_USER_DATA = '[Users] Reset User Data';

export class ResetUserData implements Action {
    readonly type = RESET_USER_DATA;
    constructor() {}
}

export class GetUser implements Action {
    readonly type = GET_USER;
    constructor(public payload: string) {}
}

export class GetUserSuccess implements Action {
    readonly type = GET_USER_SUCCESS;
    constructor(public payload: User) {}
}

export class GetUserFailed implements Action {
    readonly type = GET_USER_FAILED;
    constructor(public payload: string) {}
}

export type All = GetUser | GetUserSuccess | GetUserFailed | ResetUserData;
