/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/controls/input/input.component.ngfactory";
import * as i3 from "../../shared/controls/input/input.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "@angular/forms";
import * as i6 from "../../../core/pipes/translate.pipe";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/common";
import * as i9 from "../../shared/controls/home-button/home-button.component.ngfactory";
import * as i10 from "../../shared/controls/home-button/home-button.component";
import * as i11 from "@angular/router";
import * as i12 from "./forgot-password.component";
import * as i13 from "../../../services/auth/password.service";
import * as i14 from "../../../services/auth/verification_token.service";
var styles_ForgotPasswordComponent = [i0.styles];
var RenderType_ForgotPasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordComponent, data: {} });
export { RenderType_ForgotPasswordComponent as RenderType_ForgotPasswordComponent };
function View_ForgotPasswordComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "marlin-input", [["formControlName", "email"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i2.View_InputComponent_0, i2.RenderType_InputComponent)), i1.ɵdid(2, 114688, null, 0, i3.InputComponent, [i4.NgbTooltipConfig, [3, i5.ControlContainer]], { label: [0, "label"], errorMessage: [1, "errorMessage"], type: [2, "type"], formControlName: [3, "formControlName"] }, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.InputComponent]), i1.ɵdid(4, 671744, null, 0, i5.FormControlName, [[3, i5.ControlContainer], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlName]), i1.ɵdid(6, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "form-group text-center "]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary w-100"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(0, i6.TranslatePipe, [i7.Store])], function (_ck, _v) { var currVal_7 = "gblEmail"; var currVal_8 = "gblEmailAddressRequiredErrorMsg"; var currVal_9 = "email"; var currVal_10 = "email"; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "email"; _ck(_v, 4, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_12 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("gblGo")); _ck(_v, 8, 0, currVal_12); }); }
function View_ForgotPasswordComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "form-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i6.TranslatePipe, [i7.Store])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("gblFurtherInstructionsSent")); _ck(_v, 2, 0, currVal_0); }); }
function View_ForgotPasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "form-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i6.TranslatePipe, [i7.Store]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_3)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "form-group text-center form-navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "marlin-home-button", [], null, null, null, i9.View_HomeButtonComponent_0, i9.RenderType_HomeButtonComponent)), i1.ɵdid(11, 49152, null, 0, i10.HomeButtonComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.uiStatus === _co.uiStatuses.initial); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.uiStatus === _co.uiStatuses.submitSuccess); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("pmtResetPassword")); _ck(_v, 3, 0, currVal_0); }); }
function View_ForgotPasswordComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "form-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i6.TranslatePipe, [i7.Store]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i6.TranslatePipe, [i7.Store]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-outline-primary w-100"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resendVerificationEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(0, i6.TranslatePipe, [i7.Store]), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "col text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "a", [], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 671744, null, 0, i11.RouterLinkWithHref, [i11.Router, i11.ActivatedRoute, i8.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(14, { type: 0 }), i1.ɵpad(15, 1), i1.ɵpid(0, i6.TranslatePipe, [i7.Store]), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "col text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i11.RouterLinkWithHref, [i11.Router, i11.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(20, 1), i1.ɵpid(0, i6.TranslatePipe, [i7.Store])], function (_ck, _v) { var currVal_6 = _ck(_v, 14, 0, "PasswordReset"); var currVal_7 = _ck(_v, 15, 0, "/support"); _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_11 = _ck(_v, 20, 0, "/portal/home"); _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("gblUnableToCompleteYourRequest")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("gblUnconfirmedCognitoUserError")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("gblGenerateToken")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 16).transform("gblNeedHelp")); var currVal_4 = i1.ɵnov(_v, 13).target; var currVal_5 = i1.ɵnov(_v, 13).href; _ck(_v, 12, 0, currVal_3, currVal_4, currVal_5); var currVal_8 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 21).transform("gblHomePage")); var currVal_9 = i1.ɵnov(_v, 19).target; var currVal_10 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10); }); }
export function View_ForgotPasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i5.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(2, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_1)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordComponent_4)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.forgotPasswordForm; _ck(_v, 2, 0, currVal_7); var currVal_8 = !_co.isUnconfirmedCognitoUser; _ck(_v, 6, 0, currVal_8); var currVal_9 = _co.isUnconfirmedCognitoUser; _ck(_v, 8, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ForgotPasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "marlin-forgot-password", [], null, null, null, View_ForgotPasswordComponent_0, RenderType_ForgotPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i12.ForgotPasswordComponent, [i5.FormBuilder, i7.Store, i13.PasswordService, i14.VerificationTokenService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordComponentNgFactory = i1.ɵccf("marlin-forgot-password", i12.ForgotPasswordComponent, View_ForgotPasswordComponent_Host_0, {}, {}, []);
export { ForgotPasswordComponentNgFactory as ForgotPasswordComponentNgFactory };
