import { AbstractControl, FormControl } from '@angular/forms';

const MarkType = {
    touched: 1,
    untouched: 2
};

export function markAllTouched(control: AbstractControl) {
    markControls(control, MarkType.touched);
}

export function markAllUntouched(control: AbstractControl) {
    markControls(control, MarkType.untouched);
}

function markControls(control: AbstractControl, type: number) {
    if (control.hasOwnProperty('controls')) {
        switch (type) {
            case MarkType.touched:
                control.markAsTouched();
                break;
            case MarkType.untouched:
                control.markAsUntouched();
                break;
        }
        const ctrl = <any>control;
        for (const inner in ctrl.controls) {
            markControls(ctrl.controls[inner] as AbstractControl, type);
        }
    } else {
        switch (type) {
            case MarkType.touched:
                (<FormControl>control).markAsTouched();
                break;
            case MarkType.untouched:
                (<FormControl>control).markAsUntouched();
                (<FormControl>control).markAsPristine();
                break;
        }
    }
}

const PhoneRegex: RegExp = /^(\d)|(\+)+/;
const HomePhoneRegex: RegExp = /^(\d)|(\+)|(\))|(\()+/;
export const NameRegex: RegExp = /^[a-zA-ZЀ-џ]+(([',. -][a-zA-ZЀ-џ ])?[a-zA-ZЀ-џ ]*)*$/;

export function verifyValidPhoneKey(e: KeyboardEvent) {
    return verifyKeyStroke(e, PhoneRegex);
}

export function verifyValidHomePhoneKey(e: KeyboardEvent) {
        return verifyKeyStroke(e, HomePhoneRegex);
}

export function verifyKeyStroke(e: KeyboardEvent, regex: RegExp) {
    const charCode = e.which ? e.which : e.keyCode;
    const charStr = String.fromCharCode(charCode);
    if (regex.test(charStr)) {
        return true;
    } else {
        return false;
    }
}

export function setNullValuesAsEmpty(obj: any) {
    // Returns an empty string for any value that is null.
    for (const prop in obj) {
        if (!obj[prop]) {
            obj[prop] = '';
        }
    }
    return obj;
}
