/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../core/pipes/translate.pipe";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/forms";
import * as i6 from "./input-group.component";
var styles_InputGroupComponent = [i0.styles];
var RenderType_InputGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputGroupComponent, data: {} });
export { RenderType_InputGroupComponent as RenderType_InputGroupComponent };
function View_InputGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "label", [["for", "control"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "marlin-required": 0 }), i1.ɵpid(0, i3.TranslatePipe, [i4.Store])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, ((_co.control.errors == null) ? null : _co.control.errors.required)); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 3).transform(_co.label)); _ck(_v, 0, 0, currVal_0); }); }
function View_InputGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "alert alert-danger"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i3.TranslatePipe, [i4.Store])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.errorMessage)); _ck(_v, 0, 0, currVal_0); }); }
export function View_InputGroupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { input: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputGroupComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "input-group-prepend"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "input-group-text d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["input", 1]], null, 3, "input", [["class", "form-control"], ["id", "control"], ["maxlength", "16"], ["name", "control"], ["type", "text"]], [[8, "placeholder", 0], [8, "value", 0]], [[null, "keypress"], [null, "keyup"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keypress" === en)) {
        var pd_0 = (_co.onKeyPress($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.onChange($event.target.value) !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (_co.onTouched() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "ng-invalid": 0, "ng-touched": 1 }), i1.ɵpid(0, i3.TranslatePipe, [i4.Store]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputGroupComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.label != ""); _ck(_v, 3, 0, currVal_0); var currVal_4 = "form-control"; var currVal_5 = _ck(_v, 9, 0, _co.control.invalid, _co.control.touched); _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_6 = (_co.control.invalid && _co.control.touched); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.iconClass, ""); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 10).transform(_co.placeholder)), ""); var currVal_3 = _co.control.value; _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_InputGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "marlin-input-group", [], null, null, null, View_InputGroupComponent_0, RenderType_InputGroupComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.InputGroupComponent]), i1.ɵdid(2, 638976, null, 0, i6.InputGroupComponent, [[3, i5.ControlContainer]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var InputGroupComponentNgFactory = i1.ɵccf("marlin-input-group", i6.InputGroupComponent, View_InputGroupComponent_Host_0, { errorMessage: "errorMessage", iconClass: "iconClass", label: "label", placeholder: "placeholder", type: "type", setError: "setError", formControlName: "formControlName" }, {}, []);
export { InputGroupComponentNgFactory as InputGroupComponentNgFactory };
