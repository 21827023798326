﻿import { NgModule } from '@angular/core';
// Possible erorr.  For some reason material requires the forms module to work.
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { TranslatePipe } from '~pipes/translate.pipe';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, MomentModule],
    declarations: [TranslatePipe, ObjectToArrayPipe],
    exports: [TranslatePipe, ObjectToArrayPipe]
})
export class CoreModule {}
