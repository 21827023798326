<div [formGroup]="passwordForm">
    <div class="form-group">
        <div class='alert alert-info normalstyle'>
            <span [innerHTML]='"gblPasswordRequirementHeading" | translate'></span>
            <ul style="margin-bottom: 0;">
                <li>{{"gblPasswordRequirement1" | translate}}</li>
                <li>{{"gblPasswordRequirement2" | translate}}</li>
                <li>{{"gblPasswordRequirement3" | translate}}</li>
            </ul>
        </div>
        <label for="password" [innerHTML]='"gblPassword" | translate'></label>

        <div class="input-group" [ngbTooltip]="ttPasswordRequirements" triggers="manual" #t="ngbTooltip" placement='bottom'>
            <input class="form-control" type="{{passwordInputType}}" id="password" formControlName="password" autocomplete="new-password" (focus)="t.open()" (blur)="t.close()">
            <div class="input-group-append">
                <button class="input-group-text d-flex justify-content-center" tabindex="100000" (click)='changeInputType($event)' (blur)='changeInputType($event, true)'>
                    <i class="fa fa-eye-slash"></i>
                </button>
            </div>
        </div>
        <div class="alert alert-danger" *ngIf="passwordForm.controls.password.hasError('required') && passwordForm.controls.password.touched"
            [innerHTML]='"gblPasswordRequiredErrorMsg" | translate'>
        </div>
        <div class="alert alert-danger" *ngIf="passwordForm.controls.password.hasError('minlength') && passwordForm.controls.password.touched"
            [innerHTML]='"gblPasswordTooShort12ErrorMsg" | translate'>
        </div>
        <div class="alert alert-danger" *ngIf="passwordForm.controls.password.hasError('maxlength') && passwordForm.controls.password.touched"
            [innerHTML]='"gblPasswordTooLong64ErrorMsg" | translate'>
        </div>
        <div class="alert alert-danger" *ngIf="passwordForm.controls.password.hasError('pattern') && passwordForm.controls.password.touched"
            [innerHTML]='"gblPasswordNoWhitespaceErrorMsg" | translate'>
        </div>

        <ng-template #ttPasswordRequirements>
            <div class="password-requirements">
                <label [innerHTML]='"lclPasswordStrength" | translate'></label>
                <ngb-progressbar type="{{progressColour}}" [value]="passwordForm.get('strength').value"></ngb-progressbar>
            </div>
        </ng-template>
    </div>

    <div class="form-group">
        <label for="repeatPassword" [innerHTML]='"gblPasswordRepeat" | translate'></label>
        <input class="form-control" type="password" id="repeatPassword" formControlName="confirmPassword">
        <div class="alert alert-danger" *ngIf="passwordForm.hasError('matchValues') && passwordForm.get('confirmPassword').touched"
            [innerHTML]='"gblPasswordRepeatRequiredErrorMsg" | translate'>
        </div>
    </div>
</div>