const PEM = 'pem';
const PAYLOAD = 'payload';
const ENCRYPTED_PAYLOAD = 'jwe';

import * as pemjwk from 'pem-jwk/index';
import * as Jose from 'jose-jwe-jws/lib/jose-core.js';

declare var global;
global.Jose = Jose;

export function JsonToJWEwPEM(event): PromiseLike<any> {

    VerifyType(event[PAYLOAD], 'object');

    var rsa_key = pemjwk.pem2jwk(event[PEM]);
    
    var key = Jose.Utils.importRsaPublicKey(rsa_key, 'RSA-OAEP');
  
    var cryptographer = new Jose.WebCryptographer();
   
    var encrypter = new Jose.JoseJWE.Encrypter(cryptographer, key);
      
    return encrypter.encrypt(JSON.stringify(event[PAYLOAD]))
        .then(function(encryptedPayload) {
            return encryptedPayload;
        });
}

export function JWEToJsonwPEM(event) {
    var rsa_key = pemjwk.pem2jwk(event[PEM]);
    
    var key = Jose.Utils.importRsaPublicKey(rsa_key, 'RSA-OAEP');
  
    var cryptographer = new Jose.WebCryptographer();
    
    var decrypter = new Jose.JoseJWE.Decrypter(cryptographer, key);
  
    return decrypter.decrypt(event[ENCRYPTED_PAYLOAD])
        .then(function(decrypted){
            const buff = JSON.parse(decrypted[PAYLOAD]);
            return buff;
        });
}

function VerifyType(toCheck, type) {
    if (typeof toCheck != type) {
        throw new Error('Expecting event.payload to be a json object.');
    }
}
