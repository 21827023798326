import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ChurchLocationServiceComponent } from './church-location-service/church-location-service.component';
import { PasswordComponent } from './password/password.component';
import { CoreModule } from '~core/core.module';
import { ControlsModule } from '~shared/controls/controls.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ConsentsComponent } from '~app/components/shared/ui-groups/consents/consents.component';
import { DateDropdownComponent } from '~app/components/shared/ui-groups/date-dropdown/date-dropdown.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, NgbModule, CoreModule, ControlsModule, DropDownsModule],
    declarations: [
        ChurchLocationServiceComponent,
        ConsentsComponent,
        PasswordComponent,
        BreadcrumbComponent,
        DateDropdownComponent
    ],
    exports: [
        ChurchLocationServiceComponent,
        ConsentsComponent,
        PasswordComponent,
        BreadcrumbComponent,
        DateDropdownComponent
    ]
})
export class UiGroupsModule {}
