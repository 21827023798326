import { Store } from '@ngrx/store';
import { FormBuilder, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '~services/auth/password.service';
import { VerificationTokenService } from '~services/auth/verification_token.service';
import { UIControlStatuses } from '~constants/ui';
import { ProgressSteps } from '~logic/ui/progress-steps';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userActions from '~store/actions/user.actions';
import { markAllTouched } from '~logic/helpers/form';
import { AuthTokenName } from '~app/constants/app-config';
import { getTranslatedErrorMessageFromResponse } from '~app/logical-objects/helpers/system';
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(fb, passwordService, verficationTokenService, activatedRoute, router, store) {
        this.fb = fb;
        this.passwordService = passwordService;
        this.verficationTokenService = verficationTokenService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.store = store;
        this.message = 'pmtResetPassword';
        this.passwordMatchForm = this.fb.group({
            code: [''],
            urlEncoded: [false],
            password: [''],
            confirmPassword: [''],
            oldPassword: [''],
            token: [''],
            tk: ['']
        });
        this.passwordVerifyForm = this.fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required]
        });
        this.status = UIControlStatuses.initial;
        this.steps = {
            validateVerificationToken: 1,
            passwordMatch: 2,
            passwordUpdateSucccess: 3,
            serverError: 4,
            validateVerificationFailed: 5,
            validateVerificationFailedNoResendOption: 6,
            resendVerificationEmail: 7,
        };
        // First parameter is the number of steps in this process.
        // Irrelevant as we are not doing nextStep() nor are we displaying the progress value.
        // We are just invoking goToStep() to go to the correct step.
        this.progressSteps = new ProgressSteps(1, this.store);
        this.resetType = {};
        this.passwordResetTypes = {
            // Reset is when the user has lost their password and wish to reset it.
            resetPassword: 'reset',
            // Complete is when the user has just signed up and wish to complete their rego.
            completeRegistration: 'complete',
            // Is when they wish to change their password (or forced to change) whilst signed in.
            updatePassword: 'update'
        };
        this.uiStatuses = UIControlStatuses;
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (params) {
            // Set values for all possible query string parameters that arrive on this page.
            var token = params['token'];
            var code = params['code'];
            var tk = params['tk'];
            _this.authToken = window.sessionStorage.getItem(AuthTokenName);
            _this.passwordMatchForm.controls.token.setValue(token);
            _this.passwordMatchForm.controls.code.setValue(code);
            _this.passwordMatchForm.controls.tk.setValue(tk);
            _this.passwordResetType = params['type'];
            // We are not starting from the first step.
            _this.progressSteps.goToStep(_this.steps.passwordMatch);
            switch (_this.passwordResetType) {
                case _this.passwordResetTypes.resetPassword:
                    _this.message = 'pmtResetPassword';
                    break;
                case _this.passwordResetTypes.completeRegistration:
                    _this.message = 'pmtCompleteRegistration';
                    _this.progressSteps.goToStep(_this.steps.validateVerificationToken);
                    _this.validateVerificationToken();
                    break;
                case _this.passwordResetTypes.updatePassword:
                    _this.message = 'pmtResetPassword';
                    break;
                default:
                    // Default messages to 'Reset Password' and the reset type to update password
                    // which affects the submit behaviour.
                    _this.message = 'pmtResetPassword';
                    _this.passwordResetType = _this.passwordResetTypes.resetPassword;
                    break;
            }
            if (!token && !code && !tk) {
                if (!_this.authToken && _this.passwordResetType == _this.passwordResetTypes.updatePassword) {
                    // If there is no auth token then the user isn't logged in so we can't update their password.
                    _this.router.navigate(['/login']);
                }
                else {
                    // If we don't have any values for token/code/tk then the user has accidently redirected here.
                    // They will automatically be redirected back to home.
                    _this.router.navigate(['/portal/home']);
                }
            }
        });
    };
    ResetPasswordComponent.prototype.validateVerificationToken = function () {
        var _this = this;
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.verficationTokenService
            .validate({ token: this.passwordMatchForm.controls.token.value })
            .subscribe(function (data) { return _this.toggleSuccess(_this.steps.passwordMatch); }, function (err) { return _this.toggleValidateVerificationError(err); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
    };
    ResetPasswordComponent.prototype.resendVerificationEmail = function () {
        var _this = this;
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.verficationTokenService
            .resendVerificationEmail({ token: this.passwordMatchForm.controls.token.value })
            .subscribe(function (data) { return _this.toggleSuccess(_this.steps.resendVerificationEmail); }, function (err) { return _this.toggleValidateVerificationError(err); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
    };
    ResetPasswordComponent.prototype.submitPasswordChange = function () {
        var _this = this;
        if (this.passwordMatchForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            if (this.passwordResetType == this.passwordResetTypes.completeRegistration) {
                this.passwordService
                    .completeRegistration(this.passwordMatchForm.value)
                    .subscribe(function (data) { return _this.toggleSuccess(); }, function (err) { return _this.toggleError(err); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
            }
            else if (this.passwordResetType == this.passwordResetTypes.resetPassword) {
                if (this.passwordMatchForm.controls.tk.value) {
                    // If tk has a value then update via hapi endpoints.
                    this.passwordService.updatePasswordHapi(this.passwordMatchForm.value).subscribe(function (data) { return _this.toggleSuccess(); }, 
                    // Send error token message on Hapi error.
                    function (err) { return _this.toggleError({ error: { status: '[0003]' } }); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
                }
                else {
                    // Otherwise, update password via marlin api.
                    this.passwordService
                        .updatePassword(this.passwordMatchForm.value)
                        .subscribe(function (data) { return _this.toggleSuccess(); }, function (err) { return _this.toggleError(err); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
                }
            }
            else if (this.passwordResetType == this.passwordResetTypes.updatePassword) {
                this.passwordMatchForm.controls.token.setValue(this.authToken);
                this.passwordService.updatePasswordLoggedInUser(this.passwordMatchForm.value).subscribe(function (data) {
                    // Reset the user data to so that tags are updated.
                    _this.store.dispatch(new userActions.ResetUserData());
                    _this.store.dispatch(new userActions.GetUser(_this.authToken.toString()));
                    _this.toggleSuccess();
                }, function (err) { return _this.toggleError(err); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
            }
        }
        else {
            markAllTouched(this.passwordMatchForm);
        }
    };
    ResetPasswordComponent.prototype.toggleError = function (err) {
        var errorMessage = '';
        if (err.error.status.indexOf('0003') != -1 || err.error.status.indexOf('0017') != -1) {
            errorMessage = getTranslatedErrorMessageFromResponse('[customResetPasswordError]');
        }
        else {
            errorMessage = getTranslatedErrorMessageFromResponse(err.error.status);
        }
        this.progressSteps.goToStep(this.steps.serverError);
        this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        // Reset password values.
        this.passwordMatchForm.controls.password.setValue('');
        this.passwordMatchForm.controls.confirmPassword.setValue('');
        this.passwordMatchForm.controls.oldPassword.setValue('');
    };
    ResetPasswordComponent.prototype.toggleValidateVerificationError = function (err) {
        console.log("toggleValidateVerificationError " + err);
        var errorMessage = '';
        if (err.error.status.indexOf('0027') != -1 ||
            err.error.status.indexOf('0028') != -1 ||
            err.error.status.indexOf('0029') != -1) {
            // Further refinement of error message is required.
            errorMessage = "";
            this.progressSteps.goToStep(this.steps.validateVerificationFailedNoResendOption);
        }
        else if (err.error.status.indexOf('0026') != -1) {
            errorMessage = "";
            this.progressSteps.goToStep(this.steps.validateVerificationFailed);
        }
        else {
            errorMessage = getTranslatedErrorMessageFromResponse(err.error.status);
            this.progressSteps.goToStep(this.steps.validateVerificationFailedNoResendOption);
        }
        this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
    };
    ResetPasswordComponent.prototype.toggleSuccess = function (nextStep) {
        if (nextStep === void 0) { nextStep = this.steps.passwordUpdateSucccess; }
        this.progressSteps.goToStep(nextStep);
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
    };
    ResetPasswordComponent.prototype.resetPage = function () {
        this.progressSteps.goToStep(this.steps.validateVerificationToken);
        this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
