/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../core/pipes/translate.pipe";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "./delete.component";
import * as i7 from "../../../services/common/support.service";
var styles_DeleteComponent = [i0.styles];
var RenderType_DeleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteComponent, data: {} });
export { RenderType_DeleteComponent as RenderType_DeleteComponent };
function View_DeleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "form-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete your Account."])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please let us know the reason you would like to delete your account."])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "textarea", [["placeholder", "eg: No longer attend Hillsong Church"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary w-100"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete My Account"]))], null, null); }
function View_DeleteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "form-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Account has been deleted"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-primary w-100"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), i1.ɵpid(0, i3.TranslatePipe, [i4.Store])], function (_ck, _v) { var currVal_1 = _ck(_v, 7, 0, "/login"); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 8).transform("gblReturnToLogin")); _ck(_v, 5, 0, currVal_0); }); }
export function View_DeleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeleteComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeleteComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDeleted; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isDeleted; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DeleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "marlin-delete", [], null, null, null, View_DeleteComponent_0, RenderType_DeleteComponent)), i1.ɵdid(1, 114688, null, 0, i6.DeleteComponent, [i2.Router, i7.SupportService, i4.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteComponentNgFactory = i1.ɵccf("marlin-delete", i6.DeleteComponent, View_DeleteComponent_Host_0, {}, {}, []);
export { DeleteComponentNgFactory as DeleteComponentNgFactory };
