import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as userActions from '~store/actions/user.actions';
import { AuthTokenName } from '~constants/app-config';
import { AppState } from '~app/store/app-state';
import * as appDataActions from '~store/actions/app-data.actions';
import { SupportService } from '~app/services/common/support.service';

@Component({
    selector: 'marlin-delete',
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {
    constructor(
        private router: Router,
        private supportService: SupportService,
        private store: Store<AppState>) {}
    isDeleted = false;
    userData = null;
    ngOnInit() {
        this.store.select(state => state.user).subscribe(data => {
            this.userData = data;
        });
    }
    confirmDelete() {
        this.supportService.support({
            name: this.userData.firstName + " " + this.userData.familyName,
            email: "myhillsong@hillsong.com",
            phone: "",
            supportType: "Delete Account Request",
            issueDescription: this.userData.mobilePhone + " wants their account deleted."
        }).subscribe(
            data => {
                this.confirmDeleteSuccess();
            },
            err => {
                // Todo: For an unknown reason, on local a success behaves as predicted,
                // but on live a success goes to this error handler.
                if (err.status == 200) {
                    this.confirmDeleteSuccess();
                } else {
                    // TODO graceful error handling
                }
            }
        );
    }

    confirmDeleteSuccess() {
        this.isDeleted = true;
        this.store.dispatch(new userActions.ResetUserData());
        window.sessionStorage.removeItem(AuthTokenName);
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
        this.isDeleted = true;
    }
}
