import { Observable } from 'rxjs/Observable';
import { Injectable, Inject } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { UserValidateVerificationTokenRequest, UserResendVerificationEmailRequest } from '~app/models/user';
import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';

@Injectable()
export class VerificationTokenService {
    private _Validate_Verfication_Token_Endpoint = '/ValidateVerificationToken';
    private _Resend_Verification_Email_Endpoint = '/ResendVerificatonEmailRequest';
    private _Password_Reset_Resend_Verification_Email_Endpoint = '/PasswordResetResendVerificatonEmail';

    constructor(private httpClient: HttpClient) {}

    validate(data: UserValidateVerificationTokenRequest) {
        const headers = new HttpHeaders({
            authorization: data.token
        });
        const body = {
            data: ''
        };
        // Throws on HTTP-4XX. Service subscribers should catch and handle error case.
        return this.httpClient
            .post(environment.marlinApiUrl + this._Validate_Verfication_Token_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    }

    resendVerificationEmail(data: UserResendVerificationEmailRequest) {
        const headers = new HttpHeaders({
            authorization: data.token
        });
        const body = {
            data: ''
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Resend_Verification_Email_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    }

    passwordResetResendVerificationEmail(email): Observable<{}> {
        const body = {
            loginEmail: email
        };

        return this.httpClient
            .post(environment.marlinApiUrl + this._Password_Reset_Resend_Verification_Email_Endpoint, body)
            .catch(ErrorHandler);
    }
}
