import { CookieOptions } from 'ngx-cookie';
import * as moment from 'moment';

export const App_Id = 'marlin';
export const Default_Language = 'en-au';

const CookieExpiryInHours = moment()
    .add(24, 'hours')
    .toISOString();

export const MarlinCookieOptions: CookieOptions = { secure: true, expires: new Date(CookieExpiryInHours) };

export const SupportTypes = [
    {
        text: 'gblGeneralEnquiries',
        type: 'General Enquiries',
        value: 98
    },
    {
        text: 'gblPasswordReset',
        type: 'Password Reset',
        value: 99
    },
    {
        text: 'gblAccountRegistration',
        type: 'Account Registration',
        value: 97
    }
];

export const AuthTokenName = 'marlin-auth';
export const TranslationsMd5 = 'marlin-translations-md5';
export const CookieLanguageName = 'marlin-language';

export const MapiPublicKey = {
    // tslint:disable-next-line
    sandbox:
        // tslint:disable-next-line:max-line-length
        '-----BEGIN PUBLIC KEY-----\r' +
        'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAifM1ScBCNYyfdN+wiBnR\r' +
        'YbkAloINxWj1qNet/8RuHb3bc5xiGIlxasE3NcvgTjLwadyXm0xzFVtW7Hz5916k\r' +
        't78pzmFCN+++YiCZoG8w9/yZodc3C+f5ZqYBSU8TLAN2OvTyWEhlewDlOYTtiiWN\r' +
        'HZ++3yX1E8CF+mXfbXiSscl8/12MT8BWZHeSzrU9Fq9valgm2SqfKaXmw5jNfZDN\r' +
        'lNSCC1x6a6ZTbgMmrEDe1sTrb+1qCWZPOnw70vJpdj2eH90GOYNoGTjjZ071ttOG\r' +
        'JqGAMYEXI/ysxu6b8IQ4I7yuEVwWKbRIIXOQukjZRfvw0wtL+K0g21dgebNw1fIH\r' +
        'Z7ZtmCFIXuqWf/DRHevbhS3spBKas7rgwXf/3+Yl18SJsIWm+FYK3wxMJe4xbCdB\r' +
        'W/og6If+Ay6jyVHpq+q0d9xx1GWGsLXgWRcQ+cVuYqeEzE4OluqWhT3ui9Mky/NM\r' +
        'nO0I/uq3Zhc7kXZoyWAdyJnHoWVwsjq+bq3c8LmnTDbCNJCrJWhnCujnzt35Gfow\r' +
        'vTthjHQIaj9j8UEYfo3TUS9KD1W5XJ04h3knKy6gYaMTmEam3HTizXnfoHcBT9XV\r' +
        'L63Tv6PabPHysWwSt6ruBd1FmARrhLdbuR9utJbxKRqQ22AtYoHTmFEfjZ/UTDvy\r' +
        'qZJqPkW2GxtvfuxogQlxn3MCAwEAAQ==\r' +
        '-----END PUBLIC KEY-----',
    // tslint:disable-next-line
    staging:
        // tslint:disable-next-line:max-line-length
        '-----BEGIN PUBLIC KEY-----\r' +
        'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAgDs3k27wme7FYaYlKptE\r' +
        '+di6kJiG7xCGWcTK40IGn8Vb+CnxdnyrKyL06IxUMV0Z11Hez5n5gna69OdAe3H1\r' +
        'Si1HJtdCIstaLAkifQjJKgTO8O4KXGpskI9/SRb5lxy61KAd3Utavrg9ufbCYr2j\r' +
        'cZx+sbWNasPtppuVPp6litswvx+amyhV2DFeAAtwnoeqLE4LAcsoE/sJWJmpqTYP\r' +
        'lkyXi+XN1AERWsbh5bur9ORS3MtBDZzbMiz1oK52fYh4wvRGlFwxyaZWNvCF8BF3\r' +
        'TiPPrcFYGEq31MfXI7abBshB8MOWwdJtegE3pngP7RBQ+j5QO0gle2LR+ZV7U8pR\r' +
        'PgSHrCi3HvEKQBxZ184Ul9Nnpc6aYv+TIUdiKtRrW49MR7AcU667w/axIJ8b7scd\r' +
        'wb5GdEoavK2P4EyToyWToDuQAkEmd5c3IUbgLYwJ4yo88JUltaut/6hFRR7cqb6B\r' +
        'wJDkmzpsQLVJWX0wiioi2DCsbrv6ZGN/Wo8571I5cte/OI8SKzurmHe7Bk37WfzV\r' +
        'CUANdsJ6vPtl26/bbj+llQ2ALHUti0XqIqYEf5OOO5D/hvsi061czBnhg5H7NtX7\r' +
        'KRyDY11mqI/luJ7/TWeOdHBl1gG54uCTFvR9/Nr5DdQuraEYQBA1/mD5laKtj77R\r' +
        '6txCiYQGLH3Gfb3beuL7OO0CAwEAAQ==\r' +
        '-----END PUBLIC KEY-----',
    // tslint:disable-next-line
    production:
        // tslint:disable-next-line:max-line-length
        '-----BEGIN PUBLIC KEY-----\r' +
        'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAoxUX+pkr0DhIKcwwgb2P\r' +
        'svzpN8vACU/9KhD8iDZ/e+lC51TZ24W1nvR+tRz0mxI3Fl7DrD7uSDD1o6MdrIxr\r' +
        'FthoYXvrpNxzs8amI8THPTzgo6M3R86SdFbD2uOy1z+xygS6SL3YuVgfwuKVzYEc\r' +
        'BOBQOJQnOckLANhpyHrSOusm1zXvCM8ZEaGoNk//iI3P5TXHSZlCZaJhzHJUxYWJ\r' +
        '4ZxUdQ36ZxNIZYOS7VJmk2YC5cNKrs9gkRORD/9cVViR5Zko1sIXVEUY6CdeB1zA\r' +
        'N01Zn/IxVG6/WFSDR4oWMtnCntYine0PXi8E/brSBqHGvsgzhU7mzysJWwSclMN8\r' +
        'Jlrn/jGvQdzElMqTbum9/DQBrls7M0b0E703vih/16pWWiZQcCWPl6lA/SOU7wAM\r' +
        'g3g+j7fg55hv8VcWt3//ZLw4VN1w8UZDVN02Lp48CshF/tTBxGQ+2OzqPc6IVW71\r' +
        'MULZijaLQvYLhk3bcQtV1fbSVMAlEB5cewK1Neix5BsTEz+RrszRv89URM2okmvY\r' +
        'dRdv/Jn+C5ESbXHsUREZBO8LzQLO/gmq+Q+QaIN5+RJKS17O80LTzM1Yxu4DWNvp\r' +
        '4sbqCfhg7iHjcePL4zTcRk7gYF6XRb1ItqY0xVORhuIly4WNlcikaKS8bD5l3ayH\r' +
        'ocNkFmrNFO/4WLDjflVIIkUCAwEAAQ==\r' +
        '-----END PUBLIC KEY-----'
};

export const RaygunAPIKey = {
    development: 'WslhQeDmGN3ljI3ZX51yIQ==',
    production: 'ln2TR89i/jByr0LBoiOH2g=='
};
