import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordService } from '~services/auth/password.service';
import { VerificationTokenService } from '~services/auth/verification_token.service';
import { UIControlStatuses } from '~constants/ui';
import { MarlinSlideInOut } from '~core/animations';
import { ProgressSteps } from '~logic/ui/progress-steps';
import { SuccessMessages } from '~services/auth/user.service';
import { ServiceResponse } from '~app/models/http-response';
import { AppState } from '~store/app-state';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userActions from '~store/actions/user.actions';
import { markAllTouched } from '~logic/helpers/form';
import { AuthTokenName } from '~app/constants/app-config';
import { getTranslatedErrorMessageFromResponse } from '~app/logical-objects/helpers/system';

@Component({
    selector: 'marlin-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    authToken: string;
    message = 'pmtResetPassword';
    passwordMatchForm = this.fb.group({
        code: [''],
        urlEncoded: [false],
        password: [''],
        confirmPassword: [''],
        oldPassword: [''],
        token: [''],
        tk: ['']
    });
    passwordVerifyForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required]
    });
    status = UIControlStatuses.initial;
    steps = {
        validateVerificationToken: 1,
        passwordMatch: 2,
        passwordUpdateSucccess: 3,
        serverError: 4,
        validateVerificationFailed: 5,
        validateVerificationFailedNoResendOption: 6,
        resendVerificationEmail: 7,
    };
    // First parameter is the number of steps in this process.
    // Irrelevant as we are not doing nextStep() nor are we displaying the progress value.
    // We are just invoking goToStep() to go to the correct step.
    progressSteps = new ProgressSteps(1, this.store);
    resetType = {};
    passwordResetType: string;
    passwordResetTypes = {
        // Reset is when the user has lost their password and wish to reset it.
        resetPassword: 'reset',
        // Complete is when the user has just signed up and wish to complete their rego.
        completeRegistration: 'complete',
        // Is when they wish to change their password (or forced to change) whilst signed in.
        updatePassword: 'update'
    };
    uiStatuses = UIControlStatuses;

    constructor(
        private fb: FormBuilder,
        private passwordService: PasswordService,
        private verficationTokenService: VerificationTokenService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            // Set values for all possible query string parameters that arrive on this page.
            const token = params['token'];
            const code = params['code'];
            const tk = params['tk'];
            this.authToken = window.sessionStorage.getItem(AuthTokenName);

            this.passwordMatchForm.controls.token.setValue(token);
            this.passwordMatchForm.controls.code.setValue(code);
            this.passwordMatchForm.controls.tk.setValue(tk);
            this.passwordResetType = params['type'];

            // We are not starting from the first step.
            this.progressSteps.goToStep(this.steps.passwordMatch);

            switch (this.passwordResetType) {
                case this.passwordResetTypes.resetPassword:
                    this.message = 'pmtResetPassword';
                    break;
                case this.passwordResetTypes.completeRegistration:
                    this.message = 'pmtCompleteRegistration';
                    this.progressSteps.goToStep(this.steps.validateVerificationToken);
                    this.validateVerificationToken();
                    break;
                case this.passwordResetTypes.updatePassword:
                    this.message = 'pmtResetPassword';
                    break;
                default:
                    // Default messages to 'Reset Password' and the reset type to update password
                    // which affects the submit behaviour.
                    this.message = 'pmtResetPassword';
                    this.passwordResetType = this.passwordResetTypes.resetPassword;
                    break;
            }
            if (!token && !code && !tk) {
                if (!this.authToken && this.passwordResetType == this.passwordResetTypes.updatePassword) {
                    // If there is no auth token then the user isn't logged in so we can't update their password.
                    this.router.navigate(['/login']);
                } else {
                    // If we don't have any values for token/code/tk then the user has accidently redirected here.
                    // They will automatically be redirected back to home.
                    this.router.navigate(['/portal/home']);
                }
            }
        });
    }

    validateVerificationToken() {
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.verficationTokenService
            .validate({token: this.passwordMatchForm.controls.token.value})
            .subscribe(
                data => this.toggleSuccess(this.steps.passwordMatch),
                err => this.toggleValidateVerificationError(err),
                () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
            );
    }

    resendVerificationEmail() {
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.verficationTokenService
            .resendVerificationEmail({token: this.passwordMatchForm.controls.token.value})
            .subscribe(
                data => this.toggleSuccess(this.steps.resendVerificationEmail),
                err => this.toggleValidateVerificationError(err),
                () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
            );
    }

    submitPasswordChange() {
        if (this.passwordMatchForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            if (this.passwordResetType == this.passwordResetTypes.completeRegistration) {
                this.passwordService
                    .completeRegistration(this.passwordMatchForm.value)
                    .subscribe(
                        data => this.toggleSuccess(),
                        err => this.toggleError(err),
                        () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
                    );
            } else if (this.passwordResetType == this.passwordResetTypes.resetPassword) {
                if (this.passwordMatchForm.controls.tk.value) {
                    // If tk has a value then update via hapi endpoints.
                    this.passwordService.updatePasswordHapi(this.passwordMatchForm.value).subscribe(
                        data => this.toggleSuccess(),
                        // Send error token message on Hapi error.
                        err => this.toggleError({ error: { status: '[0003]' } }),
                        () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
                    );
                } else {
                    // Otherwise, update password via marlin api.
                    this.passwordService
                        .updatePassword(this.passwordMatchForm.value)
                        .subscribe(
                            data => this.toggleSuccess(),
                            err => this.toggleError(err),
                            () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
                        );
                }
            } else if (this.passwordResetType == this.passwordResetTypes.updatePassword) {
                this.passwordMatchForm.controls.token.setValue(this.authToken);
                this.passwordService.updatePasswordLoggedInUser(this.passwordMatchForm.value).subscribe(
                    data => {
                        // Reset the user data to so that tags are updated.
                        this.store.dispatch(new userActions.ResetUserData());
                        this.store.dispatch(new userActions.GetUser(this.authToken.toString()));
                        this.toggleSuccess();
                    },
                    err => this.toggleError(err),
                    () => this.store.dispatch(new appDataActions.ToggleIsLoading(false))
                );
            }
        } else {
            markAllTouched(this.passwordMatchForm);
        }
    }

    toggleError(err) {
        let errorMessage = '';
        if (err.error.status.indexOf('0003') != -1 || err.error.status.indexOf('0017') != -1) {
            errorMessage = getTranslatedErrorMessageFromResponse('[customResetPasswordError]');
        } else {
            errorMessage = getTranslatedErrorMessageFromResponse(err.error.status);
        }
        this.progressSteps.goToStep(this.steps.serverError);
        this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        // Reset password values.
        this.passwordMatchForm.controls.password.setValue('');
        this.passwordMatchForm.controls.confirmPassword.setValue('');
        this.passwordMatchForm.controls.oldPassword.setValue('');
    }

    toggleValidateVerificationError(err) {
        console.log(`toggleValidateVerificationError ${err}`);
        let errorMessage = '';
        if (
            err.error.status.indexOf('0027') != -1 ||
            err.error.status.indexOf('0028') != -1 ||
            err.error.status.indexOf('0029') != -1
        ) {
            // Further refinement of error message is required.
            errorMessage = "";
            this.progressSteps.goToStep(this.steps.validateVerificationFailedNoResendOption);
        } else if (err.error.status.indexOf('0026') != -1) {
            errorMessage = "";
            this.progressSteps.goToStep(this.steps.validateVerificationFailed);
        } else {
            errorMessage = getTranslatedErrorMessageFromResponse(err.error.status);
            this.progressSteps.goToStep(this.steps.validateVerificationFailedNoResendOption);
        }
        this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
    }

    toggleSuccess(nextStep: number = this.steps.passwordUpdateSucccess) {
        this.progressSteps.goToStep(nextStep);
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
    }

    resetPage() {
        this.progressSteps.goToStep(this.steps.validateVerificationToken);
        this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
    }
}
