import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '~pipes/translate.pipe';

import {
    FormControl,
    AbstractControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    ControlContainer
} from '@angular/forms';
import { Component, OnInit, Input, Output, forwardRef, ViewChild, Optional, Host, SkipSelf } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '~store/app-state';
@Component({
    selector: 'marlin-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true
        }
    ]
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
    @ViewChild('input') input;
    control: AbstractControl = new FormControl();
    @Input() formControlName: string;
    @Input() data = [];
    @Input() title = '';
    @Input() class = 'form-control w-100';
    @Input() type = '';
    @Input() toolTip = '';
    @Input() toolTipConfig: NgbTooltipConfig;
    @Input() errorMessage = '';
    label: string;
    selectedValue: string;

    onChange = (val: FormControl) => {};
    onTouched = () => {};

    constructor(
        private store: Store<AppState>,
        config: NgbTooltipConfig,
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer
    ) {
        if (!this.toolTipConfig) {
            config.placement = 'right';
            config.triggers = 'click';
        } else {
            config = this.toolTipConfig;
        }
    }

    ngOnInit() {
        this.control = this.controlContainer.control.get(this.formControlName);
    }

    writeValue(value: string): void {
        if (value) {
            this.selectedValue = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}
