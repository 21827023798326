import { AuthenticatedModule } from '~authenticated/authenticated.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SupportComponent } from '~public/support/support.component';
import { RegisterComponent } from '~public/register/register.component';
import { LoginComponent } from '~public/login/login.component';
import { GlobalLoginComponent } from '~public/global-login/global-login.component';
import { HomeComponent } from '~authenticated/home/home.component';
import { ResetPasswordComponent } from '~public/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '~public/forgot-password/forgot-password.component';
import { AcceptPolicyComponent } from '~public/accept-policy/accept-policy.component';

import { UserStateGuard } from '~app/guards/user-state.guard';
import { AuthenticationGuard } from '~guards/authentication.guard';
import { YoungComponent } from '~app/components/public/young/young.component';

const routes: Routes = [
    {
        path: 'portal',
        // Replaced by update_loadChildren.js when deployed to live/staging/sandbox.
        loadChildren: '~authenticated/authenticated.module#AuthenticatedModule'
    },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'support', component: SupportComponent },
    { path: 'global-login', component: GlobalLoginComponent },
    { path: 'policy', component: AcceptPolicyComponent, canActivate: [UserStateGuard] },
    { path: 'young', component: YoungComponent },
    { path: '**', redirectTo: '/login', pathMatch: 'full' },
    { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthenticationGuard, UserStateGuard]
})
export class AppRoutingModule {}
