import { VERSION } from '~environments/version';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import * as rg4js from 'raygun4js';

export function raygunNavigationTracking(router: Router) {
    return router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            // Track navigation end
            rg4js('trackEvent', {
                type: 'pageView',
                path: event.url
            });
        } else if (event instanceof NavigationError) {
            // Track navigation error
            rg4js('send', {
                error: JSON.stringify(event, ['message', 'arguments', 'type', 'name']),
                version: JSON.stringify(VERSION)
            });
        }
    });
}
