import { UserLogin } from '~models/user';
import { ActionReducer, Action } from '@ngrx/store';
import * as UserActions from '~actions/user.actions';
import { UserStatuses } from '~app/constants/user';

export function userReducer(state = [], action: UserActions.All) {
    switch (action.type) {
        case UserActions.RESET_USER_DATA:
        case UserActions.GET_USER:
            return Object.assign({}, {});
        case UserActions.GET_USER_SUCCESS:
            return Object.assign({}, state, action.payload, { isConnectionZoneUser: true });
        case UserActions.GET_USER_FAILED:
            return Object.assign({}, state, { status: UserStatuses.failed, code: action.payload });
        default:
            return state;
    }
}
