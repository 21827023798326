import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '~services/auth/user.service';
import { markAllTouched } from '~logic/helpers/form';
import { AuthTokenName } from '~constants/app-config';
import * as appDataActions from '~store/actions/app-data.actions';
import * as userActions from '~store/actions/user.actions';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import { environment } from '~environments/environment';
import { UserMetaData } from '~app/constants/bit-data';
import { validateEmail } from '~app/core/validators';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(fb, userService, router, store, activatedRoute, cookieService) {
        this.fb = fb;
        this.userService = userService;
        this.router = router;
        this.store = store;
        this.activatedRoute = activatedRoute;
        this.cookieService = cookieService;
        this.errorMessage = '';
        this.policiesUrl = environment.myhillsongUrl + "/Policies.aspx";
        this.rememberCookie = 'marlinEmail';
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        window.sessionStorage.removeItem(AuthTokenName);
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                redirect: null
            },
            queryParamsHandling: 'merge'
        });
        this.buildForm();
        var emailCookie = this.cookieService.get(this.rememberCookie);
        if (emailCookie) {
            this.loginForm.setValue({ loginEmail: emailCookie, password: null, isRemember: true });
        }
        this.store.select(function (state) { return state.user.metadata; }).subscribe(function (data) {
            if (!!(data & UserMetaData.isGlobalUser)) {
                _this.isGlobalUser = !!(data & UserMetaData.isGlobalUser);
                _this.store.dispatch(new appDataActions.ToggleIsLoggedIn(false));
                _this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                _this.store.dispatch(new appDataActions.ToggleIsLoading(false));
                _this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
            }
        });
    };
    LoginComponent.prototype.buildForm = function () {
        this.loginForm = this.fb.group({
            loginEmail: ['', [Validators.required, validateEmail]],
            password: ['', Validators.required],
            isRemember: ['']
        });
    };
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.loginForm.get('isRemember').value) {
            this.cookieService.put(this.rememberCookie, this.loginForm.get('loginEmail').value);
        }
        else {
            this.cookieService.remove(this.rememberCookie);
        }
        if (this.loginForm.invalid) {
            markAllTouched(this.loginForm);
            return;
        }
        this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
        if (this.loginForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.userService.authenticate(this.loginForm.value).subscribe(function (user) {
                window.sessionStorage.setItem(AuthTokenName, user.token);
                // Get user details now that they have successfully logged in.
                _this.store.dispatch(new userActions.GetUser(user.token));
            }, function (err) { return handleMapiError(_this.store, err.error.status); });
        }
        else {
            markAllTouched(this.loginForm);
        }
    };
    LoginComponent.prototype.resetUserData = function () {
        this.store.dispatch(new userActions.ResetUserData());
        window.sessionStorage.removeItem(AuthTokenName);
        this.buildForm();
        // Set user is loaded, to avoid loading forever loop.
        this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
    };
    return LoginComponent;
}());
export { LoginComponent };
