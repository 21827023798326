import { Store } from '@ngrx/store';
import { markAllTouched } from '~app/logical-objects/helpers/form';
import { FormBuilder, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import { UIControlStatuses } from '~constants/ui';
import { PasswordService } from '~services/auth/password.service';
import { VerificationTokenService } from '~services/auth/verification_token.service';
import * as appDataActions from '~store/actions/app-data.actions';
import { getTranslatedErrorMessageFromResponse } from '~app/logical-objects/helpers/system';
import { validateEmail } from '~app/core/validators';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(fb, store, passwordService, verficationTokenService) {
        this.fb = fb;
        this.store = store;
        this.passwordService = passwordService;
        this.verficationTokenService = verficationTokenService;
        this.forgotPasswordForm = this.fb.group({
            email: ['', [Validators.required, validateEmail]]
        });
        this.uiStatuses = UIControlStatuses;
        this.uiStatus = this.uiStatuses.initial;
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.isUnconfirmedCognitoUser = false;
    };
    ForgotPasswordComponent.prototype.submit = function () {
        var _this = this;
        if (this.forgotPasswordForm.valid) {
            // Reset messages and initiate loading.
            this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.passwordService.resetPassword(this.forgotPasswordForm.get('email').value).subscribe(function (data) {
                _this.uiStatus = UIControlStatuses.submitSuccess;
            }, function (err) { return _this.toggleForgotPasswordError(err); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
        }
        else {
            markAllTouched(this.forgotPasswordForm);
        }
    };
    ForgotPasswordComponent.prototype.resendVerificationEmail = function () {
        var _this = this;
        this.store.dispatch(new appDataActions.ToggleIsLoading(true));
        this.verficationTokenService
            .passwordResetResendVerificationEmail(this.forgotPasswordForm.get('email').value)
            .subscribe(function (data) {
            _this.uiStatus = UIControlStatuses.submitSuccess;
            _this.isUnconfirmedCognitoUser = false;
        }, function (err) { return _this.toggleForgotPasswordError(err); }, function () { return _this.store.dispatch(new appDataActions.ToggleIsLoading(false)); });
    };
    ForgotPasswordComponent.prototype.toggleForgotPasswordError = function (err) {
        console.log("toggleForgotPasswordError " + err);
        var errorMessage = '';
        if (err.error.status.indexOf('0007') != -1) {
            errorMessage = "";
            this.isUnconfirmedCognitoUser = true;
        }
        else {
            errorMessage = getTranslatedErrorMessageFromResponse(err.error.status);
        }
        this.store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
