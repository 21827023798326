import { fromPromise } from 'rxjs/observable/fromPromise';
import { environment } from '~environments/environment';
import { App_Id, AuthTokenName } from '~constants/app-config';
import { JsonToJWEwPEM } from '~core/encryption';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor() {
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var encryptionBody = {
            pem: environment.mapiPublicKey,
            payload: req.body
        };
        // Get the auth token from session storage if its there.
        var authToken = window.sessionStorage.getItem(AuthTokenName);
        // Set the authToken if it is already present and do not overwrite it.
        if (req.headers.get('authorization')) {
            authToken = req.headers.get('authorization');
        }
        // Skip encoding to JWE when communicating with Hapi.
        if (!req.url.startsWith(environment.marlinApiUrl)) {
            return next.handle(req);
        }
        return fromPromise(JsonToJWEwPEM(encryptionBody)).switchMap(function (token) {
            // Set authorization, content type and app id to all outgoing headers.
            var authReq = req.clone({
                headers: req.headers
                    .set('Content-Type', 'application/json; charset=utf-8')
                    .set('x-hs-appid', App_Id)
                    .set('authorization', authToken ? authToken : ''),
                body: { data: token }
            });
            return next.handle(authReq);
        });
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
