import { HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';
var SupportService = /** @class */ (function () {
    function SupportService(httpClient) {
        this.httpClient = httpClient;
        this._Support_Endpoint = '/enquiries';
    }
    SupportService.prototype.support = function (userSupportRequest) {
        var body = {
            enquiryType: userSupportRequest.supportType,
            name: userSupportRequest.name,
            emailAddress: userSupportRequest.email,
            message: userSupportRequest.issueDescription
        };
        return this.httpClient.post(environment.hapiUrl + this._Support_Endpoint, body).catch(ErrorHandler);
    };
    return SupportService;
}());
export { SupportService };
