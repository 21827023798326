/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "../../../../core/pipes/translate.pipe";
import * as i4 from "@ngrx/store";
import * as i5 from "./home-button.component";
var styles_HomeButtonComponent = [];
var RenderType_HomeButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HomeButtonComponent, data: {} });
export { RenderType_HomeButtonComponent as RenderType_HomeButtonComponent };
export function View_HomeButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "far fa-home fa-2x"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(0, i3.TranslatePipe, [i4.Store])], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/portal/home"); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("gblHomePage")); _ck(_v, 4, 0, currVal_3); }); }
export function View_HomeButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "marlin-home-button", [], null, null, null, View_HomeButtonComponent_0, RenderType_HomeButtonComponent)), i0.ɵdid(1, 49152, null, 0, i5.HomeButtonComponent, [], null, null)], null, null); }
var HomeButtonComponentNgFactory = i0.ɵccf("marlin-home-button", i5.HomeButtonComponent, View_HomeButtonComponent_Host_0, {}, {}, []);
export { HomeButtonComponentNgFactory as HomeButtonComponentNgFactory };
