import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '~app/components/authenticated/home/home.component';
import { AuthenticationGuard } from '~app/guards/authentication.guard';
import { DeleteComponent } from '~app/components/authenticated/delete/delete.component';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'delete',
        component: DeleteComponent,
        canActivate: [AuthenticationGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PortalRoutingModule {}
