import { OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import * as zxcvbn from 'zxcvbn';
import { matchValues } from '~app/core/validators';
// No whitespace at the beginning or end of the password.
var PasswordRegex = /^[\S].*[\S]$/;
var PasswordComponent = /** @class */ (function () {
    function PasswordComponent(fb) {
        this.fb = fb;
        // TODO: Rework this control to use value accessor and use the input-password.component.
        this.passwordForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(64), Validators.pattern(PasswordRegex)]],
            confirmPassword: [''],
            strength: ['']
        }, { validator: matchValues('password', 'confirmPassword') });
        this.passwordInputType = 'password';
        this.passwordInputTypes = {
            hidePassword: { value: 'eye-slash', type: 'password' },
            showPassword: { value: 'eye', type: 'text' }
        };
        this.progressColours = {
            0: 'danger',
            25: 'danger',
            50: 'warning',
            75: 'success',
            100: 'success'
        };
        this.progressColour = this.progressColours[0];
        this.showPasswordRequirments = false;
        // zxcvbn has 4 levels of strength so we need a multiplier for the progress input.
        this.strengthMultiplier = 25;
        this.onPasswordChange = new EventEmitter();
    }
    PasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.passwordForm.get('password').valueChanges.subscribe(function () {
            _this.getStrength(_this.passwordForm.get('password').value);
        });
        this.passwordForm.get('confirmPassword').valueChanges.subscribe(function () {
            _this.getStrength(_this.passwordForm.get('password').value);
        });
        // Emit on init so the parent can recieve a value to initialise.
        this.onPasswordChange.emit(this.passwordForm);
    };
    PasswordComponent.prototype.getStrength = function (password) {
        var estimation = zxcvbn(password || '');
        var score = estimation.score * this.strengthMultiplier;
        this.passwordForm.get('strength').setValue(score);
        this.progressColour = this.progressColours[score];
        this.onPasswordChange.emit(this.passwordForm);
    };
    PasswordComponent.prototype.changeInputType = function (e, remaskPassword) {
        if (remaskPassword === void 0) { remaskPassword = false; }
        if (remaskPassword || this.passwordInputType == this.passwordInputTypes.showPassword.type) {
            this.passwordInputType = this.passwordInputTypes.hidePassword.type;
            // Setting the svg icon manually because using ng class doesn't work.
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.hidePassword.value);
        }
        else {
            this.passwordInputType = this.passwordInputTypes.showPassword.type;
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.showPassword.value);
        }
    };
    return PasswordComponent;
}());
export { PasswordComponent };
