<div class="form-title">
    <h4 *ngIf="status === uiStatuses.submitSuccess">{{"gblThankyouMsg" | translate}}</h4>
    <h4 *ngIf="status === uiStatuses.submitError">{{"gblServerUnavailable" | translate}}</h4>
</div>
<form [formGroup]="supportForm" *ngIf="status === uiStatuses.initial" novalidate>
    <div class="form-title">
        <h2>{{"gblHowCanWeHelpYou" | translate}}?</h2>
    </div>
    <marlin-input [label]="'gblName'" [errorMessage]="'gblNameErrorMsg'" formControlName="name"></marlin-input>
    <marlin-input [label]="'gblEmail'" [type]="'email'" [errorMessage]="'gblEmailAddressRequiredErrorMsg'" formControlName="email"></marlin-input>
    <marlin-input [label]="'gblMobilePhone'" [type]="'tel'" [toolTip]="'gblThisWillEnableOurTeamToCall' | translate" [errorMessage]="'gblEmailAddressRequiredErrorMsg'"
        [type]="'tel'" formControlName="phone"></marlin-input>
    <marlin-dropdown (onSelect)="supportForm.controls.supportType = $event" formControlName="supportType" [data]="supportTypes"
        [title]="'gblEnquiryType'" [errorMessage]="'gblEnquiryTypeRequiredErrorMsg'"></marlin-dropdown>
    <div class="form-group">
        <label>{{'gblLeaveAMessage' | translate}}</label>
        <textarea class="form-control w-100" placeholder='{{"gblLeaveAMessage" | translate}}' formControlName="issueDescription" maxlength="2000"></textarea>
        <div class="alert alert-danger" *ngIf="supportForm.get('issueDescription').hasError('required') && supportForm.get('issueDescription').touched"
            [innerHTML]='"gblMessageErrorMsg" | translate'>
        </div>
    </div>
    <div class="form-group">
        <re-captcha formControlName="recaptcha" #captchaRef="reCaptcha" siteKey="6LerCzEUAAAAAKOi1IQFyq_0J8TT5VrcnnRAnnRD" size="invisible"
            (resolved)="submit($event)"></re-captcha>
        <button type="button" (click)="captchaRef.execute()" class="btn btn-primary w-100" [innerHTML]='"gblSend" | translate'></button>
    </div>
</form>
<div class="form-group text-center form-navigation">
    <marlin-home-button></marlin-home-button>
</div>