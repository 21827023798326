import * as appDataActions from '~store/actions/app-data.actions';
var ProgressSteps = /** @class */ (function () {
    function ProgressSteps(numberOfSteps, store) {
        this.store = store;
        this.currentStep = 1;
        this.progressValue = 0;
        this.numberOfSteps = numberOfSteps;
        this.progressStepSize = 100 / numberOfSteps;
        this.updateProgressValue();
    }
    Object.defineProperty(ProgressSteps.prototype, "isFirstStep", {
        get: function () {
            return this.currentStep === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProgressSteps.prototype, "isFinalStep", {
        get: function () {
            return this.currentStep === this.numberOfSteps;
        },
        enumerable: true,
        configurable: true
    });
    ProgressSteps.prototype.goToStep = function (step) {
        this.currentStep = step;
        this.updateProgressValue();
    };
    ProgressSteps.prototype.nextStep = function () {
        if (this.currentStep < this.numberOfSteps) {
            this.currentStep++;
            this.updateProgressValue();
        }
    };
    ProgressSteps.prototype.previousStep = function () {
        if (this.currentStep > 1) {
            this.currentStep--;
            this.updateProgressValue();
        }
    };
    ProgressSteps.prototype.updateProgressValue = function () {
        this.progressValue = this.progressStepSize * this.currentStep;
        this.store.dispatch(new appDataActions.SetAppErrorMessage(''));
    };
    return ProgressSteps;
}());
export { ProgressSteps };
