import { MetadataTypes, ExcludedChurchesNotRequiringAgeGroupGender, LimitInvolvementOptionToHillsongIsHome } from '~app/constants/church';
import { Store } from '@ngrx/store';
import { FormBuilder, Validators } from '@angular/forms';
import { OnInit } from '@angular/core';
import { UserService } from '~app/services/auth/user.service';
import * as metadataAction from '~store/actions/app-data.actions';
import { ProgressSteps } from '~logic/ui/progress-steps';
import { InitialiseChurchLocationService } from '~models/church';
import { markAllTouched, markAllUntouched, NameRegex } from '~app/logical-objects/helpers/form';
import { TranslatePipe } from '~pipes/translate.pipe';
import * as moment from 'moment';
import { validateRequiredGroup, validateFormHasValues, validateEmail, validatePhone, validateHomePhone } from '~app/core/validators';
import { environment } from '~environments/environment';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import { is16OrOver, formatDateForHapi } from '~app/logical-objects/helpers/date-time';
import { Router, ActivatedRoute } from '@angular/router';
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(userService, fb, store, activatedRoute, router) {
        this.userService = userService;
        this.fb = fb;
        this.store = store;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.ageGroups = { metaType: '', options: [] };
        this.errorMessage = '';
        this.genders = { metaType: '', options: [] };
        this.involvementLevels = {
            homeChurch: { value: 'HH', visible: true },
            newPerson: { value: 'NP', visible: true },
            connectGroup: { value: 'CG', visible: true },
            volunteer: { value: 'VOL', visible: true }
        };
        this.minDate = { year: 1900, month: 1 };
        this.maxDate = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
        this.startDate = { year: new Date().getFullYear() - 16, month: new Date().getMonth() + 1 };
        this.initialiseChurchLocationService = new InitialiseChurchLocationService();
        this.isAgeGroupGenderRequired = false;
        this.labels = {
            ageGroup: '',
            gender: ''
        };
        this.policiesUrl = environment.myhillsongUrl + "/Policies.aspx";
        this.progressSteps = new ProgressSteps(5, this.store);
        this.registrationSuccess = false;
        this.steps = {
            NameDetails: { text: 'Basic Info', value: 3 },
            signUpIntention: { text: 'Connect', value: 2 },
            churchInfo: { text: 'Church Info', value: 1 },
            contactDetails: { text: 'Contact', value: 4 },
            accountCreate: { text: 'Final', value: 5 }
        };
        this.minNameLength = 2;
    }
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.buildForm();
        this.activatedRoute.queryParams.subscribe(function (params) {
            // Set values for all possible query string parameters that arrive on this page.
            var token = params['tk'];
            _this.registerForm.controls['invitationToken'].setValue(token);
        });
        this.store.select(function (state) { return state.metadata[MetadataTypes.Gender]; }).subscribe(function (data) {
            if (data) {
                _this.genders = data;
            }
        });
        this.store.select(function (state) { return state.metadata[MetadataTypes.AgeGroup]; }).subscribe(function (data) {
            if (data) {
                _this.ageGroups = data;
                _this.ageGroups.options.sort(function (a, b) {
                    return parseFloat(b.value) - parseFloat(a.value);
                });
            }
        });
        // Set inital select dropdownvalue.
        this.labels.ageGroup = new TranslatePipe(this.store).transform('gblPleaseSelect');
        this.labels.gender = new TranslatePipe(this.store).transform('gblPleaseSelect');
        this.registerForm.get('churchLocationService').valueChanges.subscribe(function (value) {
            if (value.controls.church.value) {
                _this.churchLocationServiceChange();
            }
        });
    };
    RegisterComponent.prototype.buildForm = function () {
        this.registerForm = this.fb.group({
            loginEmail: ['', [Validators.required, validateEmail]],
            firstName: [
                '',
                [Validators.required, Validators.minLength(this.minNameLength), Validators.pattern(NameRegex)]
            ],
            familyName: [
                '',
                [Validators.required, Validators.minLength(this.minNameLength), Validators.pattern(NameRegex)]
            ],
            churchLocationService: [null, validateFormHasValues],
            // Currently not used but we need to capture this information to customise the user experience.
            homePhone: ['', validateHomePhone],
            mobilePhone: ['', validatePhone],
            dateOfBirth: ['', validateFormHasValues],
            ageGroupId: ['', Validators.required],
            genderId: ['', Validators.required],
            policyAcceptance: ['', Validators.requiredTrue],
            involvementId: ['', Validators.required],
            recaptcha: ['', Validators.required],
            invitationToken: [''],
            consents: {
                consentType1: false,
                consentType2: false,
                consentType3: false,
                consentType4: false,
                consentType5: false,
                consentType6: false
            }
        }, { validator: validateRequiredGroup(['homePhone', 'mobilePhone']) });
    };
    RegisterComponent.prototype.signUpIntentionSelected = function (intentionType) {
        this.registerForm.get('signUpIntention').setValue(intentionType);
        this.progressSteps.nextStep();
    };
    RegisterComponent.prototype.IsNameDetailsStep = function () {
        return this.progressSteps.currentStep === this.steps.NameDetails.value;
    };
    RegisterComponent.prototype.IsChurchInfoStep = function () {
        return this.progressSteps.currentStep === this.steps.churchInfo.value;
    };
    RegisterComponent.prototype.IsContactDetailsStep = function () {
        return this.progressSteps.currentStep === this.steps.contactDetails.value;
    };
    RegisterComponent.prototype.IsSignUpIntentionsStep = function () {
        return this.progressSteps.currentStep === this.steps.signUpIntention.value;
    };
    RegisterComponent.prototype.IsAccountCreatedStep = function () {
        return this.progressSteps.currentStep === this.steps.accountCreate.value;
    };
    RegisterComponent.prototype.next = function () {
        // Depending on step, check if the form is filled out correctly before allowing the user to move on.
        switch (this.progressSteps.currentStep) {
            case this.steps.NameDetails.value:
                if (this.registerForm.controls['ageGroupId'].invalid ||
                    this.registerForm.controls['genderId'].invalid ||
                    this.registerForm.controls['firstName'].invalid ||
                    this.registerForm.controls['familyName'].invalid ||
                    this.registerForm.controls['dateOfBirth'].invalid) {
                    markAllTouched(this.registerForm);
                    return;
                }
                else {
                    var dateOfBirth = moment(formatDateForHapi(this.registerForm.get('dateOfBirth').value.value));
                    if (!is16OrOver(dateOfBirth)) {
                        // under 16
                        // this.registerForm.reset();
                        this.router.navigate(['young']);
                    }
                }
                break;
            case this.steps.churchInfo.value:
                if (this.registerForm.get('churchLocationService').value.invalid) {
                    markAllTouched(this.registerForm.get('churchLocationService').value);
                    return;
                }
                break;
            case this.steps.contactDetails.value:
                if (this.registerForm.controls['loginEmail'].invalid ||
                    this.registerForm.getError('validateRequiredGroup') ||
                    this.registerForm.controls.homePhone.invalid ||
                    this.registerForm.controls.mobilePhone.invalid) {
                    markAllTouched(this.registerForm);
                    return;
                }
                break;
        }
        markAllUntouched(this.registerForm);
        if (this.progressSteps.currentStep == this.steps.accountCreate.value) {
            this.submit();
        }
        else {
            this.progressSteps.nextStep();
        }
    };
    RegisterComponent.prototype.churchLocationServiceChange = function () {
        if (this.registerForm.get('churchLocationService').value) {
            this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.Gender, this.registerForm.controls.churchLocationService.value.controls.church.value));
            this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.AgeGroup, this.registerForm.controls.churchLocationService.value.controls.church.value));
            // Configure involvement options.
            var locationId = Number(this.registerForm.get('churchLocationService').value.get('location').value);
            var showNonHillsongIsHomeButtons = LimitInvolvementOptionToHillsongIsHome.indexOf(locationId) == -1;
            var involvementControl = this.registerForm.get('involvementId');
            this.involvementLevels.connectGroup.visible = showNonHillsongIsHomeButtons;
            this.involvementLevels.newPerson.visible = showNonHillsongIsHomeButtons;
            this.involvementLevels.volunteer.visible = showNonHillsongIsHomeButtons;
            involvementControl.updateValueAndValidity();
            // Configure age group & gender options.
            var churchId = Number(this.registerForm.get('churchLocationService').value.get('church').value);
            this.isAgeGroupGenderRequired =
                ExcludedChurchesNotRequiringAgeGroupGender.indexOf(churchId) == -1;
            //// Check if it is an excluded church then don't make gender and age group required.
            var genderControl = this.registerForm.get('genderId');
            var ageGroupControl = this.registerForm.get('ageGroupId');
            if (this.isAgeGroupGenderRequired) {
                genderControl.setValidators(Validators.required);
                ageGroupControl.setValidators(Validators.required);
            }
            else {
                genderControl.clearValidators();
                ageGroupControl.clearValidators();
            }
            genderControl.updateValueAndValidity();
            ageGroupControl.updateValueAndValidity();
        }
    };
    RegisterComponent.prototype.submit = function () {
        var _this = this;
        if (this.registerForm.valid) {
            this.store.dispatch(new metadataAction.ToggleIsLoading(true));
            this.userService.registerUser(this.registerForm.value).subscribe(function (data) {
                _this.registrationSuccess = true;
            }, function (err) { return handleMapiError(_this.store, err.error.status); }, function () {
                _this.store.dispatch(new metadataAction.ToggleIsLoading(false));
            });
        }
        else {
            markAllTouched(this.registerForm);
        }
    };
    RegisterComponent.prototype.markAllUntouched = function () {
        markAllUntouched(this.registerForm);
        this.errorMessage = '';
    };
    return RegisterComponent;
}());
export { RegisterComponent };
