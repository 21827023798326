import { Routes } from '@angular/router';
import { HomeComponent } from '~app/components/authenticated/home/home.component';
import { AuthenticationGuard } from '~app/guards/authentication.guard';
import { DeleteComponent } from '~app/components/authenticated/delete/delete.component';
var routes = [
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthenticationGuard]
    },
    {
        path: 'delete',
        component: DeleteComponent,
        canActivate: [AuthenticationGuard]
    }
];
var PortalRoutingModule = /** @class */ (function () {
    function PortalRoutingModule() {
    }
    return PortalRoutingModule;
}());
export { PortalRoutingModule };
