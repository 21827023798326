import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '~environments/environment';
import { ErrorHandler } from '~app/services/service-handlers';
var PasswordService = /** @class */ (function () {
    function PasswordService(httpClient) {
        this.httpClient = httpClient;
        this._Change_Password_Endpoint = '/passwordchange';
        this._Reset_Password_Endpoint = '/passwordreset';
        this._Change_Password_Hapi_Endpoint = '/UserDetails/UpdatePassword';
    }
    PasswordService.prototype.resetPassword = function (email) {
        var body = {
            loginEmail: email
        };
        return this.httpClient.post(environment.marlinApiUrl + this._Reset_Password_Endpoint, body).catch(ErrorHandler);
    };
    PasswordService.prototype.updatePassword = function (data) {
        var headers = new HttpHeaders({
            authorization: data.token
        });
        var body = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            code: data.code,
            resetPassword: true
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    };
    PasswordService.prototype.updatePasswordLoggedInUser = function (data) {
        var headers = new HttpHeaders({
            authorization: data.token
        });
        var body = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            oldPassword: data.oldPassword
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    };
    PasswordService.prototype.updatePasswordHapi = function (data) {
        var body = {
            passwordToken: data.tk,
            newPassword: data.password
        };
        return this.httpClient
            .post(environment.hapiUrl + this._Change_Password_Hapi_Endpoint, body)
            .catch(ErrorHandler);
    };
    PasswordService.prototype.completeRegistration = function (data) {
        var headers = new HttpHeaders({
            authorization: data.token
        });
        var body = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            code: data.code,
            urlEncoded: data.urlEncoded
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body, { headers: headers })
            .catch(ErrorHandler);
    };
    PasswordService.prototype.verifyPasswordDetails = function (password, token) {
        var body = {
            password: password,
            token: token
        };
        return this.httpClient
            .post(environment.marlinApiUrl + this._Change_Password_Endpoint, body)
            .catch(ErrorHandler);
    };
    return PasswordService;
}());
export { PasswordService };
