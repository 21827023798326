/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./young.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../core/pipes/translate.pipe";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/router";
import * as i5 from "./young.component";
var styles_YoungComponent = [i0.styles];
var RenderType_YoungComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_YoungComponent, data: {} });
export { RenderType_YoungComponent as RenderType_YoungComponent };
export function View_YoungComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(0, i2.TranslatePipe, [i3.Store]), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "button", [["class", "btn btn-primary w-100"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(0, i2.TranslatePipe, [i3.Store])], function (_ck, _v) { var currVal_1 = _ck(_v, 6, 0, "/login"); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("gblSorryYouMustBe16")); _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("gblReturnToLogin")); _ck(_v, 7, 0, currVal_2); }); }
export function View_YoungComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "marlin-young", [], null, null, null, View_YoungComponent_0, RenderType_YoungComponent)), i1.ɵdid(1, 114688, null, 0, i5.YoungComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var YoungComponentNgFactory = i1.ɵccf("marlin-young", i5.YoungComponent, View_YoungComponent_Host_0, {}, {}, []);
export { YoungComponentNgFactory as YoungComponentNgFactory };
