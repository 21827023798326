import {
    FormControl,
    FormBuilder,
    AbstractControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    ControlValueAccessor,
    ControlContainer
} from '@angular/forms';
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    forwardRef,
    ViewChild,
    Optional,
    Host,
    SkipSelf
} from '@angular/core';
import { verifyValidPhoneKey } from '~app/logical-objects/helpers/form';
import { InputVerficationKeyTypes } from '~constants/form';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'marlin-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true
        }
    ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
    @ViewChild('input') input;

    @Input() label = '';
    control: AbstractControl = new FormControl();
    @Input() errorMessage = '';
    @Input() placeholder = '';
    @Input() type = '';
    @Input() toolTip = '';
    @Input() toolTipConfig: NgbTooltipConfig;
    @Input() formControlName: string;
    @Input() allowTrailingSpaceTrim = true;
    @Input() helpText = '';

    onChange;
    onTouched;

    constructor(
        config: NgbTooltipConfig,
        @Optional()
        @Host()
        @SkipSelf()
        private controlContainer: ControlContainer
    ) {
        if (!this.toolTipConfig) {
            config.placement = 'right';
            config.triggers = 'click';
        } else {
            config = this.toolTipConfig;
        }
    }

    ngOnInit() {
        this.control = this.controlContainer.control.get(this.formControlName);
    }

    writeValue(value: any): void {
        const input = this.input.nativeElement;
        input.value = value;
    }
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    onKeyPress(e) {
        switch (this.type) {
            case InputVerficationKeyTypes.tel:
                return verifyValidPhoneKey(e);
            default:
                break;
        }
    }

    onBlur(e) {
        if (this.allowTrailingSpaceTrim) {
            this.control.setValue(e.target.value.trim());
        }
    }
}
