<form [formGroup]='forgotPasswordForm'>
    <div *ngIf="!isUnconfirmedCognitoUser">
        <div class="form-group">
            <div class="form-title">
                <h1 [innerHTML]='"pmtResetPassword" | translate'></h1>
            </div>
        </div>
        <div *ngIf="uiStatus === uiStatuses.initial">
            <marlin-input [label]="'gblEmail'" [type]="'email'" [errorMessage]="'gblEmailAddressRequiredErrorMsg'" formControlName="email"></marlin-input>

            <div class="form-group text-center ">
                <button class="btn btn-primary w-100" (click)="submit()" [innerHTML]="'gblGo' | translate"></button>
            </div>
        </div>
        <div *ngIf="uiStatus === uiStatuses.submitSuccess" class="form-group">
            <div class="form-title">
                <h4 [innerHTML]='"gblFurtherInstructionsSent" | translate'></h4>
            </div>
        </div>
        <div class="form-group text-center form-navigation">
            <marlin-home-button></marlin-home-button>
        </div>
    </div>
    <div *ngIf="isUnconfirmedCognitoUser" class="form-group">
        <div class="form-title">
            <h2 [innerHTML]='"gblUnableToCompleteYourRequest" | translate'></h2>
        </div>

        <div class="form-group">
            <label [innerHTML]='"gblUnconfirmedCognitoUserError" | translate'></label>
        </div>

        <div class="form-group">
            <button class="btn btn-outline-primary w-100" (click)="resendVerificationEmail();" [innerHTML]="'gblGenerateToken' | translate"></button>
        </div>

        <div class="row">
            <div class="col text-center">
                <a [routerLink]="['/support']" [queryParams]="{type:'PasswordReset'}" [innerHTML]="'gblNeedHelp' | translate"></a>
            </div>
            <div class="col text-center">
                <a [routerLink]="['/portal/home']" [innerHTML]="'gblHomePage' | translate"></a>
            </div>
        </div>
    </div>
</form>