import { Option } from '~models/meta-data';

export class InitialiseChurchLocationService {
    churchId: number;
    locationId: number;
    serviceId: number;

    constructor() { }
}

export class ChurchLocationService {
    church: Option;
    location: Option;
    service: Option;

    constructor() { }
}

export interface ChurchConfigurations {
    Australia: {

    };
    NewYork: {

    };
}
