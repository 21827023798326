import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { environment } from '~environments/environment';
import { OnInit } from '@angular/core';
import { UserService } from '~app/services/auth/user.service';
import { handleMapiError } from '~app/logical-objects/helpers/system';
var AcceptPolicyComponent = /** @class */ (function () {
    function AcceptPolicyComponent(router, userService, store) {
        this.router = router;
        this.userService = userService;
        this.store = store;
        this.policiesUrl = environment.myhillsongUrl + "/Policies.aspx";
    }
    AcceptPolicyComponent.prototype.ngOnInit = function () { };
    AcceptPolicyComponent.prototype.submit = function () {
        var _this = this;
        if (this.isAcceptPolicy) {
            this.userService.updateTermsAndConditions(this.isAcceptPolicy).subscribe(function (data) {
                _this.router.navigate(['/login']);
            }, function (err) {
                handleMapiError(_this.store, err.error.status);
                _this.router.navigate(['/login']);
            });
        }
    };
    return AcceptPolicyComponent;
}());
export { AcceptPolicyComponent };
