import { OnInit } from '@angular/core';
import { AbstractControl, FormControl, ControlContainer, ControlValueAccessor } from '@angular/forms';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import * as zxcvbn from 'zxcvbn';
var InputPasswordComponent = /** @class */ (function () {
    function InputPasswordComponent(config, controlContainer) {
        this.controlContainer = controlContainer;
        this.label = '';
        this.control = new FormControl();
        this.errorMessage = '';
        this.placeholder = '';
        this.type = '';
        this.showToolTip = false;
        this.passwordInputType = 'password';
        this.passwordInputTypes = {
            hidePassword: { value: 'eye-slash', type: 'password' },
            showPassword: { value: 'eye', type: 'text' }
        };
        this.progressColours = {
            0: 'danger',
            25: 'danger',
            50: 'warning',
            75: 'success',
            100: 'success'
        };
        this.strength = 0;
        this.progressColour = this.progressColours[0];
        this.showPasswordRequirments = false;
        // zxcvbn has 4 levels of strength so we need a multiplier for the progress input.
        this.strengthMultiplier = 25;
    }
    InputPasswordComponent.prototype.ngOnInit = function () {
        this.control = this.controlContainer.control.get(this.formControlName);
    };
    InputPasswordComponent.prototype.writeValue = function (value) {
        var input = this.input.nativeElement;
        input.value = value;
    };
    InputPasswordComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    InputPasswordComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    // Setting the svg icon manually because using ng class doesn't work.
    InputPasswordComponent.prototype.changeInputType = function (e, remaskPassword) {
        if (remaskPassword === void 0) { remaskPassword = false; }
        if (remaskPassword) {
            if (this.passwordInputType == this.passwordInputTypes.showPassword.type) {
                this.passwordInputType = this.passwordInputTypes.hidePassword.type;
                var parent_1 = e.currentTarget.parentNode;
                if (parent_1.getAttribute('class') == "input-group-append") {
                    // Lost focus from show/hide button.
                    parent_1.childNodes[0].childNodes[0].setAttribute('data-icon', this.passwordInputTypes.hidePassword.value);
                }
                else {
                    // Lost focus from password field itself.
                    parent_1.childNodes[1].childNodes[0].childNodes[0].setAttribute('data-icon', this.passwordInputTypes.hidePassword.value);
                }
            }
        }
        else if (this.passwordInputType == this.passwordInputTypes.showPassword.type) {
            this.passwordInputType = this.passwordInputTypes.hidePassword.type;
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.hidePassword.value);
        }
        else {
            this.passwordInputType = this.passwordInputTypes.showPassword.type;
            e.currentTarget.childNodes[0].setAttribute('data-icon', this.passwordInputTypes.showPassword.value);
        }
    };
    InputPasswordComponent.prototype.getStrength = function (password) {
        var estimation = zxcvbn(password || '');
        var score = estimation.score * this.strengthMultiplier;
        this.strength = score;
        this.progressColour = this.progressColours[score];
    };
    return InputPasswordComponent;
}());
export { InputPasswordComponent };
