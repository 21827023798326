var InitialiseChurchLocationService = /** @class */ (function () {
    function InitialiseChurchLocationService() {
    }
    return InitialiseChurchLocationService;
}());
export { InitialiseChurchLocationService };
var ChurchLocationService = /** @class */ (function () {
    function ChurchLocationService() {
    }
    return ChurchLocationService;
}());
export { ChurchLocationService };
