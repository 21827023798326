import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthTokenName } from '~app/constants/app-config';
import { AppState } from '~app/store/app-state';
import { UserMetaData } from '~app/constants/bit-data';

@Injectable()
export class UserStateGuard implements CanActivate {
    constructor(private router: Router, private store: Store<AppState>) {}
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        let isAuthorised = false;
        this.store.select(state => state.user.metadata).subscribe(data => {
            const bitMask = UserMetaData.isTermsAndConditionsToBeAccepted | UserMetaData.isPasswordChangedOnNextLogin;
            isAuthorised = !!(data & bitMask);
        });
        if (!isAuthorised) {
            this.router.navigate(['/home']);
        }
        return isAuthorised;
    }
}
