import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import * as userActions from '~store/actions/user.actions';
import { AuthTokenName } from '~constants/app-config';
import * as appDataActions from '~store/actions/app-data.actions';
import { SupportService } from '~app/services/common/support.service';
var DeleteComponent = /** @class */ (function () {
    function DeleteComponent(router, supportService, store) {
        this.router = router;
        this.supportService = supportService;
        this.store = store;
        this.isDeleted = false;
        this.userData = null;
    }
    DeleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.select(function (state) { return state.user; }).subscribe(function (data) {
            _this.userData = data;
        });
    };
    DeleteComponent.prototype.confirmDelete = function () {
        var _this = this;
        this.supportService.support({
            name: this.userData.firstName + " " + this.userData.familyName,
            email: "myhillsong@hillsong.com",
            phone: "",
            supportType: "Delete Account Request",
            issueDescription: this.userData.mobilePhone + " wants their account deleted."
        }).subscribe(function (data) {
            _this.confirmDeleteSuccess();
        }, function (err) {
            // Todo: For an unknown reason, on local a success behaves as predicted,
            // but on live a success goes to this error handler.
            if (err.status == 200) {
                _this.confirmDeleteSuccess();
            }
            else {
                // TODO graceful error handling
            }
        });
    };
    DeleteComponent.prototype.confirmDeleteSuccess = function () {
        this.isDeleted = true;
        this.store.dispatch(new userActions.ResetUserData());
        window.sessionStorage.removeItem(AuthTokenName);
        this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
        this.isDeleted = true;
    };
    return DeleteComponent;
}());
export { DeleteComponent };
