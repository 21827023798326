import { OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, Validators, ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';
import { CookieLanguageName } from '~app/constants/app-config';
import { CookieService } from 'ngx-cookie';
var DateDropdownComponent = /** @class */ (function () {
    function DateDropdownComponent(fb, controlContainer, cookieService) {
        this.fb = fb;
        this.controlContainer = controlContainer;
        this.cookieService = cookieService;
        this.dateForm = this.fb.group({
            day: [{ value: null, disabled: true }, Validators.required],
            month: [{ value: null, disabled: true }, Validators.required],
            year: [null, Validators.required]
        });
        this.maxDate = null;
        this.minDate = null;
        this.onChange = function (form) { };
        this.onTouched = function () { };
    }
    DateDropdownComponent.prototype.ngOnInit = function () {
        var minYear = 1900;
        var maxYear = new Date().getFullYear();
        if (this.maxDate !== null) {
            maxYear = this.maxDate.year;
        }
        if (this.minDate !== null) {
            minYear = this.minDate.year;
        }
        this.generateYearOptions(minYear, maxYear);
    };
    DateDropdownComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DateDropdownComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    DateDropdownComponent.prototype.writeValue = function (value) {
        if (value != '' && value != null) {
            this.dateForm.setValue({
                day: value.day,
                month: value.month,
                year: value.year
            });
        }
        else {
            this.dateForm.setValue({
                day: null,
                month: null,
                year: null
            });
        }
    };
    DateDropdownComponent.prototype.generateDayOptions = function () {
        var dayOptions = [];
        var year = this.dateForm.controls.year.value;
        var month = this.dateForm.controls.month.value;
        var minDay = 1;
        var maxDay = moment(year + "-" + month, 'YYYY-MM').daysInMonth();
        if (this.minDate !== null) {
            if (this.minDate.year == year.value && this.minDate.month == month.value) {
                minDay = this.minDate.day;
            }
        }
        if (this.maxDate !== null) {
            if (this.maxDate.year == year.value && this.maxDate.month == month.value) {
                maxDay = this.maxDate.day;
            }
        }
        for (var index = minDay; index <= maxDay; index++) {
            dayOptions.push({ text: index, value: index });
        }
        this.dayOptions = dayOptions;
    };
    DateDropdownComponent.prototype.generateMonthOptions = function () {
        var monthOptions = [];
        var year = this.dateForm.controls.year.value;
        var minMonth = 1;
        var maxMonth = 12;
        if (this.minDate !== null) {
            if (this.minDate.year == year) {
                minMonth = this.minDate.month;
            }
        }
        if (this.maxDate !== null) {
            if (this.maxDate.year == year) {
                maxMonth = this.maxDate.month;
            }
        }
        var language = this.cookieService.get(CookieLanguageName).substring(0, 2);
        var monthsArray = moment.localeData(language).monthsShort();
        for (var index = minMonth; index <= maxMonth; index++) {
            monthOptions.push({ text: monthsArray[index - 1], value: index });
        }
        this.monthOptions = monthOptions;
    };
    DateDropdownComponent.prototype.generateYearOptions = function (minYear, maxYear) {
        var yearOptions = [];
        for (var index = maxYear; index >= minYear; index--) {
            yearOptions.push({ text: index, value: index });
        }
        this.yearOptions = yearOptions;
    };
    DateDropdownComponent.prototype.yearMonthChange = function (type) {
        var year = this.dateForm.controls.year;
        var month = this.dateForm.controls.month;
        var day = this.dateForm.controls.day;
        switch (type) {
            case 'year':
                month.updateValueAndValidity();
                day.updateValueAndValidity();
                if (year.value !== null) {
                    month.enable();
                    // Populate min and max month depending on the input.
                    this.generateMonthOptions();
                    if (month.value === 2) {
                        this.generateDayOptions();
                    }
                }
                break;
            case 'month':
                this.dayOptions = [];
                day.updateValueAndValidity();
                if (this.dateForm.controls.year.value !== null) {
                    day.enable();
                    // Populate min and max date depending on the input.
                    this.generateDayOptions();
                }
                break;
        }
    };
    return DateDropdownComponent;
}());
export { DateDropdownComponent };
