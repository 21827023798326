export var GET_USER = '[Users] Get';
export var GET_USER_SUCCESS = '[Users] Get Sucess';
export var GET_USER_FAILED = '[Users] Get Failed';
export var RESET_USER_DATA = '[Users] Reset User Data';
var ResetUserData = /** @class */ (function () {
    function ResetUserData() {
        this.type = RESET_USER_DATA;
    }
    return ResetUserData;
}());
export { ResetUserData };
var GetUser = /** @class */ (function () {
    function GetUser(payload) {
        this.payload = payload;
        this.type = GET_USER;
    }
    return GetUser;
}());
export { GetUser };
var GetUserSuccess = /** @class */ (function () {
    function GetUserSuccess(payload) {
        this.payload = payload;
        this.type = GET_USER_SUCCESS;
    }
    return GetUserSuccess;
}());
export { GetUserSuccess };
var GetUserFailed = /** @class */ (function () {
    function GetUserFailed(payload) {
        this.payload = payload;
        this.type = GET_USER_FAILED;
    }
    return GetUserFailed;
}());
export { GetUserFailed };
