export const ErrorMessages = {
    // gblUnauthorisedAccess can be used for errors where a user does not have access to a resource.
    '0001': 'gblLoginFailed', // Unauthorized Access
    '0002': 'gblAccountSetupIncomplete', // Account is unconfirmed, ask user to check their email.
    '0003': 'gblSessionExpiredErrorMsg', // Let's rename this to gblSessionExpired
    // Missing input, need to workout what to show the user or how to deal with it.
    '0004': 'gblOopsSomethingWentWrongErrorMsg',
    '0005': 'gblOopsSomethingWentWrongErrorMsg',
    '0006': 'gblOopsSomethingWentWrongErrorMsg',
    // Missing input, need to workout what to show the user or how to deal with it.
    '0007': 'gblAccountSetupIncomplete', // Account set up is incomplete, ask user to check their email.
    '0008': 'gblLockedOut',
    // Password validation error on front end, need a appropriate.
    '0010': 'gblPasswordTooWeak', // does this only come back on account creation? and password reset?
    // 'gblPasswordTooWeak' = "Your password is too weak, please try again."
    '0011': 'gblLoginFailed',
    '0012': 'gblPasswordRepeatRequiredErrorMsg',
    '0013': 'gblOopsSomethingWentWrongErrorMsg',
    '0014': 'gblOopsSomethingWentWrongErrorMsg',
    '0015': 'gblRecapchaErrorMsg',
    // Fail to create user, missing details.
    '0016': 'gblOopsSomethingWentWrongErrorMsg',
    '0017': 'gblSessionExpiredErrorMsg',
    '0018': 'gblPhoneFormatErrorMsg',
    '0019': 'gblOopsSomethingWentWrongErrorMsg',
    customResetPasswordError: 'gblInvalidToken',
    customVerificationTokenExpired: 'gblVerificationTokenExpired',
    customVerificationLinkExpired: 'gblVerificationLinkExpired'
};
