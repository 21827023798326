import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { CoreModule } from '~core/core.module';
import { DropdownComponent } from './dropdown/dropdown.component';
import { InputComponent } from './input/input.component';
import { InputGroupComponent } from './input-group/input-group.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { HomeButtonComponent } from './home-button/home-button.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, NgbModule, CoreModule, DropDownsModule, RouterModule],
    declarations: [DropdownComponent, InputComponent, InputGroupComponent, InputPasswordComponent, HomeButtonComponent],
    exports: [DropdownComponent, InputComponent, InputGroupComponent, InputPasswordComponent, HomeButtonComponent]
})
export class ControlsModule {}
