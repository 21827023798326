import { ErrorMessages } from '~app/constants/system';
import * as appDataActions from '~store/actions/app-data.actions';

export function getTranslatedErrorMessageFromResponse(errorMessage: string) {
    // Add 1 so not to include the bracket.
    const startingidx = errorMessage.indexOf('[') + 1;
    const endingidx = errorMessage.indexOf(']');
    const errorCode = errorMessage.slice(startingidx, endingidx);
    return ErrorMessages[errorCode];
}

// Handles the error by setting the error message on the app and clears loading screen.
export function handleMapiError(store, message) {
    const errorMessage = getTranslatedErrorMessageFromResponse(message);
    store.dispatch(new appDataActions.SetAppErrorMessage(errorMessage));
    store.dispatch(new appDataActions.ToggleIsLoading(false));
}
