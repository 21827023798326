import { is16OrOver } from '~app/logical-objects/helpers/date-time';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthTokenName } from '~constants/app-config';
import * as _ from 'lodash';
import { UserMetaData } from '~app/constants/bit-data';
import * as userActions from '~store/actions/user.actions';
import * as appDataActions from '~store/actions/app-data.actions';
import * as moment from 'moment';
var AuthenticationGuard = /** @class */ (function () {
    function AuthenticationGuard(router, store) {
        this.router = router;
        this.store = store;
    }
    AuthenticationGuard.prototype.canActivate = function () {
        var isAuthorised = false;
        var userData;
        this.store.select(function (state) { return state.user; }).subscribe(function (data) {
            isAuthorised = !!window.sessionStorage.getItem(AuthTokenName) && !_.isEmpty(data);
            userData = data;
        });
        if (userData.dateOfBirth) {
            if (!is16OrOver(moment(userData.dateOfBirth))) {
                this.store.dispatch(new userActions.ResetUserData());
                window.sessionStorage.removeItem(AuthTokenName);
                this.router.navigate(['/young']);
                this.store.dispatch(new appDataActions.ToggleIsLoading(false));
            }
        }
        else if (!isAuthorised) {
            this.router.navigate(['/login']);
        }
        else if (UserMetaData.isPasswordChangedOnNextLogin & userData.metadata) {
            this.router.navigate(['/reset-password'], { queryParams: { type: 'update' } });
            this.store.dispatch(new appDataActions.ToggleIsLoading(false));
        }
        return isAuthorised;
    };
    return AuthenticationGuard;
}());
export { AuthenticationGuard };
