import { Store } from '@ngrx/store';
import { CanActivate, Router } from '@angular/router';
import { UserMetaData } from '~app/constants/bit-data';
var UserStateGuard = /** @class */ (function () {
    function UserStateGuard(router, store) {
        this.router = router;
        this.store = store;
    }
    UserStateGuard.prototype.canActivate = function () {
        var isAuthorised = false;
        this.store.select(function (state) { return state.user.metadata; }).subscribe(function (data) {
            var bitMask = UserMetaData.isTermsAndConditionsToBeAccepted | UserMetaData.isPasswordChangedOnNextLogin;
            isAuthorised = !!(data & bitMask);
        });
        if (!isAuthorised) {
            this.router.navigate(['/home']);
        }
        return isAuthorised;
    };
    return UserStateGuard;
}());
export { UserStateGuard };
