import { FormControl, AbstractControl, ControlValueAccessor, ControlContainer } from '@angular/forms';
import { OnInit } from '@angular/core';
import { verifyValidPhoneKey } from '~app/logical-objects/helpers/form';
import { InputVerficationKeyTypes } from '~constants/form';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
var InputComponent = /** @class */ (function () {
    function InputComponent(config, controlContainer) {
        this.controlContainer = controlContainer;
        this.label = '';
        this.control = new FormControl();
        this.errorMessage = '';
        this.placeholder = '';
        this.type = '';
        this.toolTip = '';
        this.allowTrailingSpaceTrim = true;
        this.helpText = '';
        if (!this.toolTipConfig) {
            config.placement = 'right';
            config.triggers = 'click';
        }
        else {
            config = this.toolTipConfig;
        }
    }
    InputComponent.prototype.ngOnInit = function () {
        this.control = this.controlContainer.control.get(this.formControlName);
    };
    InputComponent.prototype.writeValue = function (value) {
        var input = this.input.nativeElement;
        input.value = value;
    };
    InputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    InputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    InputComponent.prototype.onKeyPress = function (e) {
        switch (this.type) {
            case InputVerficationKeyTypes.tel:
                return verifyValidPhoneKey(e);
            default:
                break;
        }
    };
    InputComponent.prototype.onBlur = function (e) {
        if (this.allowTrailingSpaceTrim) {
            this.control.setValue(e.target.value.trim());
        }
    };
    return InputComponent;
}());
export { InputComponent };
