<div [formGroup]="consentsForm">
    <div class="form-group consents">
        <div class="terms-conditions">
            <label>{{getWordingForConsentType(consentTypes.CONSENT_LEGAL_TEXT)}}</label>
            <div class="container">
                <div class="row">
                    <div class="checkbox col-sm">
                        <input type="checkbox" id="cbxEmail" formControlName="consentType1" (change)='onChange(consentsForm);' />
                        <label for="cbxEmail" [innerHTML]='getWordingForConsentType(consentTypes.EMAIL)'></label>
                    </div>
                    <div class="checkbox col-sm">
                        <input type="checkbox" id="cbxPhone" formControlName="consentType2" (change)='onChange(consentsForm);' />
                        <label for="cbxPhone" [innerHTML]='getWordingForConsentType(consentTypes.PHONE)'></label>
                    </div>
                    <div class="checkbox col-sm">
                        <input type="checkbox" id="cbxSMS" formControlName="consentType3" (change)='onChange(consentsForm);' />
                        <label for="cbxSMS" [innerHTML]='getWordingForConsentType(consentTypes.SMS)'></label>
                    </div>
                    <div class="checkbox col-sm">
                        <input type="checkbox" id="cbxMail" formControlName="consentType4" (change)='onChange(consentsForm);' />
                        <label for="cbxMail" [innerHTML]='getWordingForConsentType(consentTypes.MAIL)'></label>
                    </div>
                </div>
                <div class="row">
                    <div class="checkbox tooltip-container col-sm">
                        <input type="checkbox" id="cbxSocial" formControlName="consentType5" (change)='onChange(consentsForm);' />
                        <label for="cbxSocial" [innerHTML]='getWordingForConsentType(consentTypes.SOCIAL)'></label>
                        <button type="button" [ngbTooltip]="getWordingForConsentType(consentTypes.SOCIAL_HELP)" class="tooltip-element">
                            <i class="fa fa-question-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>