﻿<form [formGroup]="loginForm" *ngIf="!isGlobalUser" novalidate>
    <div class="form-title">
        <h1 [innerHTML]='"gblLoginToMyhillsong" | translate'></h1>
    </div>
    <marlin-input [label]="'gblEmail'" [type]="'email'" [errorMessage]="'gblEmailAddressRequiredErrorMsg'" formControlName="loginEmail"></marlin-input>
    <marlin-input-password [label]="'gblPassword'" [errorMessage]="'gblPasswordRequiredErrorMsg'" formControlName="password"></marlin-input-password>
    <div class="form-group row login-options">
        <div class="checkbox remember-me col">
            <input type="checkbox" id="remember" formControlName="isRemember" />
            <label for="remember" [innerHTML]='"gblRememberMe" | translate'></label>
        </div>
        <div class="col float-right text-right">
            <a class="forgot-password" [routerLink]="['/forgot-password']" [innerHTML]='"gblForgotPassword" | translate'></a>
        </div>
    </div>

    <div class="form-group">
        <button type="submit" id="btnLogin" (click)="onSubmit()" class="btn btn-primary w-100" [innerHTML]='"gblLogin" | translate'></button>
    </div>
    <div class="form-group terms-conditions text-center">
        <div [innerHTML]='"gblBySigningInYouAgree" | translate'></div>
        <a href="{{policiesUrl}}" (click)='resetUserData()' [innerHTML]="'gblTerms' | translate"></a> &
        <a href="{{policiesUrl}}" (click)='resetUserData()' [innerHTML]="'gblPrivacyPolicy' | translate"></a>
    </div>
    <div class="form-group text-center no-account">
        <hr>
        <label>
            <b [innerHTML]='"gblDontHaveAccountYet" | translate'></b>
        </label>
        <button type="button" [routerLink]="['/register']" class="btn btn-outline-primary w-100" (click)='resetUserData()' [innerHTML]="'gblCreateAccount' | translate"></button>
    </div>
</form>

<div *ngIf="isGlobalUser">
    <div class="form-title">
        <h3 [innerHTML]="('gblHello' | translate) + '!'"></h3>
        <h4 [innerHTML]='"gblExistingGlobalUserMessage" | translate'></h4>
        <div class="form-group text-center">
            <a class="btn btn-primary w-100" (click)="resetUserData()" [routerLink]="['/support']" [queryParams]="{type:'GeneralEnquiries'}"
                [innerHTML]="'gblContactOurTeam' | translate"></a>
            <label class="label-separator" [innerHTML]='"gblOR" | translate'></label>
            <a class="btn btn-primary w-100" (click)="resetUserData()" href='https://hillsong.com' [innerHTML]="'gblTakeMetoMyhillsongUpcomingEvents' | translate"></a>
        </div>
        <div class="form-group text-center">
            <a [routerLink]="['/']" (click)="resetUserData(); isGlobalUser = false">
                <i class="far fa-home fa-2x"></i>
                <span [innerHTML]="'gblBackToLogin' | translate"></span>
            </a>
        </div>
    </div>
</div>