import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserService } from '~services/auth/user.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import * as userActions from '~actions/user.actions';
import { AppState } from '~app/store/app-state';

@Injectable()
export class AuthUserEffects {
    @Effect()
    getUser$ = this.actions$.pipe(
        ofType(userActions.GET_USER),
        map(toPayload => {
            return toPayload;
        }),
        switchMap((payload: any) => {
            return this.userService.getUser(payload)
                .map(user => {
                    return new userActions.GetUserSuccess(user.data);
                })
                .catch(err => {
                    return of(new userActions.GetUserFailed(err.error.status));
                })
                .finally(() => {});
        })
    );

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private store: Store<AppState>,
        private router: Router
    ) {}
}
