import { CookieService } from 'ngx-cookie';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as userActions from '~store/actions/user.actions';

import { MarlinSlideInOut } from '~core/animations';
import { AppState } from '~store/app-state';
import { MetaData } from '~models/meta-data';
import { ProgressSteps } from '~logic/ui/progress-steps';
import { MetadataTypes } from '~constants/church';
import * as metadataAction from '~store/actions/app-data.actions';
import * as appDataActions from '~store/actions/app-data.actions';
import { TranslatePipe } from '~pipes/translate.pipe';
import { markAllTouched, setNullValuesAsEmpty, markAllUntouched } from '~app/logical-objects/helpers/form';
import { UserService } from '~services/auth/user.service';
import { AuthTokenName } from '~constants/app-config';
import { environment } from '~environments/environment';
import { validateRequiredGroup, validatePhone, validateHomePhone, validateFormHasValues } from '~app/core/validators';
import { MyHillsongLoginPassThroughInvolvementIds } from '~constants/user';
import { handleMapiError } from '~app/logical-objects/helpers/system';
import { MyHillsongUrl } from '~app/constants/url';
import { UserMetaData } from '~app/constants/bit-data';
import { InitialiseChurchLocationService } from '~app/models/church';
import { is16OrOver, formatDateForHapi } from '~app/logical-objects/helpers/date-time';

@Component({
    selector: 'marlin-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: MarlinSlideInOut
})
export class HomeComponent implements OnInit {
    additionalInfoForm = this.fb.group(
        {
            firstName: ['', Validators.required],
            familyName: ['', Validators.required],
            ageGroupId: ['', Validators.required],
            genderId: ['', Validators.required],
            dateOfBirth: [null, Validators.required],
            homePhone: ['', validateHomePhone],
            mobilePhone: ['', validatePhone],
            address: [''],
            suburb: ['', Validators.required],
            postCode: ['', Validators.required],
            countryId: ['', Validators.required],
            involvementId: ['', Validators.required],
            churchLocationService: [null, validateFormHasValues],
            consents: {
                consentType1: false,
                consentType2: false,
                consentType3: false,
                consentType4: false,
                consentType5: false,
                consentType6: false
            }
        },
        { validator: validateRequiredGroup(['homePhone', 'mobilePhone']) }
    );
    initialiseChurchLocationService = new InitialiseChurchLocationService();
    ageGroups: MetaData = { metaType: '', options: [] };
    maxSignupAge = moment().subtract(13, 'year');
    genders$ = this.store.select(state => state.metadata[MetadataTypes.Gender]);
    countries: MetaData = { metaType: '', options: [] };
    isProfileComplete = false;
    isGlobalUser = false;
    hasDob = false;
    labels = {
        gender: '',
        ageGroup: ''
    };
    readonly loginHandlerString = '/Utilities/LoginHandler.ashx?nonce=';
    minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
    maxDate: NgbDateStruct = {
        year: moment().year(),
        month: moment().month(),
        day: moment().date()
    };
    redirectUrl = '';
    progressSteps = new ProgressSteps(3, this.store);
    steps = {
        dobCheck: null,
        initial: 1,
        additionalInfo1: 2,
        additionalInfo2: 3,
        churchLocationService: null,
        involvementLevel: null,
        consents: null
    };
    token = window.sessionStorage.getItem(AuthTokenName);
    userSelection = {
        CG: { value: 'ConnectGroup', url: '/ConnectGroups/Search.aspx' },
        VT: { value: 'VolunteerTeam', url: '/Volunteers/JoinATeam.aspx' },
        AC: { value: 'AlphaCourse', url: '/Registrations/CourseRegistration.aspx' },
        MF: { value: 'ManageFamily', url: '/MyProfile/MyFamily.aspx' },
        CM: { value: 'ClassicMyhillsong', url: '/' }
    };

    involvementLevels = {
        homeChurch: 'HH',
        newPerson: 'NP',
        connectGroup: 'CG',
        volunteer: 'VOL'
    };
    isAgeGroupGenderRequired = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private store: Store<AppState>,
        private cookieService: CookieService,
        private activatedRoute: ActivatedRoute,
        private fb: FormBuilder
    ) {}

    ngOnInit() {
        // Set timeout is required to avoid change detection errors.
        // tslint:disable-next-line
        // See https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4.

        this.store.select(state => state.user).subscribe(data => {
            if (data.status == 'failed') {
                this.router.navigate(['/login']);
            } else if (!_.isEmpty(data)) {
                let dob = null;
                if (data.dateOfBirth) {
                    const dobDate = moment(data.dateOfBirth);
                    dob = {
                        year: dobDate.year(),
                        month: dobDate.month() + 1,
                        day: dobDate.date()
                    };
                    this.hasDob = true;
                }

                // Setting values for the form requires a value for each field, but the values cannot be null.
                const formattedData = setNullValuesAsEmpty(data);
                this.additionalInfoForm.setValue({
                    firstName: formattedData.firstName,
                    familyName: formattedData.familyName,
                    ageGroupId: formattedData.ageGroupId.toString(),
                    genderId: formattedData.genderId.toString(),
                    homePhone: formattedData.homePhone,
                    mobilePhone: formattedData.mobilePhone,
                    // Only set DOB if it is valid, otherwise some of the form data will be in an invalid state.
                    dateOfBirth: dob ? this.fb.group(dob) : null,
                    address: formattedData.address1,
                    suburb: formattedData.suburb,
                    postCode: formattedData.postCode,
                    countryId: formattedData.countryId.toString(),
                    involvementId: formattedData.involvementId,
                    churchLocationService: formattedData.serviceId
                        ? {
                              church: formattedData.churchId,
                              location: formattedData.locationId,
                              service: formattedData.serviceId
                          }
                        : { church: null, location: null, service: null },
                    consents: {
                        consentType1: false,
                        consentType2: false,
                        consentType3: false,
                        consentType4: false,
                        consentType5: false,
                        consentType6: false
                    }
                });

                this.isProfileComplete = this.additionalInfoForm.valid;
                // Check that the user has an involvement level of higher than ministry
                // active to pass them straight through to myhillsong.
                const isPassThroughToMyHillsong = MyHillsongLoginPassThroughInvolvementIds.indexOf(
                    this.additionalInfoForm.get('involvementId').value
                );

                // If is connection zone user then pass them straight through to the connection zone page.
                if (data.metadata & UserMetaData.isConnectionZoneUser) {
                    this.userService.getNonce(this.token).subscribe(resp => {
                        window.location.href = `${this.getMyhillsongLoginHandlerString(resp.nonce)}&ReturnUrl=${
                            MyHillsongUrl.connectionZone
                        }`;
                    });
                } else if (
                    (this.isProfileComplete && isPassThroughToMyHillsong >= 0) ||
                    // If the user has not accepted terms and conditions we immediately redirect them to myhillsong
                    // which then will force them to read T&C.
                    data.metadata & UserMetaData.isTermsAndConditionsToBeAccepted
                ) {
                    let returnUrl = '';
                    let embeddedParam = '';
                    this.store.dispatch(new appDataActions.ToggleIsRedirecting(true));
                    this.activatedRoute.queryParams.take(1).subscribe(params => {
                        returnUrl =
                            params['ReturnUrl'] || params['returnUrl'] || params['Returnurl'] || params['returnurl'];
                    });
                    if (returnUrl && returnUrl.includes('FrontPlugin')) {
                        embeddedParam = '&embedded=true';
                    }
                    this.userService.getNonce(this.token).subscribe(resp => {
                        window.location.href = `${this.getMyhillsongLoginHandlerString(resp.nonce)}&ReturnUrl=${
                            returnUrl ? returnUrl : ''
                        }${embeddedParam}`;
                    });
                } else {
                    this.store.dispatch(new appDataActions.ToggleIsRedirecting(false));
                }
            }

            this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.Gender));
            this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.AgeGroup, data.churchId));
            this.store.dispatch(new metadataAction.LoadMetadata(MetadataTypes.Country));
        });

        this.store.select(state => state.metadata[MetadataTypes.AgeGroup]).subscribe(data => {
            if (data) {
                this.ageGroups = data;
                this.ageGroups.options.sort((a, b) => {
                    return parseFloat(b.value) - parseFloat(a.value);
                });
            }
        });

        this.store.select(state => state.metadata[MetadataTypes.Country]).subscribe(data => {
            if (data) {
                this.countries = data;
            }
        });

        this.labels.ageGroup = new TranslatePipe(this.store).transform('gblPleaseSelect');
        this.labels.gender = new TranslatePipe(this.store).transform('gblPleaseSelect');

        this.store
            .select(store => store.user.metadata)
            .take(1)
            .subscribe(data => {
                if (!!(data & UserMetaData.isGlobalUser)) {
                    this.isGlobalUser = true;
                    this.router.navigate(['/login']);
                } else if (!this.hasDob) {
                    this.progressSteps = new ProgressSteps(4, this.store);
                    this.steps = Object.assign(
                        {},
                        {
                            dobCheck: 0,
                            initial: 1,
                            churchLocationService: null,
                            involvementLevel: null,
                            additionalInfo1: 2,
                            additionalInfo2: 3,
                            consents: null
                        }
                    );
                    this.progressSteps.goToStep(this.steps.dobCheck);
                }
            });
    }

    IsAddtionalInfo1Step() {
        return this.progressSteps.currentStep === this.steps.additionalInfo1;
    }

    IsAddtionalInfo2Step() {
        return this.progressSteps.currentStep === this.steps.additionalInfo2;
    }

    IsDobStep() {
        return this.progressSteps.currentStep === this.steps.dobCheck;
    }

    userSelect(type: string) {
        if (this.isProfileComplete) {
            this.userService.getNonce(this.token).subscribe(resp => {
                switch (type) {
                    case this.userSelection.MF.value:
                        this.redirectUrl = `${this.getMyhillsongLoginHandlerString(resp.nonce)}&ReturnUrl=${
                            this.userSelection.MF.url
                        }`;
                        break;
                    case this.userSelection.CG.value:
                        this.redirectUrl = `${this.getMyhillsongLoginHandlerString(resp.nonce)}&ReturnUrl=${
                            this.userSelection.CG.url
                        }`;
                        break;
                    case this.userSelection.VT.value:
                        this.redirectUrl = `${this.getMyhillsongLoginHandlerString(resp.nonce)}&ReturnUrl=${
                            this.userSelection.VT.url
                        }`;
                        break;
                    case this.userSelection.AC.value:
                        this.redirectUrl = `${this.getMyhillsongLoginHandlerString(resp.nonce)}&ReturnUrl=${
                            this.userSelection.AC.url
                        }`;
                        break;
                    case this.userSelection.CM.value:
                        this.redirectUrl = this.getMyhillsongLoginHandlerString(resp.nonce);
                        break;
                }
                window.location.href = this.redirectUrl;
            });
        } else {
            this.progressSteps.goToStep(this.steps.additionalInfo1);
        }
    }

    getMyhillsongLoginHandlerString(nonce) {
        return environment.myhillsongUrl + this.loginHandlerString + nonce;
    }

    additionalInfo1Next() {
        if (
            this.additionalInfoForm.get('suburb').valid &&
            this.additionalInfoForm.get('postCode').valid &&
            this.additionalInfoForm.get('countryId').valid &&
            !this.additionalInfoForm.hasError('validateRequiredGroup') &&
            this.additionalInfoForm.controls.homePhone.valid &&
            this.additionalInfoForm.controls.mobilePhone.valid
        ) {
            this.progressSteps.nextStep();
            markAllUntouched(this.additionalInfoForm);
        } else {
            markAllTouched(this.additionalInfoForm);
        }
    }

    updateUser() {
        if (this.additionalInfoForm.valid) {
            this.store.dispatch(new appDataActions.ToggleIsLoading(true));
            this.userService.updateUser(this.additionalInfoForm.value).subscribe(
                data => {
                    this.userService.getNonce(this.token).subscribe(
                        resp => {
                            window.location.href = this.getMyhillsongLoginHandlerString(resp.nonce);
                        },
                        err => handleMapiError(this.store, err.error.status)
                    );
                },
                err => handleMapiError(this.store, err.error.status),
                () => this.store.dispatch(new appDataActions.ToggleIsLoading(true))
            );
        } else {
            markAllTouched(this.additionalInfoForm);
        }
    }

    gotoHillsongDotCom() {
        window.location.href = 'https://hillsong.com';
    }

    dobCheck() {
        if (this.additionalInfoForm.get('dateOfBirth').value.invalid) {
            markAllTouched(this.additionalInfoForm.get('dateOfBirth').value);
        }

        const dateOfBirth = moment(formatDateForHapi(this.additionalInfoForm.get('dateOfBirth').value.value));
        if (is16OrOver(dateOfBirth)) {
            // 16 years or older, continue to next step.
            this.progressSteps.nextStep();
        } else {
            // under 16 - prevent user login.
            this.store.dispatch(new userActions.ResetUserData());
            window.sessionStorage.removeItem(AuthTokenName);
            this.store.dispatch(new appDataActions.ToggleIsLoading(false));
            this.store.dispatch(new appDataActions.ToggleUserIsLoaded(true));
            this.router.navigate(['young']);
        }
    }

    submit() {
        if (!this.isGlobalUser) {
            this.updateUser();
        } else {
            // Global user, direct to login.
            this.router.navigate(['/login']);
        }
    }
}
